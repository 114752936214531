import React from 'react'
import { Provider } from 'react-redux'
//import { ConnectedRouter } from 'connected-react-router'
//import initializeStore, { history } from './store'
import initializeStore from './store'
// import Prompt from './containers/Prompt'
// import Booking from './containers/Booking'
// import Confirm from './containers/Confirm'
import Wrapper from './containers/Wrapper';
import './styles'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors';


const THEME = (createMuiTheme({
  typography: {
   "fontFamily": "TimesNewRoman, \"Times New Roman\", Times, Baskerville, Georgia, serif",
   "fontSize": 15, //'16px',
   //"fontWeightLight": 300,
   //"fontWeightRegular": 400,
   //"fontWeightMedium": 500,
   root: {
     color: '#000'
   },
    h1: { color:'#000', fontSize:'130%' },
    h2: { color:'#000', fontSize:'125%' },
    h3: { color:'#000', fontSize:'120%' },
    h4: { color:'#000', fontSize:'115%' },
    h5: { color:'#000', fontSize:'110%' },
    h6: { color:'#000', fontSize:'100%' },
    body1: {
      fontSize: 'inherit', //14px',
      color:'#000'
    }
    /*
        available variants:    
        h1
        h2
        h3
        h4
        h5
        h6
        subtitle1
        subtitle2
        body1
        body2
        button
        caption
        overline 
    */
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 'inherit', //14px',
      }
    },
    MuiCssBaseline:{
      '@global':{
        fontSize: 'inherit', //16px'
      }
    },
    MuiFormLabel: {
      root:{
        //color:'#900'
        fontSize: 'inherit', //14px',
        color:'#333'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: blue.A200,
      },
    },
    MuiDialogContent: {
      root: {
        padding:'16px'
      }
    },
    MuiDialogTitle: {
      root: {
        padding:'16px'
      }
    },
    MuiDialogActions: {
      root: {
        padding:'16px'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#fff',
        backgroundColor: blue["600"],
        '&:hover': {
          background: blue["800"],
       },
      },
      dayDisabled: {
        dayDisabled: '#ccc',
        backgroundColor: 'transparent',
      },
    },
    MuiFormControl: {
      root: {
        background:'#f7fff7',
        //paddingLeft:'3px',
      },
    },
    MuiInputBase: {
      root: {
        paddingLeft:'5px',
        //color:'#f00',
      },
    },
    MuiInputLabel: {
      root: {
        paddingLeft:'5px',
        //"&$focused": { paddingLeft:0 },
      },

    },
  },
}));

export default ({PHRASES, SCHEDULE}) => (
  <MuiThemeProvider theme={THEME}>
    <Provider store={initializeStore({PHRASES, SCHEDULE})}>
      {/* <ConnectedRouter history={history}> */}
          <Wrapper 
            PHRASES={PHRASES}
            SCHEDULE={SCHEDULE}
          />
      {/* </ConnectedRouter> */}
    </Provider>
  </MuiThemeProvider>
)