//import { Promisify } from './../../helpers/promisify';

import SPOT_LOAD from './../../config/spot';

import {
    SPOT_BOOKING_DELIVERY_TYPE,
    SPOT_BOOKING_VISIT_TYPE,
    SPOT_BOOKING_DATE
} from './constants';

import { PHRASE } from './../../helpers/PHRASES';

import { Moment } from './../../helpers/schedule'

import { BOOKING_CONFIRMED } from '../booking'


//export const CustomerConnect = window.CustomerConnect;

// Name	Type	Max Length	Description
// PickupDate	string		Ending date in the format MM/DD/YYYY. If you use time slots, you can set this to MM/DD/YYYY HH:MM:SS where the hour/minute/second is the start of the time slot.
// Comments	string	255	Customer provided comments that will show on the pickup entry. Max length 255 characters.
// InstructionsRequests	string	1000	Cleaning Instructions or additional information for the pickup. This will be displayed as a popup prior to detailing in orders for the customer.
// VisitType	string		Specify either 'Pickup' or 'Both Pickup and Delivery'. This will default to 'Pickup' if not provided.
// DeliveryDate	string		The delivery date for the returning order in the format of MM/DD/YYYY. If you use time slots, you can set this to MM/DD/YYYY HH:MM:SS where the hour/minute/second is the start of the time slot. Must be provided if the type is 'Both Pickup and Delivery'. It does not need to be provided if the type is 'Pickup'.
// DeliveryComments	string	255	Customer provided comments that will show on the delivery entry. Max length 255 characters.
// PickupTimeRange	string	50	The name of the time range. This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (Morning PU, 7:30 PU, etc)
// PickupStartTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
// PickupEndTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
// DeliveryTimeRange	string	50	The name of the time range. This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (Morning PU, 7:30 PU, etc)
// DeliveryStartTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
// DeliveryEndTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
export const SpotSavePickupRequest = (dispatch, state)=>{
	return new Promise((resolve, reject)=>{
		SPOT_LOAD().then(()=>{
			
			const comments = (state.instructions.pickup_delivery_comments || '') + (!state.instructions.pickup_delivery_comments ? '' : ' '+PHRASE("INSTRUCTIONS_DRIVER_INITIALS")); // delivery notes, pick-up delivery comments
			const instructionsRequests = state.instructions.customer_memo || ''; // clothes, customer memo
			const deliveryType = SPOT_BOOKING_DELIVERY_TYPE;
			const visitType = SPOT_BOOKING_VISIT_TYPE;
			const pickupDate = Moment(state.schedule.collect.DATE).format(SPOT_BOOKING_DATE);
			const deliveryDate = Moment(state.schedule.deliver.DATE).format(SPOT_BOOKING_DATE);
			const AcceptTerms = !!state.confirm.accepted_terms_and_conditions;
			const PickupTimeRange = state.schedule.collect.TIME; //.relace(/[\s-]*(\d\d:\d\d)\s*$/gi, '')	// string	50	The name of the time range. This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (Morning PU, 7:30 PU, etc)
			const PickupStartTime = state.schedule.collect.TIME.replace(/[\s-]*(\d\d:\d\d)\s*$/gi, '');	// string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
			const PickupEndTime = state.schedule.collect.TIME.replace(/^\s*(\d\d:\d\d)[\s-]*/gi, '');	// string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
			const DeliveryTimeRange = state.schedule.deliver.TIME; //.relace(/[\s-]*(\d\d:\d\d)\s*$/gi, '')	// string	50	The name of the time range. This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (Morning PU, 7:30 PU, etc)
			const DeliveryStartTime = state.schedule.deliver.TIME.replace(/[\s-]*(\d\d:\d\d)\s*$/gi, '');	// string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
			const DeliveryEndTime = state.schedule.deliver.TIME.replace(/^\s*(\d\d:\d\d)[\s-]*/gi, '');	// string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)

			const pickupRequest = {
				AcceptTerms,
				comments, // pickup/delivery comments
				instructionsRequests, // customer memo
				deliveryType,
				pickupDate,
				visitType,
				deliveryDate,
				PickupTimeRange, // PickupTimeRange	string	50	The name of the time range. This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (Morning PU, 7:30 PU, etc)
				PickupStartTime, // PickupStartTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
				PickupEndTime, // PickupEndTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
				DeliveryTimeRange, // DeliveryTimeRange	string	50	The name of the time range. This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (Morning PU, 7:30 PU, etc)
				DeliveryStartTime, // DeliveryStartTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
				DeliveryEndTime, // DeliveryEndTime	string	10	This should match the time ranges set in SPOT in Program Configuration > Store Settings > Route Settings > OnDemand Time Ranges. (6:00 AM, 2:00 PM, etc)
			};

			//console.info('CustomerConnect.Route.SavePickupRequest',{pickupRequest})
			window.CustomerConnect.Route.SavePickupRequest(pickupRequest) //Promisify
			.done((pickupRequestResponse)=>{
				console.info('CustomerConnect.Route.SavePickupRequest',{pickupRequestResponse})
				
				//dispatch({type:BOOKING_IDLE,payload:{}});

				//noty.success('Booking request received!');

				// parse response
				const PickupRequestData = {Request:pickupRequest, Response:{...pickupRequestResponse.ReturnObject}};
				
				// tracking
				window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'submit', eventAction:'confirmed'});
				window.ga && window.ga('send', 'event', 'booking', 'submit', 'confirmed');//, [eventLabel], [eventValue], [fieldsObject]);

				// set booking state for rest of app
				dispatch({type:BOOKING_CONFIRMED,payload:PickupRequestData});

				resolve(PickupRequestData);

			})
			.fail( err => {
				console.error('CustomerConnect.Route.SavePickupRequest failed',{err});
				
				//dispatch({type:BOOKING_IDLE,payload:{}});
				
				//noty.error(err.Message || "Could not send booking request");
				
				reject(err);

			})
			
		})
	})
}