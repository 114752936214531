import SPOT_LOAD from './../../config/spot';

// import { Promisify } from './../../helpers/promisify';

import * as noty from './../../helpers/noty';


import { 
    //CONTACT_EMAIL_SET,
    CONTACT_EMAIL_EXISTS
} from './../shipping';

import { 
    loginPromptShow
} from '../login';

//export const CustomerConnect = window.CustomerConnect;

export const SpotSaveCustomer = (dispatch, customer)=>{
	return new Promise((resolve, reject)=>{
        SPOT_LOAD().then(()=>{

            console.log('CustomerConnect.Customer.SaveCustomer go',{customer});

            // sign up
            (window.CustomerConnect.Customer.SaveCustomer(customer)) //Promisify
            .done((data)=>{
                console.log('CustomerConnect.Customer.SaveCustomer',{data});
                if(!!data.Failed){
                    noty.error(data.Message)

                    reject(data);

                }
                else{
                    console.info('Account saved',{data});
                    //noty.success('Account created');
                    //noty.info('Booking collection and delivery now');

                    resolve(data);

                }
        
            })
            .fail( err =>{
                console.error('CustomerConnect.Customer.SaveCustomer',{err})
                
                if(err.Message && err.Message.match(/already exists/gi)){
                    
                    dispatch({
                        type: CONTACT_EMAIL_EXISTS,
                        payload:{emailExists:true}
                    });

                    loginPromptShow(dispatch);
            
                }
                
                reject(err);

            })
            
        })
    })
}