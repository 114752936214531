//nst SCHEDULE_TSV = window.SCHEDULE_TSV = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTaMh7l2CF8kVWFWWn1pKfCC8zdXkSbR-4ivnyVceftnKEx74ePW3uFW-FcTmRE22aQMNDsY07YOa6n/pub?output=tsv';
//nst SCHEDULE_TSV = window.SCHEDULE_TSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vRpvEDDsB9Sxs7kGHYmZMYXpNP3XMhLHSVkEssX4Doq5D3jl1TTH_hHx6WcY5dVAhMird7vVkLmBUuN/pub?output=tsv";
//nst SCHEDULE_TSV = window.SCHEDULE_TSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vRV97ShsVhXTTjnF_YoIz0PKwHQJFJLyhrSWbQEK0ZvTGYw5m4t5czwI6koghEwSMCPkjLON7ULlhJ2/pub?gid=613617061&single=true&output=tsv";
//nst SCHEDULE_TSV = window.SCHEDULE_TSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSc7fmcWDgS22dHLa9jeK58rjoFFp9Qad5a1SyrW1-lBUTjFrAM-uHwAofnMFamE_FfIoAjhV4Sd4vG/pub?gid=0&single=true&output=tsv";
//nst SCHEDULE_TSV = window.SCHEDULE_TSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-r3_TBqWCkTU1jYMoviqyP0VsWI-d3nAXe2myWHSY79hW90j6MPhw4oL9Ca6caV2pUR3B3ECKVPfS/pub?gid=811888117&single=true&output=tsv";
const SCHEDULE_TSV = window.SCHEDULE_TSV = "https://www.123cleaners.com/do/google?q=booking-schedule";

export default function load_schedule(){

    return new Promise((resolve, reject) => {

        fetch(SCHEDULE_TSV, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            //mode: 'no-cors', 
            cache: "no-cache"
        })
        .then(r=>r.text())
        .then( (data) => {
            
            let DATA = [];
            let HEAD = [];

            data = data
            .replace(/^[\n\r\s]+/gi,'')
            .replace(/^\d+\t/gi,'')
            .replace(/([\n\r]+)\d+\t/gi,'$1')
            .replace(/([\n\r]+)[\s\t]+[\n\r]+/gi,'$1')
            .split(/[\n\r]+/gi)
            .forEach((line_txt, line_num) => {
                const line = line_txt.split(/\t/);
                if(line_num===0){
                    HEAD = line;
                }
                else{
                    DATA[DATA.length] = line;
                }
            });
            
            DATA = DATA.map((line)=>{
                let obj = {};
                HEAD.forEach((head_txt, head_num)=>{
                    obj[head_txt] = line[head_num]
                })
                //////console.log('SCHEDULE',{line,obj});
                return obj;
            });

            // initialize schedule helper
            const SCHEDULE = DATA; //new Schedule(DATA);
            
            //console.log({SCHEDULE});
            window.SCHEDULE = SCHEDULE;
    
            resolve(SCHEDULE);
        })
        .catch((err) => {
            console.log("Unable to laod schedule", {err, SCHEDULE_TSV});
            reject(err)
        } )
    
    
    })

}
