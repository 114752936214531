import * as noty from './../helpers/noty';

import { PHRASES } from './../helpers/PHRASES'
import SPOT_LOAD from './../config/spot';

import {
	CHANGE_PASSWORD_PROMPT_PASSWORD
} from './changePassword'

import {
	BOOKING_SHOW
} from './booking'

import {
	BOOKING_CONTAINER
} from './wrapper'

import { 
    SPOT_LOGOUT, 
    SPOT_LOGIN
} from './spot.js';

//import SPOT_LOAD from './../config/spot';
import { SPOT_SESSION, SPOT_KILL_SESSION } from './../config/spot.Session';

import {
	CONTACT_EMAIL_SET, 
	CONTACT_EMAIL_EXISTS,
	//SET_POSTCODE,
	setPostcodeValue,
	//setPostcodeValueFromString,
} from './../modules/shipping';

import { SpotLoginRequest } from './spot/login'

//import { Promisify } from './../helpers/promisify';

export const LOGIN_REMEMBER_SHOW = 'Login/Remember/Show'
export const LOGIN_REMEMBER_HIDE = 'Login/Remember/Hide'
export const LOGIN_REMEMBER_BUSY = 'Login/Remember/Busy'
export const LOGIN_REMEMBER_IDLE = 'Login/Remember/Idle'
export const LOGIN_REMEMBER_SENT = 'Login/Remember/Sent'
export const LOGIN_REMEMBER_ERROR = 'Login/Remember/Error'

export const LOGIN_PROMPT_SHOW = 'Login/Prompt/Show'
export const LOGIN_PROMPT_HIDE = 'Login/Prompt/Hide'
export const LOGIN_PROMPT_BUSY = 'Login/Prompt/Busy'
export const LOGIN_PROMPT_IDLE = 'Login/Prompt/Idle'
export const LOGIN_PROMPT_LOGIN = 'Login/Prompt/Login'
export const LOGIN_PROMPT_ERROR = 'Login/Prompt/Error'

export const LOGIN_SET_PAYMENT = 'Login/Success/Set/Payment'
export const LOGIN_SET_SHIPPING = 'Login/Success/Set/Shipping'
export const LOGIN_SET_BILLING = 'Login/Success/Set/Billing'

export const LOGIN_PROMPT_PASSWORD = 'Login/Prompt/Password'
export const LOGIN_SET_PASSWORD = 'Login/Set/Password'
export const SET_RANDOM_TEMPORARY_PASSWORD = 'Login/Set/TemporaryPassword'
export const LOGIN_SUCCESS = 'Login/Success'


const CustomerConnect = window.CustomerConnect;

const initialState = {
	password: '',
	emailExists: false,
	visible: false,
	busy: false,
	remember_visible: false,
	remember_busy: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

	case SPOT_LOGIN:
		return {...state,...action.payload}
		
	case SPOT_LOGOUT:
		return {...state,...action.payload}
	
	case CONTACT_EMAIL_EXISTS:
		return {
		  ...state,
		  emailExists: action.payload.emailExists
		}

	case LOGIN_PROMPT_ERROR:
		return {...state,error:action.payload.error || 'Unknown Error'}
	
	case CONTACT_EMAIL_SET:
		return {
			...state,
			email: action.payload.email
		}

	case LOGIN_PROMPT_SHOW:
		return {...state,visible:true}

	case LOGIN_PROMPT_HIDE:
		return {...state,visible:false}

    case LOGIN_PROMPT_BUSY:
        return {...state,busy:true}
  
	case LOGIN_PROMPT_IDLE:
        return {...state,busy:false}
  
	case LOGIN_REMEMBER_SHOW:
		return {...state,remember_visible:true}

	case LOGIN_REMEMBER_HIDE:
		return {...state,remember_visible:false}

	case LOGIN_REMEMBER_BUSY:
		return {...state,remember_busy:true}

	case LOGIN_REMEMBER_IDLE:
		return {...state,remember_busy:false}
		
	case LOGIN_SET_PASSWORD:
	case LOGIN_PROMPT_PASSWORD:
	case CHANGE_PASSWORD_PROMPT_PASSWORD:
		return {
			...state,
			password:action.payload.password,
			passwordIsValid:passwordIsValid(action.payload.password)
		}
	
	case SET_RANDOM_TEMPORARY_PASSWORD:
		return {
			...state,
			manualLogin:false,
			needsPasswordReset:true,
			password:action.payload.password,
			passwordIsValid:passwordIsValid(action.payload.password),
		}
		
	case LOGIN_SUCCESS:
		return {
			...state,
			manualLogin:true,
			needsPasswordReset:false,
		}

	default:
	  return state

  }
}


export const passwordIsValid = (password)=> {
	if(!!password
		&& password.match(/[A-Z]/)
		&& password.match(/[a-z]/)
		&& password.match(/[0-9]/)
		//&& password.match(/[^0-9a-zA-Z]/)
	){
		return true;
	}
	else{
		return false;
	}
}

export const loginPromptShow = (dispatch) => {
	dispatch({ type: LOGIN_PROMPT_SHOW })
	dispatch({ type: LOGIN_PROMPT_IDLE })
}
export const loginPromptShowClick = () => {
	return dispatch => {
		console.log("loginPromptShowClick: loginPromptShow(dispatch)");
		loginPromptShow(dispatch)
	}
}

export const logoutButtonClick = () => {
	return dispatch => {
		//console.log('LOGOUT');
		
		console.log('SPOT_KILL_SESSION();');
		SPOT_KILL_SESSION();
		try{ window.SPOT.session = null; }catch(e){}
		try{ CustomerConnect.Config.SessionId = null; }catch(e){}
		try{ window.sessionStorage && window.sessionStorage.removeItem("ccApp.token"); }catch(e){}
		try{ CustomerConnect.User.Logout(); }catch(e){}
		
		//console.log('LOGOUT: start new session');
		SPOT_SESSION();

		// tracking
		window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'login', eventAction:'logout'});
		window.ga && window.ga('send', 'event', 'booking', 'login', 'logout');//, [eventLabel], [eventValue], [fieldsObject]);
		
		dispatch({
			type: SPOT_LOGOUT,
			payload: {}
		})

	}
}
export const loginPromptHide = (dispatch) => {
	dispatch({ type: LOGIN_PROMPT_HIDE })
}
export const loginPromptHideClick = () => {
	return dispatch => {
		
		// tracking
		window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'login', eventAction:'hide'});
		window.ga && window.ga('send', 'event', 'booking', 'login', 'hide');//, [eventLabel], [eventValue], [fieldsObject]);

		loginPromptHide(dispatch)
	}
}




export const LoadAllTheThings = (dispatch, state, { manualLogin, autoLogin })=> {
	//console.log('LoadAllTheThings START', state);

	return new Promise((resolve,reject)=>{

		//console.log('LoadAllTheThings aaa,bbb...');

		SPOT_LOAD()
		.then((aaa,bbb)=>{

			//console.log('LoadAllTheThings aaa,bbb', {state,aaa,bbb});
			
			// fill form with existign customer data
			(window.CustomerConnect.Customer.GetCustomer()) //Promisify
			.done(CustomerDataResponse=>{
				//console.log('LoadAllTheThings, CustomerData', {CustomerDataResponse})
				const CustomerData = CustomerDataResponse.ReturnObject;
				const BillingAddress = CustomerData.BillingAddress;
				const DeliveryAddress = CustomerData.DeliveryAddress;
				//console.log('LoadAllTheThings, CustomerData', {BillingAddress, DeliveryAddress})
		
				let shipping = {
					firstName: CustomerData.FirstName,
					lastName: CustomerData.LastName,
					phone: (((CustomerData.Phones && CustomerData.Phones[0]) || {}).Number || ''),
					company: DeliveryAddress.Addressee!==`${CustomerData.FirstName} ${CustomerData.LastName}` ? DeliveryAddress.Addressee : '',
					address1: DeliveryAddress.Address1,
					address2: DeliveryAddress.Address2,
					city: DeliveryAddress.City,
					county: DeliveryAddress.State,
					postcode: DeliveryAddress.Zip,
					email: CustomerData.EmailAddress
					//country: DeliveryAddress.Country,
				}
		
				let billing = {
					different_billing_address: 
						[DeliveryAddress.Address1,DeliveryAddress.Address2,DeliveryAddress.Addressee,DeliveryAddress.Zip].join('')
						===
						[BillingAddress.Address1,BillingAddress.Address2,BillingAddress.Addressee,BillingAddress.Zip].join('')
						? false
						: true
				}
				if(!!billing.different_billing_address){
					billing = {
						...billing,
						...{
							firstName: CustomerData.FirstName,
							lastName: CustomerData.LastName,
							company: BillingAddress.Addressee!==`${CustomerData.FirstName} ${CustomerData.LastName}` ? DeliveryAddress.Addressee : '',
							address1: BillingAddress.Address1,
							address2: BillingAddress.Address2,
							city: BillingAddress.City,
							county: BillingAddress.State,
							postcode: BillingAddress.Zip,
							//country: DeliveryAddress.Country,
						}
					}
				}
				let cards = [];
				if(!!CustomerData.CreditCards && CustomerData.CreditCards.length>0){
					cards = CustomerData.CreditCards
					.filter( card=> !card.MarkDeleted )
					.filter( card=> !card.CardDisabled )
				}

				// always be defined
				if(!autoLogin) autoLogin = false;
				if(!manualLogin) manualLogin = false;

				// only auto login if we actually auto login'd
				autoLogin = !!autoLogin && !!CustomerData.CustomerID;

				const { name:CustomerName } = billing;
				const { postcode, email:Email } = shipping;
				const { CustomerID, CustomerStoreID, CustomerStoreName, SessionID } = CustomerData;
				const LoginData = { manualLogin, autoLogin, Email, CustomerID, CustomerName, CustomerStoreID, CustomerStoreName, SessionID };
				
				//console.log('LoadAllTheThings, READY', {billing, shipping, postcode, LoginData, Email, CustomerName });

				dispatch({ type:SPOT_LOGIN,payload:LoginData });
				dispatch({ type:LOGIN_SET_PAYMENT, payload:{cards} });
				dispatch({ type:LOGIN_SET_SHIPPING, payload:shipping });
				dispatch({ type:LOGIN_SET_BILLING, payload:billing });
				
				console.log("setPostcodeValueFromString(shipping.postcode)", shipping.postcode);
				setPostcodeValue({dispatch, state, postcode:shipping.postcode});
				//setPostcodeValueFromString(shipping.postcode);

				resolve({state, billing, shipping, postcode});

			})
			.fail(err=>{
				//console.log('LoadAllTheThings, FAIL 2, CustomerConnect.Customer.GetCustomer', {state, err})
				reject(err, {state});
			});

		})
		.catch((err)=>{
			//console.log('LoadAllTheThings, FAIL 1, from SPOT_LOAD()', {state, err})
			reject(err, {state});
		});

	})

}

export const loginHandler = (dispatch, state) => {

	const LoginAttempt = {
		"EmailAddress": state.shipping.email,
		"Password": state.login.password
	};
	//console.log('loginHandler', {LoginAttempt})

	dispatch({ type:LOGIN_PROMPT_BUSY });

	//loginRequest(dispatch, LoginAttempt.EmailAddress, LoginAttempt.Password)
	SpotLoginRequest(dispatch, LoginAttempt.EmailAddress, LoginAttempt.Password)
	.then((data)=>{
		//console.log('loginPromptLogin OK', {data})

		//dispatch({ type:LOGIN_PROMPT_IDLE });

		// tracking
		window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'login', eventAction:'success'});
		window.ga && window.ga('send', 'event', 'booking', 'login', 'success');//, [eventLabel], [eventValue], [fieldsObject]);
		
		/*if(!data || !!data.Failed){
			console.error('SpotLoginRequest FAILED', {LoginAttempt, data});
			
			noty.error(data.Message || "Login failed!");
		
		}
		else */if(data && data.CustomerID){

			dispatch({ type:LOGIN_SUCCESS });

			dispatch({ type:LOGIN_PROMPT_HIDE });

			LoadAllTheThings(dispatch, state, {manualLogin:true})
			.then(({state, billing, shipping, postcode, LoginData})=>{
				//console.log('loginPromptLogin OK: LoadAllTheThings DONE', {state,billing,shipping,postcode,LoginData});
				
				setPostcodeValue({dispatch, state, postcode});
				//dispatch({ type:SET_POSTCODE, payload:{postcode:shipping.postcode} });

				//dispatch({ type:LOGIN_SET_PAYMENT, payload:{cards} });
				//dispatch({ type:LOGIN_SET_SHIPPING, payload:shipping });
				//dispatch({ type:LOGIN_SET_BILLING, payload:billing });

				dispatch({ type:LOGIN_PROMPT_IDLE });

				// move to booking form, if needed
				if(state.visible_container!==BOOKING_CONTAINER){
					//console.log('loginPromptLogin OK: LoadAllTheThings DONE, dispatch BOOKING_SHOW',{state});
					dispatch({ type:BOOKING_SHOW });
				}
				else{
					//console.log('loginPromptLogin OK: LoadAllTheThings DONE, already on booking form, do nothing',{state});
				}
					
			});
	
		}
		else{

			dispatch({ type:LOGIN_PROMPT_IDLE });

		}

	})
	.catch((err)=>{
		//console.log('loginPromptLogin error', {err})

		dispatch({ type:LOGIN_PROMPT_IDLE });

		// tracking
		window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'login', eventAction:'error'});
		window.ga && window.ga('send', 'event', 'booking', 'login', 'error');//, [eventLabel], [eventValue], [fieldsObject]);
		
		noty.error((err && err.Message) || PHRASES.LOGIN_FAILED || "Login failed!");
		//if(!data || !!data.Failed){
		//	console.error('SpotLoginRequest FAILED', {LoginAttempt, data});
		//	
		//	noty.error(data.Message || "Login failed!");
		//
		//}

	})

};

export const loginClick = () => {
	return (dispatch, getState) => {
	
		const state = getState();
		//console.log('try to login now', {state})
		
		loginHandler(dispatch, state);

	}
}

// Name	Type	Description
// EmailAddress	string	The email address for which a password reset is requested.
// IPAddress	string	The IP Address from which the password change was requested.
// SendEmail	Boolean	If true, automatically sends a PasswordReminder email. Defaults to false
// ResetFinishURL	string	The URL to provide in the email template for the user to finish the reset. Must be provided if SendEmail is true. The string %code% will be replaced with the RememberKey

export const resetPasswordRequest = (dispatch, email)=>{
	return new Promise((resolve, reject)=>{

		const ResetPasswordRequest = {
			EmailAddress: email
		};
		//console.log('resetPasswordRequest', {ResetPasswordRequest});

		CustomerConnect.User.PasswordReminder(ResetPasswordRequest) //Promisify
		.done((ResetPasswordResponse)=>{
			//console.log('resetPasswordRequest',{ResetPasswordRequest, ResetPasswordResponse})

			if(!!ResetPasswordResponse.Failed){
				console.error("resetPasswordRequest FAIL", {ResetPasswordRequest, ResetPasswordResponse});
				noty.error(ResetPasswordResponse.Message || "Could not send email to reset password");
				
				dispatch({ type:LOGIN_REMEMBER_ERROR, payload:{error:ResetPasswordResponse.Message} });
				reject(ResetPasswordResponse);
				return;
			
			}
			else{
				console.info('resetPasswordRequest OK!', {ResetPasswordRequest, ResetPasswordResponse});
				//noty.success('Logged in!');

				// RIDICULOUS, now we have the actual key we tell spot to send the email
				const ResetPasswordEmailData = {
					RememberKey: ResetPasswordResponse.ReturnObject.RememberKey,
					FinishURL: "https://www.123cleaners.com/spot/#/reminder/%code%".replace(/%code%/gi,ResetPasswordResponse.ReturnObject.RememberKey)
				}
				const ResetPasswordEmailRequest = {
					Data: JSON.stringify(ResetPasswordEmailData),
					Template: 7,
					ToAddress: ResetPasswordRequest.EmailAddress
				}
				CustomerConnect.Customer.SendEmail(ResetPasswordEmailRequest)
				.then((ResetPasswordEmailResponse)=>{
					//console.log('resetPasswordRequest email',{ResetPasswordEmailRequest, ResetPasswordEmailResponse})

					noty.success(ResetPasswordEmailRequest.Message || "Link sent to your email");

					dispatch({ type:LOGIN_REMEMBER_SENT,payload:ResetPasswordResponse });
					resolve(ResetPasswordResponse);

				})
				//.catch( err =>{
				//	console.error('resetPasswordRequest email err',{err})
				//	reject(err);
				//})

			}
		
		})
		.fail( err =>{
			console.error('resetPasswordRequest err',{err})
			reject(err);
		})
		
	})
}

export const resetPasswordHandler = (dispatch, state)=>{
	return new Promise((resolve, reject)=>{
		
		const email = state.login.email;
		//console.log('resetPasswordHandler', {email})

		dispatch({ type:LOGIN_REMEMBER_BUSY });

		resetPasswordRequest(dispatch, email)
		.then((ResetPasswordResponse)=>{
			//console.log('resetPasswordHandler', {email, ResetPasswordResponse})
			
			dispatch({ type:LOGIN_REMEMBER_IDLE });

			// tracking
			window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'password-reset', eventAction:'success'});
			window.ga && window.ga('send', 'event', 'booking', 'password-reset', 'success');//, [eventLabel], [eventValue], [fieldsObject]);
		
		})
		.catch( err =>{

			// tracking
			window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'password-reset', eventAction:'error'});
			window.ga && window.ga('send', 'event', 'booking', 'password-reset', 'error');//, [eventLabel], [eventValue], [fieldsObject]);

			console.error('resetPasswordHandler',{err});
			reject(err);
		})
		
	})
}

export const resetPasswordClick = () => {
	return (dispatch, getState) => {
	
		const state = getState();
		//console.log('remember password', {state})
		
		resetPasswordHandler(dispatch, state);

	}
}

export const setPassword = ({target:{value:password}}) => {
	return dispatch => {
	  setPasswordValue(dispatch, password)
	}
  }
  export const setPasswordValue = (dispatch, password) => {
	dispatch({
	  type: LOGIN_PROMPT_PASSWORD,
	  payload:{password}
	})
  };