export const CONFIRM_TERMS_AND_CONDITIOSN = 'Confirm/TermsAndConditions'

const initialState = { 
    accepted_terms_and_conditions: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case CONFIRM_TERMS_AND_CONDITIOSN:
        return {
            ...state,
            accepted_terms_and_conditions: action.payload.accepted_terms_and_conditions
        }      

        default:
        return state
    }
}



export const setTermsAndConditionsAcceptance = ({target:{checked:accepted_terms_and_conditions}}) => {
  return dispatch => {
    setTermsAndConditionsAcceptanceValue(dispatch, accepted_terms_and_conditions)
  }
}
export const setTermsAndConditionsAcceptanceValue = (dispatch, accepted_terms_and_conditions) => {
  dispatch({
    type: CONFIRM_TERMS_AND_CONDITIOSN,
    payload:{accepted_terms_and_conditions}
  })
};