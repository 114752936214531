import { 
  isPostcodeValid, 
  postcodeArea, 
  formatPostcode, 
  //PostcodeHasArea
} from './../helpers/postcode'

//import { K } from './../config/constants';

import { loadSchedule } from './../modules/_SCHEDULE'
import { setEarliestCollectSlot } from './../modules/schedule'
import { LOGIN_SET_SHIPPING } from './login';

export const SHIPPING_SCOPE = "shipping";

export const SET_EMAIL = 'Shipping/Set/Email'
export const SET_POSTCODE = 'Shipping/Set/Postcode'
export const SET_SHIPPING_ADDRESS = 'Shipping/Set/Address'

export const CONTACT_EMAIL_SET = 'Contact/Email/Set'
export const CONTACT_EMAIL_EXISTS = 'Contact/Email/Exists'

export const SHIPPING_ADDRESS_LOOKUP_WORKING = 'Shipping/Address/Lookup/Working'
export const SHIPPING_ADDRESS_LOOKUP_DONE = 'Shipping/Address/Lookup/Done'
export const SHIPPING_ADDRESS_LOOKUP_SHOW = 'Shipping/Address/Lookup/Show'
export const SHIPPING_ADDRESS_LOOKUP_HIDE = 'Shipping/Address/Lookup/Hide'

export const ADDRESS_LOOKUP_BASE = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyA65AFd_M7rPsckzpWJyaYJXCA_MRJo134&input='
export const GOOGLE_MAPS_API_KEY = 'AIzaSyA65AFd_M7rPsckzpWJyaYJXCA_MRJo134';

export const SHIPPING_ADDRESS_USER_INPUT = 'Shipping/Address/UserInput'

const initialState = { 
    firstName:'',lastName:'',company:'',address1:'',address2:'',address3:'',city:'',county:'',
    postcode: ('localStorage' in window && localStorage.getItem('postcode')) || '',
    country:'',
    addresses: [],
    addressLookupVisible: false,
    emailExists: false,
    known_area: false, postcode_is_valid: false, area: '',
};



export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POSTCODE:
      return {
        ...state,
        postcode: action.payload.postcode,
        postcode_is_valid: action.payload.postcode_is_valid || isPostcodeValid(action.payload.postcode),
        known_area: action.payload.known_area || !!postcodeArea(action.payload.postcode),
        area: action.payload.area || postcodeArea(action.payload.postcode),
      }

    case CONTACT_EMAIL_SET:
      return {
        ...state,
        email: action.payload.email
      }

    case CONTACT_EMAIL_EXISTS:
      return {
        ...state,
        emailExists: action.payload.emailExists
      }

    case SHIPPING_ADDRESS_LOOKUP_WORKING:
      return {
        ...state,
        looking_up_addresses: true
      }

    case SHIPPING_ADDRESS_LOOKUP_DONE:
      return {
        ...state,
        looking_up_addresses: false,
        addresses: action.payload
      }

      case SHIPPING_ADDRESS_LOOKUP_SHOW:
      return {
        ...state,
        addressLookupVisible: true
      }

    case SHIPPING_ADDRESS_LOOKUP_HIDE:
      return {
        ...state,
        addressLookupVisible: false
      }

    case LOGIN_SET_SHIPPING:
      return {
        ...state,
        ...action.payload
      }

    case SHIPPING_ADDRESS_USER_INPUT:
      return {
        ...state,
        ...{ [action.payload.field]: action.payload.value }
        /* 
        ...(({
            field,
            value
        })=>{
            let o = {};
            o[field] = value;
            return o;
        })({
            field: action.payload.field,
            value: action.payload.value
        })
        */
      }

		case SET_SHIPPING_ADDRESS:
		  return {
				...state,
				...((address_index, addresses, state) => {
					const address = addresses[parseInt(address_index)];
					const output = address ? {
						address1: address.line_1,
						address2: address.line_2,
						address3: address.line_3,
            county: address.county,
            city: address.town_or_city
					} : {};
					////console.log('SET_SHIPPING_ADDRESS',{address_index,output,address,addresses,state});
          return output
				})(action.payload, state.addresses)
      }


    default:
      return state
  }
}


export const setPostcodeValueFromString = (value) => {
  const postcode = value.toUpperCase();
  return (dispatch, getState) => {
    const state = getState();
    setPostcodeValue({dispatch, state, postcode});
  }
}

export const setPostcode = ({target:{value}}) => {
  const postcode = value.toUpperCase();
  return (dispatch, getState) => {
    const state = getState();
    setPostcodeValue({dispatch, state, postcode})
  }
}
export const setPostcodeValue = ({dispatch, state, postcode}) => {

  const area = postcodeArea(postcode);
  const known_area = !!area || postcodeArea(postcode);
  const postcode_is_valid = isPostcodeValid(postcode);

  postcode = formatPostcode(postcode);

  //console.log('setPostcodeValue', {postcode, area, postcode_is_valid});

  dispatch({
    type: SET_POSTCODE,
    payload:{postcode, area, known_area, postcode_is_valid}
  });

  if(!!known_area){

    if('localStorage' in window){
      if(postcode!==localStorage.getItem('postcode', postcode)){
        localStorage.setItem('postcode', postcode)
      }
    };

    loadSchedule({dispatch, state, postcode})
      .then(
        ({SCHEDULE})=>{
          ////console.log('schedule loaded',{SCHEDULE});
          ////console.log('schedule loaded so setEarliestCollectSlot',{SCHEDULE});
          setEarliestCollectSlot(dispatch, SCHEDULE, postcode);
        }
      )
      .catch(
        (err)=> console.error('Unable to load schedule',{err,state,postcode})
      )
    ;

  }

};


export const lookupAddresses = (dispatch, postcode) => {

    return; ////console.log('lookupAddresses is currently disabled!', {postcode})===1;
  /*
    ////console.log('lookupAddresses!', {postcode});

    dispatch({type: SHIPPING_ADDRESS_LOOKUP_WORKING});

    const pc = postcode.toUpperCase().replace(/[^0-9A-Z]/gi,'');
    const qry = 'https://api.getaddress.io/find/'+pc+'?api-key=CXUJxaYQJ0-UbFaG8lV_Xw16200&expand=true';

    fetch(
      qry
    )
    .then( (r)=>r.json() )
    .then( (results) =>{
      ////console.log('lookupAddresses!', {results});
      
      dispatch({type: SHIPPING_ADDRESS_LOOKUP_DONE, payload:results && results.addresses || []});
      
      dispatch({type: SHIPPING_ADDRESS_LOOKUP_SHOW});

    })
  */
}

export const setShippingAddress = (event) => {
	const index = event.currentTarget.dataset.index;
	////console.log('setShippingAddress', {index});
  return dispatch => {

		dispatch({
      type: SET_SHIPPING_ADDRESS,
      payload: index
    })
      
		dispatch({type: SHIPPING_ADDRESS_LOOKUP_HIDE});
		
  }
}
