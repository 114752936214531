import React from 'react';
import { connect } from 'react-redux'
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
//import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import red from '@material-ui/core/colors/red';
import SvgIcon from '@material-ui/core/SvgIcon';



import {
	setShippingAddress
} from './../modules/shipping'
 
const styles = theme => ({
    //root: { fontSize: '16px', },
    list: {
        width: 600,
      },
      fullList: {
        width: 'auto',
      },
      icon: {
        margin: theme.spacing(2),
      },
      iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
          color: red[800],
        },
      },
});

  
function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
}

const AddressLookup = ({
    PHRASES, shipping, classes, setShippingAddress
}) => {

  const addressLookupListOpen = !!(shipping.addressLookupVisible && shipping.addresses && shipping.addresses.length);
  
    return (
        <Drawer anchor="right" open={addressLookupListOpen}>
          <div
            tabIndex={0}
            role="button"
          >

            <div className={classes.list}>
            <List>
                {shipping.addresses.map((address, index) => (
                <ListItem button key={index} onClick={setShippingAddress} data-index={index}>
                    <ListItemIcon>
                        <HomeIcon className={classes.icon} color="action" />
                    </ListItemIcon>
                    <ListItemText primary={[address.line_1, address.line_2].join('; ')} secondary={address.company} />
                </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['Enter Manually'].map((text, index) => (
                <ListItem button key={text} onClick={setShippingAddress}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>
                ))}
            </List>
            </div>

          </div>
        </Drawer>
    );
}

const mapStateToProps = ({
    PHRASES, shipping
  }) => ({
    PHRASES, shipping
  })
  
export default withStyles(styles)(connect(
    mapStateToProps,
    {
      setShippingAddress
    }
)(AddressLookup));