import { Schedule, Moment } from './../helpers/schedule'

//import { K } from './../config/constants'

import {
  MOMENT_TODAY,
  MOMENT_DAYS,
  MIN_TURN_AROUND
} from './../config/constants';

const moment = require('moment');
//const _ = require('lodash/core');


export const SCHEDULE_SET_COLLECT = 'Schedule/Set/Collect'
export const SCHEDULE_SET_DELIVER = 'Schedule/Set/Deliver'
export const SCHEDULE_SET_PICKER_MODE = 'Schedule/Set/PickerMode'

export const SCHEDULE_LOCK_COLLECT = 'Schedule/Lock/Collect'
export const SCHEDULE_LOCK_DELIVER = 'Schedule/Lock/Deliver'

export const SCHEDULE_LOOKUP_WORKING = 'Schedule/Lookup/Working'
export const SCHEDULE_LOOKUP_DONE = 'Schedule/Lookup/Done'
export const SCHEDULE_LOOKUP_SHOW = 'Schedule/Lookup/Show'
export const SCHEDULE_LOOKUP_HIDE = 'Schedule/Lookup/Hide'

export const SCOPE_COLLECT = "collect";
export const SCOPE_DELIVER = "deliver";
export const SCOPE_FIXED = "fixed";

const initialState = { 
    collect:{},
    deliver:{},
    locked: {},
    collectSet: false,
    picker_mode: 'collect',
    scheduleLookupIsVisible: false
};


export default (state = initialState, action) => {
  switch (action.type) {

    case SCHEDULE_SET_PICKER_MODE:
      //console.log('SCHEDULE_SET_COLLECT',{payload:action.payload});
      return {
        ...state,
        picker_mode: action.payload.picker_mode || state.picker_mode,
        //collectSet: action.payload.picker_mode==SCOPE_DELIVER ? true : false
      }

    case SCHEDULE_SET_COLLECT:
      //console.log('SCHEDULE_SET_COLLECT',{payload:action.payload,collectSet:state.collectSet,state});
      return {
        ...state,
        collect: action.payload.slot,
        picker_mode: action.payload.picker_mode || state.picker_mode,
        //collectSet: action.payload.fromClick,
        //picker_mode: action.payload.fromClick ? SCOPE_DELIVER : SCOPE_COLLECT
      }

    case SCHEDULE_SET_DELIVER:
      //console.log('SCHEDULE_SET_DELIVER',{payload:action.payload,collectSet:state.collectSet,state});
      return {
        ...state,
        deliver: action.payload.slot,
        picker_mode: action.payload.picker_mode || state.picker_mode,
        //collectSet: action.payload.fromClick ? false : state.collectSet
      }

    case SCHEDULE_LOCK_COLLECT:
      return {
        ...state,
        locked: { ...state.locked, collect: action.payload }
      }

    case SCHEDULE_LOCK_DELIVER:
      return {
        ...state,
        locked: { ...state.locked, deliver: action.payload }
      }



    case SCHEDULE_LOOKUP_WORKING:
      return {
        ...state,
        looking_up_schedule: true
      }

    case SCHEDULE_LOOKUP_DONE:
      return {
        ...state,
        looking_up_schedule: false,
        collect: action.payload.collect || state.collect,
        deliver: action.payload.deliver || state.deliver
      }

    case SCHEDULE_LOOKUP_SHOW:
      return {
        ...state,
        collectionSlotsVisible: true
      }

    case SCHEDULE_LOOKUP_HIDE:
      return {
        ...state,
        collectionSlotsVisible: false
      }

    default:
      return state
  }
}



//export const lookupSchedule = (dispatch, state) => {
//  ////console.log('lookupSchedule', {state});
//  setEarliestCollectSlot(dispatch, state)
//  ////console.log('lookupSchedule DONE!');
//  dispatch({type: SCHEDULE_LOOKUP_SHOW});
//}



export const firstSlotFrom = ( SCHEDULE, restrict ) => {

  //console.log('firstSlotFrom', {SCHEDULE});
  const postcode = SCHEDULE.postcode;
  const schedule = SCHEDULE.schedule.for ? SCHEDULE.schedule : (SCHEDULE.SCHEDULE ? new Schedule(SCHEDULE.SCHEDULE) : new Schedule(SCHEDULE));
  const slot = schedule.for(postcode).first( restrict );
  //console.log('firstSlotFrom slot', {slot});
  
  return slot;
}


export const later = (event) => {
	//const index = event.currentTarget.dataset.index;
	return (dispatch, getState) => {
    const state = getState();
    ////console.log('later', {index,state});
    const current = Moment(state.schedule.collect.DATE || MOMENT_TODAY);
    const newdate = current.add(1, MOMENT_DAYS);
    ////console.log('later', {current,newdate});
    setCollectDate({dispatch,state,newdate});
		
  }
}

export const handleDayClick = (scope, date, schedule) => {
  return (dispatch, getState) => {
    const state = getState();
    //console.log('handleDayClick',{scope, date, state, schedule});
    
    const actions = {};
          actions[SCOPE_COLLECT] = setCollectDate;
          actions[SCOPE_DELIVER] = setDeliverDate;


    const SCHEDULE = state.SCHEDULE;
    const fromClick = true;
    const collect = schedule.collect.DATE;
    const collectSet = schedule.collectSet;
    const picker_mode = schedule.picker_mode;
    const Mdate = Moment(date);
    const Mcollect = Moment(collect);
    const Mdeliver = Mcollect.add(MIN_TURN_AROUND,MOMENT_DAYS);
    const isAfter = Mdate.isAfter(Mdeliver);
    
    //console.log('handleDayClick A',{scope, date, collectSet, picker_mode, collect, isAfter, Mdate, Mcollect, Mdeliver});

    // switch scope
    if((collectSet || picker_mode===SCOPE_DELIVER) && collect && isAfter){
      scope = SCOPE_DELIVER;
    }
    else{
      scope = SCOPE_COLLECT;
    }

    // toggle picker mode from collect to delier
    //const this_picker_mode = scope;
    const next_picker_mode = (scope===SCOPE_COLLECT ? SCOPE_DELIVER : (scope===SCOPE_DELIVER ? SCOPE_FIXED : SCOPE_COLLECT));

    //console.log('handleDayClick B',{scope, date, collectSet, picker_mode, this_picker_mode, next_picker_mode, collect, isAfter});

    if(actions[scope]){
      actions[scope]({dispatch,SCHEDULE,date,fromClick,picker_mode:next_picker_mode});
    }
    else{
      console.error("unknown date click", {scope, date, state})
    }

  }
}

export const setDatePickerModeTo = ({event, schedule, shipping, picker_mode}) => {
  return (dispatch, getState) => {
    //const state = getState();

    //console.log('setDatePickerModeTo', picker_mode); //{event, schedule, shipping, picker_mode});

    dispatch({type:SCHEDULE_SET_PICKER_MODE,payload:{picker_mode}})
  }
}


////console.log('MOMENT DEBUG - GLOBAL RIGHT',{moment:moment(), s:moment().format('YYYY-MM-DD'), d:moment().toDate(), g:moment().get()});

export const setEarliestCollectSlot = (dispatch, SCHEDULE) => {
  ////console.log('MOMENT DEBUG - setEarliestCollectSlot RIGHT',{moment:moment(), s:moment().format('YYYY-MM-DD'), d:moment().toDate(), g:moment().get()});
  const date = moment();
  ////console.log('MOMENT DEBUG - setEarliestCollectSlot WRONG',{date, s:date.format('YYYY-MM-DD'), d:date.toDate(), d:date.get()});
  setCollectDate({dispatch,SCHEDULE,date});
}

export const setCollectDate = ({dispatch,SCHEDULE,date,picker_mode,fromClick}) => {
  ////console.log('MOMENT DEBUG - setCollectDate',{moment:moment()});
  ////console.log('MOMENT DEBUG - setCollectDate',{date}); //return;
  const firstSlot = firstSlotFrom(SCHEDULE, date);
  ////console.log('setCollectDate firstSlot',{firstSlot}); //return;
  const slot = firstSlot && !!firstSlot.DATE ? firstSlot : {};
  ////console.log('setCollectDate setCollectSlot(slot)',{slot}); //return;
  setCollectSlot({dispatch,SCHEDULE,slot,picker_mode,fromClick});
}

export const setCollectSlot = ({dispatch,SCHEDULE,slot,picker_mode,fromClick}) => {
  ////console.log('setCollectSlot',{slot}); //return;
  dispatch({type: SCHEDULE_SET_COLLECT, payload: {slot,picker_mode,fromClick}});
  //setEarliestDeliverSlot(dispatch, state);
  setEarliestDeliverSlotGivenCollectDate(dispatch, SCHEDULE, slot.DATE);
}


export const setEarliestDeliverSlotGivenCollectDate = (dispatch, SCHEDULE, known_collect_date) => {
  const collect_date = known_collect_date;// || state.schedule.collect.DATE;
  ////console.log('setEarliestDeliverSlotGivenCollectDate a',{collect_date,MOMENT_FORMAT});
  const Collect_Date = Moment(collect_date);
  ////console.log('setEarliestDeliverSlotGivenCollectDate b',{Collect_Date,MIN_TURN_AROUND,MOMENT_DAYS});
  const min_delivery_date = Collect_Date.add(MIN_TURN_AROUND, MOMENT_DAYS);
  ////console.log('setEarliestDeliverSlotGivenCollectDate c',{min_delivery_date});
  setDeliverDate({dispatch,SCHEDULE,date:min_delivery_date});
}

export const setEarliestDeliverSlot = (dispatch, state) => {
  return;/* //console.log('never used');
  const collect_date = state.schedule.collect.DATE;
  const default_date = MOMENT_TODAY;
  ////console.log('setEarliestDeliverSlot a',{collect_date,default_date});
  const base_date = collect_date || default_date;
  const Base_Date = Moment(base_date);
  ////console.log('setEarliestDeliverSlot b',{base_date,Base_Date,MIN_TURN_AROUND,MOMENT_DAYS});
  const min_delivery_date = Base_Date.add(MIN_TURN_AROUND, MOMENT_DAYS);
  ////console.log('setEarliestDeliverSlot c',{min_delivery_date}); //return;
  setDeliverDate({dispatch,state,date:min_delivery_date});*/
}

export const setDeliverDate = ({dispatch,SCHEDULE,date,picker_mode,fromClick}) => {
  ////console.log('setDeliverDate',{state,date}); //return;
  const firstSlot = firstSlotFrom(SCHEDULE, date);
  ////console.log('setDeliverDate firstSlot',{firstSlot}); //return;
  const slot = firstSlot && !!firstSlot.DATE ? firstSlot : {};
  ////console.log('setDeliverDate setDeliverSlot(slot)',{slot}); //return;
  setDeliverSlot({dispatch,SCHEDULE,slot,picker_mode,fromClick});
}

export const setDeliverSlot = ({dispatch,SCHEDULE,slot,picker_mode,fromClick}) => {
  ////console.log('setDeliverSlot',{slot}); //return;
  dispatch({type: SCHEDULE_SET_DELIVER, payload: {slot,picker_mode,fromClick}});
}
