import {
    sendMobileCode,
    loginWithMobileCode,
    loginWithPassword,
    showPasswordPrompt,
    SpotLoginRequest
} from './login';

import { setEmail, setEmailValue } from './email';
import { SpotState, } from './state';
import { SpotSaveCustomer, } from './saveCustomer';
import { SpotSavePickupRequest, } from './savePickupRequest';


const CustomerConnect = window.CustomerConnect;

export {
    CustomerConnect,

    setEmail,
    setEmailValue,

    sendMobileCode,
    loginWithMobileCode,
    loginWithPassword,
    showPasswordPrompt,
    SpotLoginRequest,

    SpotState,
    SpotSaveCustomer,
    SpotSavePickupRequest,
}