import { formatPostcode } from './../helpers/postcode'

import { 
    SHIPPING_SCOPE,
    SHIPPING_ADDRESS_USER_INPUT,
    setPostcodeValue
} from './shipping'

import { 
    //BILLING_SCOPE,
    BILLING_ADDRESS_USER_INPUT
} from './billing'

import { 
    //EMAIL_FIELD,
    POSTCODE_FIELD,
} from './../config/constants'

const dispatch_target = {
    shipping: SHIPPING_ADDRESS_USER_INPUT,
    billing: BILLING_ADDRESS_USER_INPUT
}

export const updateStateWithUserInput = ({
    target
}) => {
    return (dispatch, getState) => {
        
        const state = getState();

        let {
            value,
            dataset:{
                scope,
                field
            }
        } = target;
        
        //////console.log('updateStateWithUserInput',{scope,field,value,target});

        if(!!scope){

            if(scope===SHIPPING_SCOPE && field===POSTCODE_FIELD){

                // let a specialised procedure handle shipping postcode change
                setPostcodeValue({dispatch, state, postcode:value});

            }
            else{

                // always format postcode
                if(field===POSTCODE_FIELD){
                    value = formatPostcode(value);
                }
                
                // put data in state
                dispatch({
                    type: dispatch_target[scope],
                    payload:{scope,field,value}
                })

            }

        }

    }
}
  