import { 
    SPOT_LOGOUT, 
    SPOT_LOGIN
} from './../spot.js';

import { SPOT_KILL_SESSION, SPOT_SESSION } from './../../config/spot.Session';

import { 
    CONTACT_EMAIL_SET,
    CONTACT_EMAIL_EXISTS
} from './../shipping';

// import { Promisify } from './../../helpers/promisify';

//export const CustomerConnect = window.CustomerConnect || null;

export const setEmail = ({target:{value:email}}) => {
    return (dispatch, getState) => {
      setEmailValue(dispatch, email, getState())
    }
}

export const setEmailValue = (dispatch, input, state) => {

    const prev_login = state.SPOT.login;
    //const prev_email = state.login.email;
    //const prev_emailExists = state.login.emailExists;

    const email = (input || '').replace(/[\s]+/gi,'').toLowerCase();

    dispatch({
      type: CONTACT_EMAIL_SET,
      payload:{email}
    });

    //if(!!state.SPOT.login){
    //    //console.log('Do not check email, we are logged in!');    
    //}
    //else{

        ////console.log('CheckEmail!', {email});
        if(!window.CustomerConnect){
            console.error("CustomerConnect is not loaded, quit now");
            return;
        }

        if(!window.CustomerConnect.Customer.CheckEmail){
            console.error("CustomerConnect CheckEmail function is not present, wtf?");
            return;
        }

        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
            console.warn("CheckEmail not a valid email yet", email);
            return;
        }


        const OkayGoCheckEmail = function(){
            //console.log('CheckEmail OkayGoCheckEmail', {email,state});
            
            //const CheckEmailQuery = CustomerConnect.Customer.CheckEmail(email)
            ////console.log('CheckEmail OkayGoCheckEmail CheckEmailQuery', {CheckEmailQuery,email,state});
            //CheckEmailQuery

            (window.CustomerConnect.Customer.CheckEmail(email)) //Promisify
            .done((resp)=>{
            
                ////console.log('CheckEmail OkayGoCheckEmail CheckEmailQuery Response', {CheckEmailQuery,email,state,resp});

                //console.log('CheckEmail Response', {email,state,resp});
        
                const emailExists = resp && !!resp.Failed;
        
                //console.log('CheckEmail',{prev_email,prev_emailExists,email,emailExists,prev_login});
        
                dispatch({
                    type: CONTACT_EMAIL_EXISTS,
                    payload:{emailExists}
                });
        
                window.TempCredentials = window.TempCredentials || {};
                const idle_payload = window.TempCredentials[email] || {
                    Email:"",
                    CustomerID:"", 
                    CustomerName:"", 
                    CustomerStoreID:"" ,
                    CustomerStoreName:"",
                    emailExists
                };
        
                //if(!!emailExists){
        
                    if(idle_payload && idle_payload.CustomerID){
                        dispatch({
                            type: SPOT_LOGIN,
                            payload: idle_payload
                        })
                    }
                    else{
        
                        if(!!prev_login){
                            window.CustomerConnect.User.Logout(); 
                            SPOT_KILL_SESSION();
                            SPOT_SESSION();
                        }
        
                        dispatch({
                            type: SPOT_LOGOUT,
                            payload: idle_payload
                        })
                    }
                //}
        
        
            })
            .fail(e=>{
                //console.log('CheckEmail catch',{e,prev_email,prev_emailExists,email,prev_login});
            })
            //.always(e=>{
            //    //console.log('CheckEmail always',{e,prev_email,prev_emailExists,email,prev_login});
            //})

        }


        if(!!window.OkayGoCheckEmail)
            window.clearTimeout(window.OkayGoCheckEmail);
        
        window.OkayGoCheckEmail = setTimeout(()=>{
            OkayGoCheckEmail(email);
        }, 1000)




    //}

};

