//import * as noty from '../helpers/noty';
//import { PHRASES } from '../helpers/PHRASES'
//import SPOT_LOAD from '../config/spot';

import {
	passwordIsValid,
	LOGIN_SET_PASSWORD,
	LOGIN_PROMPT_PASSWORD,
	SET_RANDOM_TEMPORARY_PASSWORD
} from './login'

import { 
    SPOT_LOGOUT, 
    SPOT_LOGIN
} from './spot.js';


import {
	CONTACT_EMAIL_SET, 
	CONTACT_EMAIL_EXISTS,
} from './shipping';

import { SpotChangePasswordRequest } from './spot/changePassword'

//import { Promisify } from '../helpers/promisify';


export const CHANGE_PASSWORD_PROMPT_SHOW = 'changePassword/Prompt/Show'
export const CHANGE_PASSWORD_PROMPT_HIDE = 'changePassword/Prompt/Hide'
export const CHANGE_PASSWORD_PROMPT_BUSY = 'changePassword/Prompt/Busy'
export const CHANGE_PASSWORD_PROMPT_IDLE = 'changePassword/Prompt/Idle'
export const CHANGE_PASSWORD_PROMPT_LOGIN = 'changePassword/Prompt/Login'
export const CHANGE_PASSWORD_PROMPT_ERROR = 'changePassword/Prompt/Error'

export const CHANGE_PASSWORD_PROMPT_PASSWORD = 'changePassword/Prompt/Password'


//const CustomerConnect = window.CustomerConnect;

const initialState = {
	password: '',
	visible: false,
	busy: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

	case SPOT_LOGIN:
		return {...state,...action.payload}
		
	case SPOT_LOGOUT:
		return {...state,...action.payload}
	
	case CONTACT_EMAIL_EXISTS:
		return {
		  ...state,
		  emailExists: action.payload.emailExists
		}

	case CHANGE_PASSWORD_PROMPT_ERROR:
		return {...state,error:action.payload.error || 'Unknown Error'}
	
	case CONTACT_EMAIL_SET:
		return {
			...state,
			email: action.payload.email
		}

	case CHANGE_PASSWORD_PROMPT_SHOW:
		return {...state,visible:true}

	case CHANGE_PASSWORD_PROMPT_HIDE:
		return {...state,visible:false}

    case CHANGE_PASSWORD_PROMPT_BUSY:
        return {...state,busy:true}
  
	case CHANGE_PASSWORD_PROMPT_IDLE:
        return {...state,busy:false}
  		
	case LOGIN_SET_PASSWORD:
	case LOGIN_PROMPT_PASSWORD:
	case CHANGE_PASSWORD_PROMPT_PASSWORD:
	case SET_RANDOM_TEMPORARY_PASSWORD:
		return {
			...state,
			password:action.payload.password,
			passwordIsValid:passwordIsValid(action.payload.password)
		}

	default:
	  return state

  }
}

export const changePasswordPromptShow = (dispatch) => {
	dispatch({ type: CHANGE_PASSWORD_PROMPT_SHOW })
	dispatch({ type: CHANGE_PASSWORD_PROMPT_IDLE })
}
export const changePasswordPromptShowClick = () => {
	return dispatch => {
		console.log("changePasswordPromptShowClick: changePasswordPromptShow(dispatch)");
		changePasswordPromptShow(dispatch)
	}
}
export const changePasswordPromptHide = (dispatch) => {
	dispatch({ type: CHANGE_PASSWORD_PROMPT_HIDE })
}
export const changePasswordPromptHideClick = () => {
	return dispatch => {
		
		// tracking
		window.dataLayer && window.dataLayer.push({'event': 'password', eventCategory:'login', eventAction:'hide'});
		window.ga && window.ga('send', 'event', 'password', 'login', 'hide');//, [eventLabel], [eventValue], [fieldsObject]);

		changePasswordPromptHide(dispatch)
	}
}

export const changePasswordHandler = (dispatch, state)=>{
	return new Promise((resolve, reject)=>{
		
		const email = state.login.email;
		const password = state.changePassword.password;
		//console.log('changePasswordHandler', {email})

		dispatch({ type:CHANGE_PASSWORD_PROMPT_BUSY });

		SpotChangePasswordRequest(dispatch, email, password)
		.then((changePasswordResponse)=>{
			console.log('SpotChangePasswordRequest', {email, password, changePasswordResponse})
			
			dispatch({ type:CHANGE_PASSWORD_PROMPT_HIDE });

			// tracking
			window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'password-create', eventAction:'success'});
			window.ga && window.ga('send', 'event', 'booking', 'password-create', 'success');//, [eventLabel], [eventValue], [fieldsObject]);

			console.error('changePasswordHandler resolve',{changePasswordResponse});
			resolve(changePasswordResponse);
		
		})
		.catch( err =>{
			console.log('SpotChangePasswordRequest', {email, password, err})
			
			dispatch({ type:CHANGE_PASSWORD_PROMPT_IDLE });

			// tracking
			window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'password-create', eventAction:'error'});
			window.ga && window.ga('send', 'event', 'booking', 'password-create', 'error');//, [eventLabel], [eventValue], [fieldsObject]);

			console.error('changePasswordHandler reject',{err});
			reject(err);
		})
		
	})
}

export const changePasswordClick = () => {
	return (dispatch, getState) => {
	
		const state = getState();
		//console.log('remember password', {state})
		
		changePasswordHandler(dispatch, state);

	}
}

export const setPassword = ({target:{value:password}}) => {
	return dispatch => {
	  setPasswordValue(dispatch, password)
	}
}
  export const setPasswordValue = (dispatch, password) => {
	dispatch({
	  type: CHANGE_PASSWORD_PROMPT_PASSWORD,
	  payload:{password}
	})
};