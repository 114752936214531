import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Typography from '@material-ui/core/Typography';
//import Hidden from '@material-ui/core/Hidden';

import LinearProgress from '@material-ui/core/LinearProgress';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import { CircleLoader } from 'react-spinners';

import {
  loginPromptHide,
  loginPromptHideClick,
  loginHandler,
  loginClick,
  resetPasswordClick,
  resetPasswordHandler,
  setPassword
} from './../modules/login'

import {
  setEmail,
} from './../modules/spot/index.js'
//import { Typography } from '@material-ui/core';


const styles = theme => ({
  root: { fontSize: '16px', flexGrow: 1, },
  lineProgress:{
      height: '10px',
      position: 'absolute',
      width: '100%',
      marginTop: '45px',
  },
  dialogPaper: {
     margin:'10px',
     "max-width": "340px",
  },
  overrides: {
  }
})

const LoginPrompt = ({PHRASES, 
  login,
  loginHandler,
  loginClick,
  loginPromptHideClick,
  resetPasswordClick,
  resetPasswordHandler,
  setEmail,
  setPassword,
  classes
}) => (
    <Dialog
      id={`LoginDialog`}  
      className={`LoginDialog ${classes.root}`}
      open={!!login.visible}
      onClose={loginPromptHideClick}
      maxWidth={'sm'}
      fullWidth={true}
      aria-labelledby="login-prompt-title"
      classes={{ paper:classes.dialogPaper }}
    >
      <DialogTitle className="form-dialog-title" id="login-prompt-title">
        {/* <Typography variant="h3"> */}
          {
            (PHRASES.LOGIN_PROMPT_TITLE || PHRASES.LOGIN || 'Login')
          }
        {/* </Typography> */}
      </DialogTitle>

      {
        login.busy
        ? <LinearProgress className={classes.lineProgress} />
        : null
      }

      <DialogContent>
        
        {!!PHRASES.LOGIN_PROMPT_INTRO && (
          <DialogContentText>
            {PHRASES.LOGIN_PROMPT_INTRO}
          </DialogContentText>
        )}
      
        {/* 
          !!login.busy && (
            <CircleLoader
              style={{
                "z-index": "999",
                "position": "absolute",
                "height": "0",
                "background": "#fff",
                "right": "40px",
                "top": "20px",
                "display": !!login.busy ? 'block' : 'none'
              }}
              //className={override}
              sizeUnit={"px"}
              size={150}
              color={'#000099'}
              loading={login.busy}
            />
          )
        */}
        
        <TextField
          autoFocus={!login.email}
          margin="dense"
          id="login-email"
          label={PHRASES.LOGIN_PROMPT_EMAIL_LABEL || PHRASES.EMAIL || 'Email'}
          type="text"
          placeholder={PHRASES.LOGIN_PROMPT_EMAIL_PLACEHOLDER}
          value={login.email || ''}
          onInput={setEmail}
          fullWidth
          readOnly
        />
        <TextField
          autoFocus={!!login.email}
          margin="dense"
          id="password"
          label={PHRASES.LOGIN_PROMPT_PASSWORD_LABEL || PHRASES.PASSWORD || 'Password'  }
          type="password"
          placeholder={PHRASES.LOGIN_PROMPT_PASSWORD_PLACEHOLDER}
          value={login.password || ''}
          onInput={setPassword}
          fullWidth
        />
        {/* 
        <Typography variant="body1" gutterBottom align="right">
          <a href="javascript:;" onClick={resetPasswordClick} disabled={!login.password}>
            {PHRASES.LOGIN_PROMPT_RESET_PASSWORD_TEXT || "Reset Password"}
          </a>
        </Typography>
        */}
      </DialogContent>
      <DialogActions className={`dialog-actions`}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Button className="dont-ui-button cancel-button" onClick={loginPromptHideClick} color="secondary">
              {PHRASES.LOGIN_PROMPT_BUTTON_CANCEL || PHRASES.CANCEL || 'Cancel'}
            </Button>
          </Grid>
          <Grid item xs={6} align="right">
            <Button className="dont-ui-button login-button" onClick={loginClick} color="primary" variant="contained" disabled={!login.password}>
              {PHRASES.LOGIN_PROMPT_BUTTON_LOGIN || PHRASES.LOGIN || 'Login'}
            </Button>
          </Grid>
          <Grid item xs={12} align="center">
            <Button className="dont-ui-button reset-button" onClick={resetPasswordClick} style={{float:"left"}}>
              {PHRASES.LOGIN_PROMPT_RESET_PASSWORD_TEXT || PHRASES.RESET_PASSWORD || 'Reset Password'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
);

const mapStateToProps = ({ PHRASES, SCHEDULE, login }) => ({
  PHRASES, SCHEDULE,
  login
})

export default withStyles(styles)(connect(
  mapStateToProps,
  {
    loginPromptHide,
    loginPromptHideClick,
    loginHandler,
    loginClick,
    resetPasswordClick,
    resetPasswordHandler,
    setEmail,
    setPassword
  }
)(LoginPrompt))