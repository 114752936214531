//import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
//import {DebounceInput} from 'react-debounce-input';
//import debounce from './../helpers/debounce';

import { withStyles } from '@material-ui/core/styles';

import { SHIPPING_SCOPE } from './../modules/shipping'
import { loginPromptShowClick } from '../modules/login';
import { updateStateWithUserInput } from './../modules/address'
import {
  setEmail,
  setEmailValue,
  sendMobileCode,
  loginWithMobileCode,
  loginWithPassword,
  showPasswordPrompt
} from './../modules/spot/index.js'

import AddressForm from './AddressForm'


const styles = theme => ({
  //root: { fontSize: '16px', },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
});


const emailExistsMessage = (PHRASES, showPasswordPrompt) => (
  <React.Fragment>
    <span className="email-exists-msg-part-1">
      {PHRASES.EMAIL_ALREADY_EXISTS_MESSAGE}
    </span>
    <span className="email-exists-msg-part-2">
      <a href="/spot" 
        className="email-exists-button-password-login" 
        onClick={(event)=>{
          event.preventDefault();
          showPasswordPrompt();
          return false;
        }}
      >{PHRASES.EMAIL_ALREADY_EXISTS_LOGIN}</a>
    </span>
    {/*
      <span className="email-exists-msg-part-3">
        {PHRASES.EMAIL_ALREADY_EXISTS_OR || ' or '}
      </span>
      <span className="email-exists-msg-part-4">
        <a className="email-exists-button-mobile-code" href="#" onClick={()=>{ sendMobileCode(shipping.phone)}}>{PHRASES.EMAIL_ALREADY_EXISTS_SEND_CODE}</a>
      </span>
    */}
  </React.Fragment>
)

const ShippingForm = ({
  PHRASES, 
  SPOT,
  login,
  booking,
  shipping, 
  sendMobileCode,
  loginWithMobileCode,
  loginWithPassword,
  loginPromptShowClick,
  showPasswordPrompt,
  updateStateWithUserInput,
  setEmail,
  setEmailValue
}) => {
  ////console.log('ShippingForm',{PHRASES, shipping});

  return (
    <React.Fragment>
      <section className="Shipping">
      <Grid container spacing={0} direction="row">
          <Grid item xs={8}>
            { PHRASES.SHIPPING_FORM_TITLE &&
                    <Typography variant="h6" gutterBottom className={`shipping-title ${(!!booking.errors['shipping'].errors && 'has-errors') || 'no-errors'}`}>
                      {PHRASES.SHIPPING_FORM_TITLE || PHRASES.ADDRESS || 'Address'}
                    </Typography>
            }
          </Grid>
          {!login.manualLogin && (
            <Hidden mdUp>
              <Grid item xs={4} align="right">
                <Button className="dont-ui-button" variant="contained" color="primary" onClick={loginPromptShowClick}>
                  {PHRASES.PROMPT_LOGIN_BUTTON || PHRASES.LOGIN || "Login"}
                </Button>
              </Grid>
            </Hidden>
          )}
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            { PHRASES.SHIPPING_FORM_INTRO &&
                  <Typography variant="body1" gutterBottom>
                      {PHRASES.SHIPPING_FORM_INTRO || ''}
                  </Typography>
            }
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={!!booking.errors['shipping']['firstName']}
              id="firstName"
              name="firstName"
              inputProps={{'data-scope':SHIPPING_SCOPE,'data-field':"firstName"}}
              onInput={updateStateWithUserInput}
              value={shipping.firstName || ''}
              label={PHRASES.FIRST_NAME}
              fullWidth
              autoComplete="first name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={!!booking.errors['shipping']['lastName']}
              id="lastName"
              name="lastName"
              inputProps={{'data-scope':SHIPPING_SCOPE,'data-field':"lastName"}}
              onInput={updateStateWithUserInput}
              value={shipping.lastName || ''}
              label={PHRASES.LAST_NAME}
              fullWidth
              autoComplete="last name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={!!booking.errors['shipping']['email']}
              id="email"
              name="email"
              inputProps={{'data-scope':SHIPPING_SCOPE,'data-field':"email"}}
              onInput={setEmail}
              /* 
              inputComponent={
                <DebounceInput
                  debounceTimeout={300}
                  onChange={setEmail} 
                />
              }
              */
              value={shipping.email || ''}
              label={PHRASES.EMAIL_ADDRESS}
              fullWidth
              autoComplete="email"
            />
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} hidden={!!SPOT.login || !shipping.emailExists} className={`email-exists-msg`}>
              { emailExistsMessage(PHRASES, showPasswordPrompt) }
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={!!booking.errors['shipping']['phone']}
              id="phone"
              name="phone"
              inputProps={{'data-scope':SHIPPING_SCOPE,'data-field':"phone"}}
              onInput={updateStateWithUserInput}
              value={shipping.phone || ''}
              label={PHRASES.PHONE_NUMBER}
              fullWidth
              autoComplete="tel"
            />
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12} hidden={!!SPOT.login || !shipping.emailExists} className={`email-exists-msg`}>
              { emailExistsMessage(PHRASES, showPasswordPrompt) }
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <AddressForm
              addressType={SHIPPING_SCOPE}
              addressData={shipping}
            />
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
}

const mapStateToProps = ({
  PHRASES, SPOT, booking, shipping, login
}) => ({
  PHRASES, SPOT, booking, shipping, login
})

export default withStyles(styles)(connect(
    mapStateToProps,
    {
      setEmail,
      setEmailValue,
      updateStateWithUserInput, 
      sendMobileCode,
      loginWithMobileCode,
      loginWithPassword,
      loginPromptShowClick,
      showPasswordPrompt,
      AwesomeDebouncePromise,
      addressType: SHIPPING_SCOPE
    }
)(ShippingForm));