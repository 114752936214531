import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';

import {
    bookingConfirm,
} from './../modules/booking'

import {
    setTermsAndConditionsAcceptance,
} from './../modules/confirm'

import { 
  //makeStyles, 
  withStyles
} from '@material-ui/core/styles';

const GreenGrid = withStyles({
  root: {
    background: '#f7fff7'
  }
})(Grid);

//const useStyles = makeStyles({
//  greenLabel: {
//    background: '#f7fff7', 
//  },
//});

function ConfirmForm({
  PHRASES,
  booking,
  bookingConfirm,
  confirm,
  setTermsAndConditionsAcceptance
}) {
  //const classes = useStyles();

  return (
    <React.Fragment>
      { PHRASES.CONFIRM_FORM_TITLE && 
            <Typography variant="h6" gutterBottom className={(!!booking.errors['confirm'].errors && 'has-errors') || 'no-errors'}>
              {PHRASES.CONFIRM_FORM_TITLE}
            </Typography>
      }
      { PHRASES.CONFIRM_FORM_INTRO &&
              <Typography variant="body1" gutterBottom>
              {PHRASES.CONFIRM_FORM_INTRO || ''}
          </Typography>
      }
      <Grid container spacing={1}>
        { PHRASES.CONFIRM_FORM_INTRO && 
              <Grid item xs={12} sm={6} md={12}>
                  {PHRASES.CONFIRM_FORM_INTRO}
              </Grid>
        }
      
        <GreenGrid item xs={12} sm={8}>
          <FormControlLabel
              className={(!!booking.errors['confirm'].errors && 'has-errors') || 'no-errors'}
              //error={!!booking.errors['confirm'].accepted_terms_and_conditions}
              control={
                  <Switch
                      checked={confirm.accepted_terms_and_conditions}
                      onChange={setTermsAndConditionsAcceptance}
                      value="y"
                      color="primary"
                  />
              }
              labelstyle={{ zIndex: 3 }}
              label={
                  (
                    (PHRASES, LINK)=>
                    (
                      !!LINK
                      ? (
                        <React.Fragment>
                          <React.Fragment>{PHRASES.TERMS_AND_CONDITIONS_LINK_TEXT_BEFORE || "Accept"}</React.Fragment>
                          <React.Fragment>{` `}</React.Fragment>
                          <a href={LINK} data-href={PHRASES.TERMS_AND_CONDITIONS_LINK} rel="noopener noreferrer" target="_blank">
                            <React.Fragment>{(PHRASES.TERMS_AND_CONDITIONS_LINK_TEXT || "Terms and Conditions")}</React.Fragment>
                          </a>
                          <React.Fragment>{PHRASES.TERMS_AND_CONDITIONS_LINK_TEXT_AFTER}</React.Fragment>
                        </React.Fragment>
                      )
                      :
                      (
                          PHRASES.ACCEPT_TERMS_AND_CONDITIONS || "Accept Terms and Conditions"
                      )
                    )
                  )(
                    PHRASES,
                    PHRASES.TERMS_AND_CONDITIONS_LINK || "https://docs.google.com/document/d/1bE6f8yiZtK-KCGrFVeGQdcNjeqd8NYtLiPUV8ZcQ8d0/edit?usp=sharing"
                  )
              }
          />
        </GreenGrid>

        <Grid item xs={12} sm={4} className={`book-now-wrapper`}>
            <Button className="dont-ui-button" variant="contained" color="primary" onClick={bookingConfirm} disabled={!!booking.busy}>
                {
                  (
                    (!!booking.busy ? PHRASES.BOOK_NOW_BUSY : null) || PHRASES.BOOK_NOW
                  )
                  || 'Book Now'
                }
            </Button>
        </Grid>
      
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = ({
  PHRASES, booking, confirm
}) => ({
  PHRASES, booking, confirm
})

export default /*withStyles(styles)(*/connect(
  mapStateToProps,
  {
    bookingConfirm,
    setTermsAndConditionsAcceptance
  }
)(ConfirmForm)/*)*/;
