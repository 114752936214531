export const BILLING_SCOPE = "billing";

export const SET_BILLING_ADDRESS = 'Billing/Set/Address'

export const BILLING_ADDRESS_LOOKUP_WORKING = 'Billing/Address/Lookup/Working'
export const BILLING_ADDRESS_LOOKUP_DONE = 'Billing/Address/Lookup/Done'
export const BILLING_ADDRESS_LOOKUP_SHOW = 'Billing/Address/Lookup/Show'
export const BILLING_ADDRESS_LOOKUP_HIDE = 'Billing/Address/Lookup/Hide'

export const ADDRESS_LOOKUP_BASE = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyA65AFd_M7rPsckzpWJyaYJXCA_MRJo134&input='
export const GOOGLE_MAPS_API_KEY = 'AIzaSyA65AFd_M7rPsckzpWJyaYJXCA_MRJo134';

export const BILLING_ADDRESS_USER_INPUT = 'Billing/Address/UserInput'

const initialState = { 
    name:'',company:'',address1:'',address2:'',address3:'',city:'London',postcode:'',country:'GB',
    addresses: [],
    addressLookupVisible: false
};



export default (state = initialState, action) => {
  switch (action.type) {

    case BILLING_ADDRESS_LOOKUP_WORKING:
      return {
        ...state,
        looking_up_addresses: true
      }

    case BILLING_ADDRESS_LOOKUP_DONE:
      return {
        ...state,
        looking_up_addresses: false,
        addresses: action.payload
      }

      case BILLING_ADDRESS_LOOKUP_SHOW:
      return {
        ...state,
        addressLookupVisible: true
      }

    case BILLING_ADDRESS_LOOKUP_HIDE:
      return {
        ...state,
        addressLookupVisible: false
      }

    case BILLING_ADDRESS_USER_INPUT:
      return {
        ...state,
        ...(({
            field,
            value
        })=>{
            let o = {};
            o[field] = value;
            return o;
        })({
            field: action.payload.field,
            value: action.payload.value
        })
      }

	case SET_BILLING_ADDRESS:
		  return {
				...state,
				...((address_index, addresses, state) => {
					const address = addresses[parseInt(address_index)];
					const output = address ? {
						address1: address.line_1,
						address2: address.line_2,
						address3: address.line_3,
						county: address.county,
						city: address.town_or_city
					} : {};
					////console.log('SET_BILLING_ADDRESS',{address_index,output,address,addresses,state});
          return output
				})(action.payload, state.addresses)
      }


    default:
      return state
  }
}


export const lookupAddresses = (dispatch, postcode) => {

    return; ////console.log('lookupAddresses is currently disabled!', {postcode})===1;
/*
    ////console.log('lookupAddresses!', {postcode});

    dispatch({type: BILLING_ADDRESS_LOOKUP_WORKING});

    const pc = postcode.toUpperCase().replace(/[^0-9A-Z]/gi,'');
    const qry = 'https://api.getaddress.io/find/'+pc+'?api-key=CXUJxaYQJ0-UbFaG8lV_Xw16200&expand=true';

    fetch(
      qry
    )
    .then( (r)=>r.json() )
    .then( (results) =>{
      ////console.log('lookupAddresses!', {results});
      
      dispatch({type: BILLING_ADDRESS_LOOKUP_DONE, payload: (results && results.addresses) || []});
      
      dispatch({type: BILLING_ADDRESS_LOOKUP_SHOW});

    })
*/
}

export const setBillingAddress = (event) => {
	const index = event.currentTarget.dataset.index;
	////console.log('setBillingAddress', {index});
  return dispatch => {

		dispatch({
      type: SET_BILLING_ADDRESS,
      payload: index
    })
      
		dispatch({type: BILLING_ADDRESS_LOOKUP_HIDE});
		
  }
}
