import React from 'react';
import { connect } from 'react-redux'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from 'react-autosize-textarea';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Switch from '@material-ui/core/Switch';
//import TextField from '@material-ui/core/TextField';
import { 
  setInstructionsItems,
  setInstructionsDriver
} from '../modules/instructions';



const styles = theme => ({
  //root: { fontSize: '16px', },
  textareas: {
    padding:'10px',
    lineHeight:'24px',
    background: '#f7fff7'
  },
});

function InstructionsForm({
  PHRASES,
  booking,
  instructions,
  setInstructionsItems,
  setInstructionsDriver,
  classes
}) {

  ////console.log('InstructionsForm classes', classes, {t:clsx(['a','b'])});
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom className={(!!booking.errors['instructions'].errors && 'has-errors') || 'no-errors'}>
        {PHRASES.INSTRUCTIONS_FORM_TITLE}
      </Typography>

      {
        true // layout 1
        ?
          (
            <Grid container>
              <Grid item xs={12} sm={4} md={12}>
                <Typography variant="body1" gutterBottom className={(!!booking.errors['instructions']['pickup_delivery_comments'] && 'has-errors') || 'no-errors'}>
                  {PHRASES.INSTRUCTIONS_ITEMS_DESCRIPTION}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={12}>
                <TextareaAutosize
                    className={
                      clsx([
                        classes.textareas,
                        (!!booking.errors['instructions'].customer_memo && 'has-errors') || 'no-errors'
                      ])
                    }
                    //className={(!!booking.errors['instructions'].customer_memo && 'has-errors') || 'no-errors'}
                    //error={!!booking.errors['instructions'].customer_memo}
                    rows={3}
                    id="instructionsItems"
                    name="instructionsItems"
                    placeholder={PHRASES.INSTRUCTIONS_ITEMS_PLACEHOLDER}
                    onInput={setInstructionsItems}
                    value={instructions.customer_memo}
                    style={{
                      width:'100%',
                      'maxWidth':'100%',
                      'boxSizing': 'border-box',
                    }}
                  />
              </Grid>
              <Grid item xs={12} sm={4} md={12}>
                <Typography variant="body1" gutterBottom className={(!!booking.errors['instructions']['pickup_delivery_comments'] && 'has-errors') || 'no-errors'}>
                  {PHRASES.INSTRUCTIONS_DRIVER_DESCRIPTION}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={12}>
                <TextareaAutosize
                    className={
                      clsx([
                        classes.textareas,
                        (!!booking.errors['instructions'].pickup_delivery_comments && 'has-errors') || 'no-errors'
                      ])
                    }
                    //error={!!booking.errors['instructions'].pickup_delivery_comments}
                    rows={3}
                    id="instructionsDriver"
                    name="instructionsDriver"
                    placeholder={PHRASES.INSTRUCTIONS_DRIVER_PLACEHOLDER}
                    onInput={setInstructionsDriver}
                    value={instructions.pickup_delivery_comments}
                    style={{
                      width:'100%',
                      'maxWidth':'100%',
                      'boxSizing': 'border-box',
                    }}
                  />
              </Grid>
            </Grid>
          ) 
        :
          (
            <React.Fragment>
              {/*
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={12}>
                  <Typography variant="body1" gutterBottom className={(!!booking.errors['instructions']['customer_memo'].errors && 'has-errors') || 'no-errors'}>
                    {PHRASES.INSTRUCTIONS_ITEMS_DESCRIPTION}
                  </Typography>
                  {PHRASES.INSTRUCTIONS_ITEMS_DESCRIPTION}
                  <TextareaAutosize
                      className={
                        clsx([
                          classes.textareas,
                          (!!booking.errors['instructions'].customer_memo && 'has-errors') || 'no-errors'
                        ])
                      }
                      //error={!!booking.errors['instructions'].customer_memo}
                      rows={3}
                      id="instructionsItems"
                      name="instructionsItems"
                      placeholder={PHRASES.INSTRUCTIONS_ITEMS_PLACEHOLDER}
                      onInput={setInstructionsItems}
                      value={instructions.customer_memo}
                      style={{
                        width:'100%'
                      }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <Typography variant="body1" gutterBottom className={(!!booking.errors['instructions']['pickup_delivery_comments'].errors && 'has-errors') || 'no-errors'}>
                    {PHRASES.INSTRUCTIONS_DRIVER_DESCRIPTION}
                  </Typography>
                  <TextareaAutosize
                      className={
                        clsx([
                          classes.textareas,
                          (!!booking.errors['instructions'].pickup_delivery_comments && 'has-errors') || 'no-errors'
                        ])
                      }
                      //error={!!booking.errors['instructions'].pickup_delivery_comments}
                      rows={3}
                      id="instructionsDriver"
                      name="instructionsDriver"
                      placeholder={PHRASES.INSTRUCTIONS_DRIVER_PLACEHOLDER}
                      onInput={setInstructionsDriver}
                      value={instructions.pickup_delivery_comments}
                      style={{
                        width:'100%'
                      }}
                    />
                </Grid>
              </Grid>
              */}
            </React.Fragment>
          )
      }


    </React.Fragment>
  );
}

const mapStateToProps = ({
  PHRASES, booking, instructions
}) => ({
  PHRASES, booking, instructions
})

export default withStyles(styles)(connect(
  mapStateToProps,
  {
    setInstructionsItems,
    setInstructionsDriver
  }
)(InstructionsForm));
