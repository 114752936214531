import { lookupAddresses } from './shipping';
import { loadSpot } from './spot.js';
import { SPOT_ACTIVE_SESSION, SPOT_ACTIVE_TOKEN } from './../config/spot.Session';
import { LOGIN_PROMPT_SHOW, LoadAllTheThings } from './login';

import { changeHash } from './../helpers/changeHash';

export const PROMPT_SHOW = 'Prompt/Show'
export const PROMPT_HIDE = 'Prompt/Hide'
export const PROMPT_CONTINUE = 'Prompt/Continue'
export const PROMPT_POSTCODE_CONFIRMED = 'Prompt/Postcode/Confirmed'


export const promptShow = () => {
	return (dispatch, getState) => {

		const state = getState();

		if(!state.SPOT.loaded){
			////console.log('SPOT LOAD FIRST TIME', state);
			loadSpot(dispatch);
		}

		// 2019 - just show prompt
		// dispatch({ type: PROMPT_SHOW })

		// 2020 - load customer data if we have a session ID
		// see login method here, https://apidocs.mydrycleaner.com/?version=latest#44c9a06b-04f8-4bf2-b6e1-457d5c4ddb7e
		const token = SPOT_ACTIVE_TOKEN(); // this is a logged in session
		const session = SPOT_ACTIVE_SESSION(); // this is just any session
		//console.log('promptShow', {session,token, SPOT:window.SPOT,CustomerConnect:window.CustomerConnect,state});
		if(!!session && !!token){
			LoadAllTheThings(dispatch, state, { autoLogin:true })
				.then(res=>{
					//console.log('promptShow LoadAllTheThings res', {res, session, state})
					dispatch({ type: PROMPT_SHOW })
				})
				.catch(err=>{
					console.error('promptShow LoadAllTheThings err', {err, session, state})
					dispatch({ type: PROMPT_SHOW })
				})
			;
		}
		else{
			dispatch({ type: PROMPT_SHOW })
		}


		/*
		//console.log('SCHEDULE_LOADING', {SCHEDULE_LOADING,SCHEDULE_LOADED})
		dispatch({type: SCHEDULE_LOADING});
		LOAD_SCHEDULE().then((SCHEDULE)=>{
			dispatch({
				type: SCHEDULE_LOADED,
				payload: SCHEDULE
			})
		});

		//console.log('SPOT_LOADING', {SPOT_LOADING,SPOT_LOADED})
		dispatch({type: SPOT_LOADING});
		LOAD_SPOT().then((CustomerConnect, SpotSettings, SPOT)=>{
			dispatch({
				type: SPOT_LOADED,
				payload: {CustomerConnect, SpotSettings, SPOT}
			})
		});
		*/

	}
}


export const promptHide = () => {

	changeHash('');
		
	return dispatch => {
		dispatch({
			type: PROMPT_HIDE
		})
	}
}


export const promptContinue = () => {
	return (dispatch, getState) => {
		
		dispatch({
			type: PROMPT_CONTINUE
		})

		////console.log('lookupAddresses();');

		const state = getState();

		lookupAddresses(dispatch, state.shipping.postcode)		
		
		//lookupSchedule(dispatch, state)
		
	}
}

export const promptContinueCozEnterKey = ({key}) => {
	const enterKey = (key==='Enter');
	return (dispatch, getState) => {
	  const state = getState();
	  const postcode_is_valid = state.shipping.known_area;
	  //console.log('promptContinueCozEnterKey', {key,enterKey,postcode_is_valid});
	  if(enterKey && postcode_is_valid){
				
		dispatch({
			type: PROMPT_CONTINUE
		})

	  }
	}
  }

export const promptStraightToLogin = () => {
	return (dispatch, getState) => {
		
		dispatch({ type: PROMPT_CONTINUE })

		dispatch({ type: LOGIN_PROMPT_SHOW })

		
	}
}
