const SPOT_SESSION_URI = (window.location.hostname.match(/localhost/)?'https://dev.123cleaners.com':'') + '/do/spot/session';

//export const CustomerConnect = window.CustomerConnect;

// NOTE, session ID and token are the same thing
// CustomerConnect stores the session id in sessionStorage when the user is logged in
// https://apidocs.mydrycleaner.com/?version=latest#44c9a06b-04f8-4bf2-b6e1-457d5c4ddb7e
export const SPOT_ACTIVE_TOKEN = window.SPOT_ACTIVE_TOKEN = ()=>{
    let token = '';
    
    // new style, raw token id stored as JSON parsed string in sessionStorage
    if(!token && window.sessionStorage){
        token = sessionStorage.getItem("ccApp.token");
        if(!!token){
            console.log('SPOT_ACTIVE_TOKEN in window.sessionStorage', {token});
        };
        if(!!token) token = JSON.parse(token);
    }
				
    // old style, raw token id stored as token in localStorage
    if(!token && window.localStorage){
        token = window.localStorage.getItem("token");
        if(!!token){
            console.log('SPOT_ACTIVE_TOKEN in window.localStorage', {token});
        };
    }

    return token || null;
}


export const SPOT_SESSION_DEBUG = window.SPOT_SESSION_DEBUG = ()=>{
    return {
        SPOT: window.SPOT.session,
        localStorgage: window.localStorage.getItem("token"),
        sessionStorage: window.sessionStorage.getItem("ccApp.token"),
        CustomerConnect: window.CustomerConnect.Config.SessionId,
        SPOT_ACTIVE_SESSION: SPOT_ACTIVE_SESSION(),
    }
};

// NOTE, session ID and token are the same thing
// Having a session ID does not mean the user is logged in
export const SPOT_ACTIVE_SESSION = window.SPOT_ACTIVE_SESSION = ()=>{
    let session = '';

    // new style, directly from customer connect if it's running
    if(!session && !!window.CustomerConnect){
        session = window.CustomerConnect.Config && window.CustomerConnect.Config.SessionId;
        if(!!session){
            console.log('SPOT_ACTIVE_SESSION in window.CustomerConnect', {session});
        };
    }
    
    // new style, raw session id stored as JSON parsed string in sessionStorage
    if(!session && window.sessionStorage){
        session = sessionStorage.getItem("ccApp.token");
        if(!!session){
            console.log('SPOT_ACTIVE_SESSION in window.sessionStorage', {session});
        };
        if(!!session) session = JSON.parse(session);
    }
				
    // old style, raw session id stored as token in localStorage
    if(!session && window.SPOT){
        session = window.SPOT.session || '';
        if(!!session){
            console.log('SPOT_ACTIVE_SESSION in window.SPOT', {session});
        };
    }
				
    // old style, raw session id stored as token in localStorage
    if(!session && window.localStorage){
        session = window.localStorage.getItem("token");
        if(!!session){
            console.log('SPOT_ACTIVE_SESSION in window.localStorage', {session});
        };
    }

    return session || null;
}


function generateUUID() {
    let d = new Date().getTime(); // Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && (performance.now()*1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16; // random number between 0 and 16
        if(d > 0){ // Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else { // Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        // eslint-disable-next-line
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}



export const SPOT_BREAK_SESSION = window.SPOT_BREAK_SESSION = ()=>{

    const badSessionID = generateUUID();

    // new style, directly from customer connect if it's running
    try{
        window.CustomerConnect.Config.SessionId = badSessionID;
	}catch(e){}
    
    // new style, raw session id stored as JSON parsed string in sessionStorage
    try{
        sessionStorage.setItem("ccApp.token", "");
	}catch(e){}
				
    // old style, raw session id stored as token in localStorage
    try{
        window.SPOT.session = badSessionID;
	}catch(e){}
				
    // old style, raw session id stored as token in localStorage
    try{
        window.localStorage.setItem("token", "")
    }catch(e){}
    
    // not loading
    window.SPOT_SESSION_LOADING = false;

}

export const SPOT_NEW_SESSION = window.SPOT_NEW_SESSION = async () => {
    SPOT_KILL_SESSION();
    const SessionData = await SPOT_SESSION();
    const { session } = SessionData;
    return session;
}



// NOTE, session ID and token are the same thing
// Having a session ID does not mean the user is logged in
export const SPOT_KILL_SESSION = window.SPOT_KILL_SESSION = ()=>{

    // new style, directly from customer connect if it's running
    try{
        window.CustomerConnect.Config.SessionId = "";
	}catch(e){}
    
    // new style, raw session id stored as JSON parsed string in sessionStorage
    try{
        sessionStorage.setItem("ccApp.token", "");
	}catch(e){}
				
    // old style, raw session id stored as token in localStorage
    try{
        window.SPOT.session = "";
	}catch(e){}
				
    // old style, raw session id stored as token in localStorage
    try{
        window.localStorage.setItem("token", "")
    }catch(e){}
    
    // not loading
    window.SPOT_SESSION_LOADING = false;

}



export const SPOT_SESSION = function(){

    console.log('Spot.Session start')

    return new Promise((resolve, reject) => {

        const LoadSessionIntoBookingApp = (SpotSession) => {
            console.log('Spot.Session SpotSession', {SpotSession})
            const { session } = SpotSession || {};
            
            console.log('Spot.Session LoadSessionIntoBookingApp', {session,SpotSession,resolve});
            
            // SET SPOT OBJECT
            // put session id in local spot object
            window.SPOT = Object.assign(window.SPOT || {}, { session });
            //window.SpotSession = SpotSession;

            // critical check presense of window.CustomerConnect
            if(!window.CustomerConnect){
                console.error("CustomerConnect is not loaded, quit SPOT_SESSION now without a session (LoadSessionIntoBookingApp)", {session});
                reject("CustomerConnect is not loaded, quit SPOT_SESSION now without a session");
                try{ SPOT_KILL_SESSION() }catch(e){};
            }
            
            // put session ID in CustomerConnect
            if(window.CustomerConnect){
                window.CustomerConnect.Config.SessionId = session; //SpotSession.session;
            }

            // quit here
            console.log('Spot.Session resolve', {session,SpotSession});
            resolve(SpotSession);

            //// start CustomerConnect
            //window.CustomerConnect && (function(CustomerConnect){
            //    CustomerConnect.SPOT = SPOT;
            //    CustomerConnect.Config.SessionId = SPOT.session; //.ReturnObject.SessionID;
            //    
            //    resolve(SPOT);
            //    
            //})(window.CustomerConnect);

        };


        const session = SPOT_ACTIVE_SESSION();
        console.log('Spot.Session session = SPOT_ACTIVE_SESSION()', {session});

        if(!!session){
            console.log('Spot.Session resolve with existsing session',{session});
            LoadSessionIntoBookingApp({preloaded:true,session});

        }
        else{
            console.log('Spot.Session create new session',{session,SPOT_SESSION_URI});

            // only allow this to run once
            if(!!window.SPOT_SESSION_LOADING){
                try{ SPOT_KILL_SESSION() }catch(e){};
                reject("SPOT_SESSION() Don't load 2 sessions");
                return console.warn("SPOT_SESSION() Don't load 2 sessions");
            }
            window.SPOT_SESSION_LOADING = true;

            // if not resolved by now, load everything fresh
            fetch(SPOT_SESSION_URI/*, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                //mode: 'no-cors', 
                cache: "no-cache"
            }*/)
            //.then( r => { console.log('SPOT',{r})})
            .then( r => typeof(r)=="string" ? JSON.parse(r) : r.json() )
            .then( (SpotSession) => {
                window.SPOT_SESSION_LOADING = false;
                console.log('Spot.Session new session Success', {SpotSession});
                
                LoadSessionIntoBookingApp(SpotSession);
    
            })
            .catch((err) =>{
                window.SPOT_SESSION_LOADING = false;
                console.log('Spot.Session Error', {err});
                reject(err || "Unknown error in spot spot.Session.js")
            })
            .then(()=>{

                window.SPOT_SESSION_LOADING = false;
            })
        
        }

    
    })

}
