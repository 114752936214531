const rxGBPostcode = /^\s*((GIR 0AA)|(((A[BL]|B[ABDHLNRSTX]?|C[ABFHMORTVW]|D[ADEGHLNTY]|E[HNX]?|F[KY]|G[LUY]?|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]?|M[EKL]?|N[EGNPRW]?|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKLMNOPRSTY]?|T[ADFNQRSW]|UB|W[ADFNRSV]|YO|ZE)[1-9]?[0-9]|((E|N|NW|SE|SW|W)1|EC[1-4]|WC[12])[A-HJKMNPR-Y]|(SW|W)([2-9]|[1-9][0-9])|EC[1-9][0-9])[\s-]*[0-9][ABD-HJLNP-UW-Z]{2}))\s*$/gi;

export const isPostcodeValid = postcode => postcode && postcode.toUpperCase().match(rxGBPostcode);

// this function pessimistically ignores the 9AA part of postcodes, don't care about the first part
export const postcodeArea1 = input => ((input || '').replace(/\s+/gi,'').replace(/\d\w\w$/gi,'').replace(/[a-z]$/gi,'') || '').toUpperCase();
// this function optimistically finds the first letters and numbers of a postcode, doesn't care about the second part
export const postcodeArea2 = input => ((input || '').toUpperCase().match(/^[A-Z]{1,2}[0-9]{1,2}/gi) || [''])[0];
export const postcodeArea = input => postcodeArea2(postcodeArea1(input));

export const formatPostcode = postcode => (postcode || '').replace(/[^\s0-9a-zA-Z]/gi,'').toUpperCase();