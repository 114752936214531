import { combineReducers } from 'redux'
import PHRASES from './_PHRASES'
import SCHEDULE from './_SCHEDULE'
import SPOT from './spot.js'

import visible_container from './wrapper'
import booking_reducer from './booking'
import shipping_reducer from './shipping'
import billing_reducer from './billing'
import payment_reducer from './payment'
import schedule_reducer from './schedule'
import instructions_reducer from './instructions'
import confirm_reducer from './confirm'
import login_reducer from './login'
import changePassword_reducer from './changePassword'


export default combineReducers({
  visible_container: visible_container,
  shipping: shipping_reducer,
  billing: billing_reducer,
  payment: payment_reducer,
  schedule: schedule_reducer,
  instructions: instructions_reducer,
  confirm: confirm_reducer,

  booking: booking_reducer,
  login: login_reducer,
  changePassword: changePassword_reducer,
  
  SPOT,
  PHRASES,
  SCHEDULE
})