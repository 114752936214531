import * as noty from './../../helpers/noty';
import * as crypto from './../../helpers/crypto';
import { PHRASE, PHRASES } from './../../helpers/PHRASES';
import { SHIPPING_SCOPE } from '../shipping';
import { isPostcodeValid } from '../../helpers/postcode';


const CustomerConnect = window.CustomerConnect;


export const SpotState = (state, silent = false)=> {

    // clone shipping over to billing
    state.billing = state.payment.different_billing_address ? state.billing : state.shipping;

    ['shipping','billing'].forEach( x=> {
        state[x].addressee = state[x].company 
            ? state[x].company 
            : state[x].firstName +' '+state[x].lastName;
    });
    
    // error list
    const Errs = {
        'missing_phone': PHRASE('ERROR_MISSING_PHONE') || "Please enter your {SCOPE} phone number",
        'missing_address1': PHRASE('ERROR_MISSING_ADDRESS1') ||  "Please enter your {SCOPE} address",
        'missing_email': PHRASE('ERROR_MISSING_EMAIL') ||  "Please enter your {SCOPE} email address",
        'missing_firstName': PHRASE('ERROR_MISSING_FIRSTNAME') ||  "Please enter your {SCOPE} name",
        'missing_lastName': PHRASE('ERROR_MISSING_LASTNAME') ||  "Please enter your {SCOPE} surname",
        'missing_city': PHRASE('ERROR_MISSING_CITY') ||  "Please enter your {SCOPE} city",
        'missing_postcode': PHRASE('ERROR_MISSING_POSTCODE') ||  "Please enter your {SCOPE} postcode",
        'invalid_postcode': PHRASE('ERROR_INVALID_POSTCODE') ||  "Please enter your full {SCOPE} postcode, eg.: W9 2EW",
        'missing_card_number': PHRASE('ERROR_MISSING_CARD_NUMBER') || "Please enter your payment card number",
        'missing_card_expiry': PHRASE('ERROR_MISSING_CARD_EXPIRY') || "Please enter your payment card expiry date",
        'missing_card_cvc': PHRASE('ERROR_MISSING_CARD_CVC') || "Please enter your payment card cvc number",
        'invalid_card': PHRASE('ERROR_INVALID_CARD') ||  "Invalid payment card",
        'missing_collect_date': PHRASE('ERROR_MISSING_COLLECT_DATE') || "Please choose a collection date",
        'missing_deliver_date': PHRASE('ERROR_MISSING_DELIVER_DATE') || "Please choose a delivery date",
        'customer_memo_too_long': PHRASE('ERROR_CUSTOMER_MEMO_TOO_LONG') ||  "Instructions are too long, 500 chars max",
        'pickup_delivery_comments_too_long': PHRASE('ERROR_PICKUP_DELIVERY_COMMENTS_TOO_LONG') ||  "Driver instructions are too long, 500 chars max",
        'missing_terms_acceptance': PHRASE('ERROR_MISSING_TERMS_ACCEPTANCE') || "You must agree to our terms and conditions",
    }
    let errors = [];
    let error_states = {};
    let profile_errors = false;
    let payment_errors = false;
    let schedule_errors = false;
    let instructions_errors = false;
    let confirm_errors = false;
    ['shipping','billing'].forEach( s=> {
        error_states[s] = error_states[s] || {};
        ['address1','phone','email','firstName','lastName','city','postcode'].forEach( x => {
            if(!state[s][x] && (s===SHIPPING_SCOPE || ['address1','city','postcode'].indexOf(x)>-1) ){
                if(!profile_errors) errors[errors.length] = Errs['missing_'+x].replace(/{SCOPE}/gi,s);
                error_states[s].errors = (error_states[s].errors || 0) + 1;
                error_states[s][x] = true;
                profile_errors = true;
            };
        });
        if(!profile_errors && !error_states[s]['postcode']) ['postcode'].forEach( x => {
            // postcode isn't missing, but is it valid?
            if(!isPostcodeValid(state[s][x])){
                if(!profile_errors) errors[errors.length] = Errs['invalid_postcode'].replace(/{SCOPE}/gi,s);;
                error_states[s].errors = (error_states[s].errors || 0) + 1;
                error_states[s][x] = true;
                profile_errors = true;
            }
        })
    });
    ['payment'].forEach( s=> {
        error_states[s] = error_states[s] || {};
        ['card_number','card_expiry','card_cvc'].forEach( x => {
            if(!state.payment[x] && (PHRASES.PAYMENT_REQUIRED || 'no').toLowerCase()==='yes'){
                if(!payment_errors) errors[errors.length] = Errs['missing_'+x];
                error_states[s].errors = (error_states[s].errors || 0) + 1;
                error_states[s][x] = true;
                payment_errors = true;
            };
        });
        ['card_number'].forEach( x => {
            if(!!state.payment.card_number && !CustomerConnect.Util.Validate.CCNumber(state.payment.card_number)){
                if(!payment_errors) errors[errors.length] = Errs['invalid_card'];
                error_states[s].errors = (error_states[s].errors || 0) + 1;
                error_states[s][x] = true;
                payment_errors = true;
            };
        });
    });
    ['schedule'].forEach( s=> {
        error_states[s] = error_states[s] || {};
        ['collect','deliver'].forEach( x => {
            if(!state.schedule[x].DATE){
                if(!schedule_errors) errors[errors.length] = Errs['missing_'+x+'_date'];
                error_states[s].errors = (error_states[s].errors || 0) + 1;
                error_states[s][x] = true;
                schedule_errors = true;
            };
        });
    });
    ['instructions'].forEach( s=> {
        error_states[s] = error_states[s] || {};
        ['customer_memo','pickup_delivery_comments'].forEach( x => {
            if(!!state[s][x] && state[s][x].length && state[s][x].length>255){
                if(!instructions_errors) errors[errors.length] = Errs[''+x+'_too_long'];
                error_states[s].errors = (error_states[s].errors || 0) + 1;
                error_states[s][x] = true;
                instructions_errors = true;
            }
        });
    });
    ['confirm'].forEach( s=> {
        error_states[s] = error_states[s] || {};
        ['accepted_terms_and_conditions'].forEach( x => {
            if(!state.confirm.accepted_terms_and_conditions){
                error_states[s].errors = (error_states[s].errors || 0) + 1;
                errors[errors.length] = Errs['missing_terms_acceptance'];
                confirm_errors = true;
            }
        });
    });

    // do we have any errors?
    state.errors = !!errors.length;
    state.error_states = error_states;
    state.profile_errors = profile_errors;
    state.payment_errors = payment_errors;
    state.schedule_errors = schedule_errors;
    state.instructions_errors = instructions_errors;
    state.confirm_errors = confirm_errors;

    // quit now if we have errors
    if(errors.length && !silent){
        errors.forEach( e => noty.error(e) )
        return state;
    };
    
    // always set login email
    state.login.email = state.shipping.email;

    // set customer id?
    state.LoginFirst = false;
    let SaveCustomer_CustomerID = {};
    let SaveCustomer_NewPassword = {};

    //console.log('SpotState state.login=', state.login);
    //console.log('SpotState CustomerID? (frm CustomerID)', state.login.CustomerID);
    //console.log('SpotState CustomerID? (aka clientAccountID)', state.login.clientAccountID);
    // set customer id?
    if(!state.login.CustomerID){
        state.login.CustomerID = state.login.clientAccountID;
    }
    if(!!state.login.CustomerID){
        //console.log('SpotState CustomerID? yes', state.login);
        
        // don't always login first
        //state.LoginFirst = true;
        // only if not logged in already

        SaveCustomer_CustomerID["CustomerID"] = state.login.CustomerID;
        SaveCustomer_CustomerID["clientAccountID"] = state.login.CustomerID;
    }
    else{
        //console.log('SpotState CustomerID? no', state.login);
    };

    // set customer password?
    if(!(state.login.CustomerID)){
        if(!(state.login.password)){
            state.NewPassword = true;
            state.login.password = crypto.randomStringOf(10);
            //dispatch({type:LOGIN_SET_PASSWORD,payload:{password:state.login.password}})
        }
        SaveCustomer_NewPassword["password"] = state.login.password || '';
    };


    // return custom id and password
    state.SaveCustomer = {
        LoginFirst: state.LoginFirst,
        ...SaveCustomer_CustomerID,
        ...SaveCustomer_NewPassword
    }

    // return customer record
    state.Customer = {
        clientAccountID: SaveCustomer_CustomerID.CustomerID || '',
        ...SaveCustomer_CustomerID,
        ...SaveCustomer_NewPassword,
        "firstname": state.billing.firstName,
        "lastname": state.billing.lastName,
        "emailAddress": state.shipping.email,
        "serviceType": "DELIVERY",
        "Phones": (phones =>
                    phones.map( phone=>{
                        return {
                            "Extension": "", // Extension	string	The extension number.
                            "Number": phone, // Number	string	The phone number.
                            "PhoneMask": phone.match(/^(0|\+?44)7/gi) ? '##### ### ###' : '### #### ####', // PhoneMask	string	The format mask for the phone number (e.g. (###) ###-####).
                            "PhoneType": "Principal", // PhoneType	string	The type of phone it is (Principal, Home, Mobile, Cell, Business).
                        };
                    })
        )([state.shipping.phone]),
        "BillingAddress":{
            "Addressee":      state.billing.addressee || '',             // Addressee	string	50	The addressee.
            "Address1":       state.billing.address1 || '',              // Address1	string	50	Address 1 value.
            "Address2":       state.billing.address2 || '',              // Address2	string	50	Address 2 value.
            "City":           state.billing.city || '',                  // City	string	50	City value.
            "State":          state.billing.county || '',                // State	string	50	State value. US/CA - The 2 character abbreviation. UK - the full county name.
            "Zip":            state.billing.postcode || '',              // Zip	string	10	The zip or postal code value.
            "PostalCodeMask": "???? ?#??",                          // PostalCodeMask	string	20	The zip/postal code mask. ? for characters and # for digits. Example: ##### or ?#? #?#.
        },
        "DeliveryAddress":{
            "Addressee":      state.shipping.addressee || '',             // Addressee	string	50	The addressee.
            "Address1":       state.shipping.address1 || '',              // Address1	string	50	Address 1 value.
            "Address2":       state.shipping.address2 || '',              // Address2	string	50	Address 2 value.
            "City":           state.shipping.city || '',                  // City	string	50	City value.
            "State":          state.shipping.county || '',                // State	string	50	State value. US/CA - The 2 character abbreviation. UK - the full county name.
            "Zip":            state.shipping.postcode || '',              // Zip	string	10	The zip or postal code value.
            "PostalCodeMask": "???? ?#??",                          // PostalCodeMask	string	20	The zip/postal code mask. ? for characters and # for digits. Example: ##### or ?#? #?#.
        },
        // primary address same as shipping
        "PrimaryAddress": {
            "Addressee":      state.shipping.addressee || '',             // Addressee	string	50	The addressee.
            "Address1":       state.shipping.address1 || '',              // Address1	string	50	Address 1 value.
            "Address2":       state.shipping.address2 || '',              // Address2	string	50	Address 2 value.
            "City":           state.shipping.city || '',                  // City	string	50	City value.
            "State":          state.shipping.county || '',                // State	string	50	State value. US/CA - The 2 character abbreviation. UK - the full county name.
            "Zip":            state.shipping.postcode || '',              // Zip	string	10	The zip or postal code value.
            "PostalCodeMask": "???? ?#??",                          // PostalCodeMask	string	20	The zip/postal code mask. ? for characters and # for digits. Example: ##### or ?#? #?#.
        },        
        /*
        "CreditCardsToSave":[
            {
                "Number"       : state.payment.card_number || '',    // Number       string    50	This is the full credit card number.
                "Expiration"   : state.payment.card_expiry || '',    // Expiration   string    5	The date the card will expire (MM/YY).
                "Issue"        : state.payment.card_issuen || '',    // Issue        string    50	The issue number of the card if exists.
                "StartDate"    : state.payment.card_startd || '',    // StartDate    string    5	The start date of the card if it exists.
                "SetPrimary"   : true,                       // SetPrimary   boolean   Set this to true to maket he card the first in the list.
            }
        ],*/
    };

    // save a credit card?
    if(!!state.payment.card_number){
        state.Customer = {
            ...state.Customer,
            ...{
                
                // If not provided, default is 1. If 1 then CreditCardsToSave only allows for a single credit card. It will replace the existing card on file. If 2 then CreditCardsToSave will add to the existing list. Use CreditCards to delete existing cards.
                CreditCardSaveMode: 2, 

                // Array of CreditCards objects. These are the new or updated credit cards to save to the customer's account. When updating expiration, a full credit card number must be included. Refer to the CreditCardsToSave Structure below. NOTE: This array is for adding new credit cards. To set an existing card as primary or delete, use the appropriate property in the CreditCards array.
                CreditCardsToSave:[
                    {
                        "Number"       : state.payment.card_number || '',    // Number       string    50	This is the full credit card number.
                        "Expiration"   : state.payment.card_expiry || '',    // Expiration   string    5	The date the card will expire (MM/YY).
                        "Issue"        : state.payment.card_issuen || '',    // Issue        string    50	The issue number of the card if exists.
                        "StartDate"    : state.payment.card_startd || '',    // StartDate    string    5	The start date of the card if it exists.
                        "SetPrimary"   : true, // always        ignore this: !(state.cards && state.cards.length>0),                       // SetPrimary   boolean   Set this to true to maket he card the first in the list.
                    }
                ]

            }
        }
    }

    // return login object
    state.LoginRequest = {
        "EmailAddress": state.shipping.email,
        "Password": state.login.password
    };

    // return state
    return state;
}
