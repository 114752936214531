export const MOMENT_TODAY = ''; // 2018-12-17
export const MOMENT_DAY = 'day';
export const MOMENT_DAYS = 'days';
export const MOMENT_WEEKDAY = 'ddd';
export const MOMENT_FORMAT = 'YYYY-MM-DD';
export const MOMENT_SPOT_DATE = 'MM/DD/YYYY';
export const MOMENT_PARSE_TIME = 'T00:00:00+0000';
export const MOMENT_PARSE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const MIN_TURN_AROUND = 2;

export const POSTCODE_FIELD = 'postcode';
export const EMAIL_FIELD = 'email';

export const SCHEDULE_DATE_FORMAT = "ddd MMM Do";
export const SCHEDULE_DATE_INPUT_FORMAT = "ddd MMM Do";

export const K = {
    KEY: "KEY",
    RULE: "RULE",
    POSTCODE: "POSTCODE",
    DATE: "DATE",
    TIME: "TIME",
    HOUR: "HOUR",
    WEEKDAY: "WEEKDAY",
    WEEKDAYS: "WEEKDAYS",
    MINIMUM: "MINIMUM",
    COMMENTS: "COMMENTS"
}
