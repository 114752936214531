import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

//URGH, doesn't work with create-react-app
// https://github.com/facebook/create-react-app/issues/1354
// https://github.com/tleunen/babel-plugin-module-resolver/issues/224
//import 'module-alias/register';

import './styles';
import './helpers/typography';

import LOAD_PHRASES from './config/phrases';
//import LOAD_SCHEDULE from './config/schedule';
//import LOAD_SPOT from './config/spot';

import App from './App.js';

LOAD_PHRASES().then((PHRASES)=>{

    //LOAD_SCHEDULE().then((SCHEDULE)=>{
        
        //LOAD_SPOT().then((CustomerConnect, SpotSettings, SPOT)=>{

            // render app now we have all the data we need
            ReactDOM.render(
                <App 
                    PHRASES={PHRASES} 
                    //SCHEDULE={SCHEDULE} 
                    //SPOT={SPOT} 
                    //SpotSettings={SpotSettings} 
                    //CustomerConnect={CustomerConnect}
                />, 
                document.getElementById('book123-app')
            );

            // If you want your app to work offline and load faster, you can change
            // unregister() to register() below. Note this comes with some pitfalls.
            // Learn more about service workers: http://bit.ly/CRA-PWA
            serviceWorker.unregister();

        //});

    //});

})
