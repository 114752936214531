import React from 'react';
import { connect } from 'react-redux'
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
//import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//import blue from '@material-ui/core/colors/blue';
//
//import Fab from '@material-ui/core/Fab';
//import SendIcon from '@material-ui/icons/Send';

import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
//https://material-ui.com/customization/breakpoints/

import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
//import green from '@material-ui/core/colors/green';

//import Slide from '@material-ui/core/Slide';

import { changeHash } from './../helpers/changeHash';

import {
  bookingCancel,
  bookingCancelConfirmation,
  bookingConfirm,
} from './../modules/booking'

import {
  logoutButtonClick,
  loginPromptShowClick,
} from './../modules/login'

import {
  changePasswordPromptShow,
  changePasswordPromptShowClick,
} from './../modules/changePassword'



import * as BookingFormComponents from './../components'

const styles = theme => ({
  root: {
    fontSize: '16px',
    flexGrow: 1,
  },
  appBar: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#fff', //green[500],
    position: 'absolute',
    top: '50%',
    right: '20px',
    marginTop: -12,
    marginLeft: -12,
   },
   lineProgress:{
      height: '10px',
      position: 'absolute',
      width: '100%',
      marginTop: '70px',
   },
   formBody: {
    padding: '20px 15px',
    /*
    padding: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      padding:theme.spacing.unit/2 //backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      padding:theme.spacing.unit //backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.spacing(2)
    },
    */
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});


function Transition(props) {
  //return <Slide direction="up" {...props} />;
  return <Grow {...props} />;
}

const BookingDialog = ({
  PHRASES,
  SPOT,  
  login,
  booking,
  bookingCancel, 
  bookingCancelConfirmation,
  bookingConfirm,
  width,
  breakpoints,
  fullScreen,
  logoutButtonClick,
  loginPromptShowClick,
  changePasswordPromptShow,
  changePasswordPromptShowClick,
  classes //:{ flex, appBar, paper }
}) => {

  React.useEffect(() => {
    changeHash('#booking-dialog');
    //return () => window.location.hash = '';
  }, []);


  //console.log('breakpoints', {breakpoints});

  return (
    
    <div className={classes.root}>
      <Dialog
        id="BookingForm"
        className="dont-ui-button"
        open
        breakpoints={breakpoints}
        fullScreen={fullScreen}
        maxWidth="md"
        onClose={bookingCancel}
        TransitionComponent={Transition}
        scroll="body"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={bookingCancel} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {PHRASES.BOOKING_DIALOG_TITLE || 'New Booking'}
            </Typography>
            
            {(()=> {
                if(isWidthUp('sm', width)) {
                  if(!window.location.pathname.match(/^\/spot/) && !!SPOT.login){
                    return (
                      <Button className="dont-ui-button" color="inherit" onClick={changePasswordPromptShowClick}>{PHRASES.CHANGE_PASSWORD_BUTTON_TEXT || `Pwd`}</Button>
                    )
                  }
                }
            })()}

            { !window.location.pathname.match(/^\/spot/) && !!SPOT.login && <Button className="dont-ui-button" color="inherit" onClick={logoutButtonClick}>{PHRASES.LOGOUT_BUTTON_TEXT || PHRASES.LOGOUT || `Logout`}</Button> }
            { !window.location.pathname.match(/^\/spot/) && !SPOT.login && <Button className="dont-ui-button" color="inherit" onClick={loginPromptShowClick}>{PHRASES.LOGIN_BUTTON_TEXT || PHRASES.LOGIN || `Login`}</Button> }
            {
              /*
                <Button className="dont-ui-button" color="inherit" onClick={bookingConfirm} disabled={!!booking.busy}>
                  { (!!booking.busy ? PHRASES.BOOK_NOW_BUSY : null) || PHRASES.BOOK_NOW}
                </Button>
              */
            }
            <Hidden mdDown={true}>
              {!!booking.busy && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Hidden>
          </Toolbar>
        </AppBar>

        {
          booking.busy
          ? <LinearProgress className={classes.lineProgress} />
          : null
        }

        <div id="BookingFormSections" className={classes.formBody}>
        
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper id="ScheduleForm" className={`booking-form-section ${classes.paper}`}>
                    <BookingFormComponents.ScheduleForm/>
                  </Paper>
                </Grid>
                <Divider/>
                <Grid item xs={12}>
                  <Paper id="InstructionsForm" className={`booking-form-section ${classes.paper}`}>
                    <BookingFormComponents.InstructionsForm/>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper id="ShippingForm" className={`booking-form-section ${classes.paper}`}>
                    <BookingFormComponents.ShippingForm/>
                  </Paper>
                </Grid>
                {/* 
                <Divider/>
                <Grid item xs={12}>
                  <Paper id="PaymentForm" className={`booking-form-section ${classes.paper}`}>
                    <BookingFormComponents.PaymentForm/>
                  </Paper>
                </Grid>
                 */}
                <Divider/>
                <Grid item xs={12}>
                  <Paper id="ConfirmForm" className={`booking-form-section ${classes.paper}`}>
                    <BookingFormComponents.ConfirmForm/>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/*
            <DialogActions className={`dialog-actions`}>
              <Button className="dont-ui-button" onClick={bookingCancel} style={{float:"left"}} className={`booking-cancel-button`}>
                {PHRASES.BOOKING_CANCEL || PHRASES.CANCEL || 'Cancel'}
              </Button>

              <Button className="dont-ui-button" variant="contained" color="primary" onClick={bookingConfirm} disabled={!!booking.busy}>
                  {
                    (
                      (!!booking.busy ? PHRASES.BOOK_NOW_BUSY : null) || PHRASES.BOOK_NOW
                    )
                    || 'Book Now'
                  }
              </Button>

            </DialogActions>
        */}

        {/*
              <Fab className={classes.fab} 
                color="primary" 
                variant="extended" 
                aria-label="Add"
                onClick={bookingConfirm} 
                disabled={!!booking.busy}
              >
                {
                    (
                      (!!booking.busy ? PHRASES.BOOK_NOW_BUSY : null) || PHRASES.BOOK_NOW
                    )
                    || 'Book Now'
                }
                <SendIcon/>
              </Fab>
        */}

      </Dialog>

      <BookingFormComponents.LoginPrompt/>

      <BookingFormComponents.AddressLookup/>

      <BookingFormComponents.ChangePasswordPrompt/>
    </div>
  )
}

const mapStateToProps = ({
  PHRASES, SCHEDULE, SPOT, shipping, billing, schedule, booking, login
}) => ({
  PHRASES, SCHEDULE, SPOT, shipping, billing, schedule, booking, login
})

/* withStyles(styles) returns connect function */
export default withMobileDialog({breakpoint: 'sm'})(
  withWidth()(
    withStyles(styles)(
      connect(
        mapStateToProps,
        {
          logoutButtonClick,
          loginPromptShowClick,
          bookingCancel,
          bookingCancelConfirmation,
          bookingConfirm,
          changePasswordPromptShow,
          changePasswordPromptShowClick,
        }
      )(
        BookingDialog
      )
    )
  )
)