import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import {
  updateStateWithUserInput
} from './../modules/address'




const styles = theme => ({
  //root: { fontSize: '16px', },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
});


const AddressForm = ({
  PHRASES, 
  booking, 
  updateStateWithUserInput,
  addressData,
  addressType
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={1} className={(!!booking.errors[addressType].errors && 'has-errors') || 'no-errors'}>
        <Grid item xs={12}>
          <TextField
            required
            error={!!booking.errors[addressType]['address1']}
            id={addressType + "-address1"}
            name={addressType + "-address1"}
            inputProps={{'data-scope':addressType,'data-field':"address1"}}
            onInput={updateStateWithUserInput}
            value={addressData.address1}
            label={PHRASES.ADDRESS_LINE_1}
            fullWidth
            autoComplete={addressType + " address-line1"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!!booking.errors[addressType]['address2']}
            id={addressType + "-address2"}
            name={addressType + "-address2"}
            inputProps={{'data-scope':addressType,'data-field':"address2"}}
            onInput={updateStateWithUserInput}
            value={addressData.address2}
            label={PHRASES.ADDRESS_LINE_2}
            fullWidth
            autoComplete={addressType + " address-line2"}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            error={!!booking.errors[addressType]['city']}
            id={addressType + "-city"}
            name={addressType + "-city"}
            inputProps={{'data-scope':addressType,'data-field':"city"}}
            onInput={updateStateWithUserInput}
            value={addressData.city}
            label={PHRASES.CITY}
            fullWidth
            autoComplete={addressType + " address-level2"}
          />
        </Grid>
        <Grid item xs={6} sm={6} style={{"display":addressType==='billing'?"inline-block":"none"}}>
          <TextField 
            error={!!booking.errors[addressType]['county']}
            id={addressType + "-county" }
            name={addressType + "-county" }
            inputProps={{'data-scope':addressType,'data-field':"county"}}
            onInput={updateStateWithUserInput}
            value={addressData.county}
            label={PHRASES.COUNTY} 
            fullWidth 
            autoComplete={addressType + " address-state"}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            error={!!booking.errors[addressType]['postcode']}
            id={addressType + "-postcode"}
            name={addressType + "-postcode"}
            inputProps={{'data-scope':addressType,'data-field':"postcode"}}
            onInput={updateStateWithUserInput}
            value={addressData.postcode}
            label={PHRASES.POSTCODE}
            fullWidth
            autoComplete={addressType + " postal-code"}
          />
        </Grid>
        <Grid item xs={6} sm={6} style={{"display":addressType==='billing'?"inline-block":"none"}}>
          <TextField
            required
            error={!!booking.errors[addressType]['country']}
            id={addressType + "-country"}
            name={addressType + "-country"}
            inputProps={{'data-scope':addressType,'data-field':"country"}}
            onInput={updateStateWithUserInput}
            value={addressData.country}
            label={PHRASES.COUNTRY}
            fullWidth
            autoComplete={addressType + " country"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = ({
  PHRASES, booking
}) => ({
  PHRASES, booking
})

export default withStyles(styles)(connect(
    mapStateToProps,
    {
      updateStateWithUserInput
    }
)(AddressForm));