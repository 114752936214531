import LOAD_SPOT from '../config/spot';
import { SPOT_SESSION } from '../config/spot.Session';

export const SPOT_LOADED = 'Spot/Loaded';
export const SPOT_LOGIN = "Spot/Login";
export const SPOT_LOGOUT = "Spot/Logout";

export * from './spot/index.js';

//export const CustomerConnect = window.CustomerConnect;

const initialState = { 
    spot_loaded: false,
    SpotSettings: {}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case SPOT_LOADED:
            //console.log('SPOT_LOADED',{payload:action.payload});
            return {
                ...state,
                ...action.payload
        }


        case SPOT_LOGOUT:
            //console.log('SPOT_LOGOUT',{payload:action.payload});
            return {
                ...state,
                ...{ login: null }
            }

        case SPOT_LOGIN:
            //console.log('SPOT_LOGIN',{payload:action.payload});
            window.TempCredentials = window.TempCredentials || {};
            window.TempCredentials[action.payload.Email] = action.payload;
            return {
                ...state,
                ...{ login: action.payload }
            }

            
        default:
            return state

    }
}

export const loadSpot = (dispatch) =>{
    return new Promise((resolve, reject) => {

        LOAD_SPOT().then((CustomerConnect, SpotSettings, SPOT)=>{

            dispatch({type:SPOT_LOADED,payload:{SPOT, spot_loaded:true, SpotSettings, CustomerConnect}});

            resolve({SPOT, SpotSettings, CustomerConnect});

        })
        .catch( (err)=> reject(err) );
    })
}

export const spotSession = (dispatch) =>{
    return new Promise((resolve, reject) => {

        SPOT_SESSION().then((CustomerConnect, SPOT)=>{

            dispatch({type:SPOT_SESSION,payload:{SPOT, spot_loaded:true, CustomerConnect}});

            resolve({SPOT, CustomerConnect});

        })
        .catch( (err)=> reject(err) );
    })
}
