import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import rootReducer from './modules'

export const history = createHistory()

// const initialState = {

//   // data bindings

//   // pickup:{date:'',time:''},
//   // dropoff:{date:'',time:''},
//   shipping,
//   // billing:{name:'',company:'',address1:'',address2:'',address3:'',city:'',postcode:'',country:''},
//   // payment:{method:'',card:'',expiry:'',cvc:''},

//   // ui state / control props
//   prompt_visible: false,
//  // booking_visible: false,
//  // confirmation_visible: false,
// container_visible: 'Prompt'
// }

const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

const debug_in_production = true;

if ((!!debug_in_production) || (process.env.NODE_ENV === 'development')) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)


const initializeStore = ({
  PHRASES, 
  SCHEDULE
}) =>{
  
  return createStore(
    connectRouter(history)(rootReducer),
    {
      PHRASES,
      SCHEDULE
    },
    composedEnhancers
  )
}

export default initializeStore