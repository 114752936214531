import LOAD_SCHEDULE from './../config/schedule';
import { K } from './../config/constants'
import { Schedule } from './../helpers/schedule'
import { postcodeArea } from './../helpers/postcode'
//import { SCHEDULE_SET_COLLECT, setEarliestCollectSlot } from './../modules/schedule'


export const SCHEDULE_SET = 'Schedule/Set'

const initialState = { 
    SCHEDULE:[],
	schedule:{},
	available:false,
	area: '',
	rule:{},
	slot:{}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case SCHEDULE_SET:
            //console.log('SCHEDULE_SET',{payload:action.payload});
            return {
            ...state,
            ...action.payload
        }

        default:
            return state

    }
}

export const loadSchedule = ({dispatch,state,postcode})=>{

		return new Promise((resolve, reject) => {
				
			LOAD_SCHEDULE().then((SCHEDULE)=>{
				const schedule = new Schedule(SCHEDULE);
				const area = postcodeArea(postcode);
				const slot = schedule.for(postcode).first();
				const rule = slot[K.RULE];

				const available = schedule && schedule.SLOTS && schedule.SLOTS.length>0;

				dispatch({
					type: SCHEDULE_SET,
					payload:{SCHEDULE, postcode, schedule, available, area, rule, slot}
				});

				//console.log('loadSchedule - resolve({SCHEDULE, schedule, area, rule, slot});');
				resolve({SCHEDULE:{DATA:SCHEDULE, postcode, schedule, area, rule, slot}});

				////console.log('setEarliestCollectSlot(dispatch, state);');
				//setEarliestCollectSlot(dispatch, state);
				//setEarliestCollectSlot(dispatch, state);

			})
			.catch( (err)=> reject(err) );
		
		})

}