//import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from "@material-ui/core";
//import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
//import format from "date-fns/format";
//import isValid from "date-fns/isValid";
//import isSameDay from "date-fns/isSameDay";

import {
  SCOPE_COLLECT,
  SCOPE_DELIVER,
  handleDayClick, 
  setDatePickerModeTo
} from './../modules/schedule'

import { Moment } from './../helpers/schedule'

import { promptShow } from './../modules/prompt'

//import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import {
  K,
  MOMENT_WEEKDAY,
  SCHEDULE_DATE_FORMAT,
  SCHEDULE_DATE_INPUT_FORMAT,
  MIN_TURN_AROUND,
  MOMENT_DAYS,
  MOMENT_TODAY,
  //MOMENT_FORMAT
} from './../config/constants';



const styles = theme => ({
  root: {
    //fontSize: '16px',
  },
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  dayDisabled: {
    color:'#fff'
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  dayIsActive: {
    color: "#676767",
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
});



const ScheduleForm = ({
    PHRASES, 
    SCHEDULE, 
    schedule, 
    shipping, 
    booking, 
    handleDayClick, 
    setDatePickerModeTo, 
    promptShow,
    classes
}) => {
  ////console.log('ScheduleForm classes',{classes});
  
  const today = Moment(MOMENT_TODAY || (new Date()));

  //const Collect = schedule.collect.DATE ? MomentToDate(schedule.collect.DATE) : null;
  //const Deliver = schedule.deliver.DATE ? MomentToDate(schedule.deliver.DATE) : null;
  //const modifiers = { start: Collect, end: Deliver };

  // use the rule to show minimum collection fee and notes
  const rule = schedule.collect.RULE || {};
  //console.log('CURRENT RULE',{rule});

  const localHandleDayInput = (scope) => {
    return (date)=> {
      //console.log('Schedule Date Input', {scope, date})
      handleDayClick(scope, date, schedule);
    }
  }
  //const shouldDisableDate = ({SCHEDULE, schedule, shipping, scope}) => {
  //  return date => {
  //    
  //  }
  //}

//  const localHandleDayClick = ({schedule, shipping, scope}) => {
//    if(!shipping.known_area) return ()=>{};
//    return (date, modifiers = {}) => 
//      !modifiers.disabled && handleDayClick(scope, date, schedule);
//  }
//
//  const localSetDatePickerModeTo = ({schedule, shipping, picker_mode}) => {
//    ////console.log('localSetDatePickerModeTo',{schedule, shipping, scope})
//    if(!shipping.known_area) return ()=>{};
//    return (event) => {
//      ////console.log('localSetDatePickerModeTo event',{event, schedule, shipping, scope})
//      setDatePickerModeTo({event, schedule, shipping, picker_mode});
//      
//      // still open dialog
//      return true;
//    }
//  }

  const localDayDisabled = ({SCHEDULE, schedule, shipping, scope}) => {
    return (day) => {
      if(!shipping.known_area) return true;
      const date = Moment(day);
      const collect_date = schedule.collect.DATE ? Moment(schedule.collect.DATE) : true;
      const deliver_date = schedule.collect.DATE ? collect_date.add(MIN_TURN_AROUND,MOMENT_DAYS) : true;
      const scheduleRule = SCHEDULE.rule || SCHEDULE[K.RULE] || null;
      const curSchedule = SCHEDULE.schedule;
      const weekDays = (curSchedule && curSchedule.WEEKDAYS) || (scheduleRule && scheduleRule[K.WEEKDAYS]);
      const a = (date && date.isSameOrAfter(today)) || false;
      ////console.log('localDayDisabled',{schedule,scope,today,date,collect_date,deliver_date,scheduleRule,weekDays,SCHEDULE,a});
      const b = weekDays && (weekDays.indexOf(date.format(MOMENT_WEEKDAY).toUpperCase())>-1);
      const c = (deliver_date && (scope===SCOPE_COLLECT || date.isSameOrAfter( deliver_date ))) || false;
      const disabled = !(a && b && c);
      ////console.log('localDayDisabled',{schedule,scope,today,date,collect_date,deliver_date,scheduleRule,weekDays,SCHEDULE,a,b,c,disabled});
      return disabled;
    }
  }
  const shouldDisableDate = localDayDisabled;


  const renderDay = ()=>{}/*({SCHEDULE, schedule, shipping, scope}) => {
    return (date, selectedDate, dayInCurrentMonth) => {
      //console.log('renderDay', {SCHEDULE, schedule, shipping, scope, date, selectedDate, dayInCurrentMonth});
      
      const dayIsActive = localDayDisabled({SCHEDULE, schedule, shipping, scope})(date);

      const wrapperClassName = clsx({
      });

      const dayClassName = clsx(classes.day, {
        [classes.dayIsActive]: dayIsActive,
      });

      return (
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span>{Moment(date).format("DD")} </span>
          </IconButton>
        </div>
      );
    };
  }*/;

  /*
  const SchedulePicker = ({PHRASES, SCHEDULE, schedule, shipping})=>{
    return (
      <React.Fragment>
        <Hidden mdUp>
          <Grid container className="postcode-notice-mobile">
            <Grid item xs={8} className="message left-column">
              <Typography variant="body1" align="left">
                { !!shipping.postcode && 
                  (
                    PHRASES.SCHEDULE_INTRO_MOBILE || `For postcode {AREA}`
                  )
                  .replace(/\{AREA\}/gi, shipping.area)
                  .replace(/\{POSTCODE\}/gi, shipping.postcode)
                }
              </Typography>
            </Grid>
            <Grid item xs={4} className="change-button right-column">
              <Button className="dont-ui-button" variant="text" color="primary" onClick={promptShow}>
                {PHRASES.SCHEDULE_INTRO_MOBILE_CHANGE_BUTTON || 'Change'}
              </Button>
            </Grid>
          </Grid>
        </Hidden>
        
        <Grid container className={`SchedulePicker`}>
          <Grid item xs={12} sm={7} className={`schedule-calendar left-column picking-${schedule.picker_mode} disabled-${(schedule.collectSet ? 'picking-deliver' : 'picking-collect')}`}>
              <DayPicker
                month={MomentToDate(Moment(Collect || today))}
                showOutsideDays
                className="Calendar"
                hidden={!shipping.known_area}
                modifiers={modifiers}
                selectedDays={[ Collect, { from:Collect, to:Deliver } ]}
                disabledDays={[ localDayDisabled({SCHEDULE, schedule, shipping, scope:SCOPE_COLLECT}) ]}
                onDayClick={ localHandleDayClick({SCHEDULE, schedule, shipping, scope:SCOPE_COLLECT}) }
                disabled={!shipping.known_area}
              />
          </Grid>
          <Grid item xs={12} sm={5} className={`schedule-slots right-column picking-${schedule.picker_mode} disabled-${(schedule.collectSet ? 'picking-deliver' : 'picking-collect')}`}>
              <Grid item sm={12} xs={6} 
                className={`schedule-slot ${SCOPE_COLLECT}`+(schedule.picker_mode===SCOPE_COLLECT ? " picking-this": "")} 
                onClick={localSetDatePickerModeTo({SCHEDULE, schedule, shipping, picker_mode:SCOPE_COLLECT})}
              >
                <div className="schedule-heading">{PHRASES.SCHEDULE_SLOT_COLLECT_HEADING}</div>
                <div className="schedule-display schedule-date" id="schedule-collect-date">{Moment(schedule.collect.DATE).format(PHRASES.SCHEDULE_SLOT_DATE_FORMAT || SCHEDULE_DATE_FORMAT)}</div>
                <div className="schedule-display schedule-time" id="schedule-collect-time">{schedule.collect.TIME}</div>
              </Grid>
              <Grid item sm={12} xs={6} 
                className={`schedule-slot ${SCOPE_DELIVER}`+(schedule.picker_mode===SCOPE_DELIVER ? " picking-this": "")} 
                onClick={localSetDatePickerModeTo({SCHEDULE, schedule, shipping, picker_mode:SCOPE_DELIVER})}
              >
                <div className="schedule-heading">{PHRASES.SCHEDULE_SLOT_DELIVER_HEADING}</div>
                <div className="schedule-display schedule-date" id="schedule-deliver-date">{Moment(schedule.deliver.DATE).format(PHRASES.SCHEDULE_SLOT_DATE_FORMAT || SCHEDULE_DATE_FORMAT)}</div>
                <div className="schedule-display schedule-time" id="schedule-deliver-time">{schedule.deliver.TIME}</div>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
    )
  }
  */


  const DatePickerTitle = ({ text })=> {
    return <div className="schedule-date-picker-title">{ text }</div>
  }


  const ScheduleInputs = ()=> {
    
    return (
      <React.Fragment>

        <Grid container spacing={1}>
          <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker 
                    disablePast={true}
                    autoOk={true}
                    shouldDisableDate={shouldDisableDate({SCHEDULE, schedule, shipping, scope:SCOPE_COLLECT})}
                    renderDay={renderDay({SCHEDULE, schedule, shipping, scope:SCOPE_COLLECT})}
                    margin="dense"
                    type="text"
                    id="collectDate"
                    name="collectDate"
                    error={!!booking.errors.schedule['collect']}
                    inputProps={{'data-scope':'schedule','data-field':SCOPE_COLLECT}}
                    onInput={localHandleDayInput('collect')}
                    onChange={localHandleDayInput('collect')}
                    onAccept={localHandleDayInput('collect')}
                    views={["date"]}
                    ToolbarComponent={()=><DatePickerTitle text={PHRASES.COLLECTION_DATE_PICKER_TITLE}/>}
                    variant={"dialog"}
                    value={schedule.collect.DATE}
                    format={SCHEDULE_DATE_INPUT_FORMAT}
                    label={PHRASES.COLLECTION_DATE}
                    fullWidth
                    autoComplete={SCOPE_COLLECT}
                    //onOpen={localSetDatePickerModeTo({SCHEDULE, schedule, shipping, picker_mode:SCOPE_COLLECT})}
                  />
              </MuiPickersUtilsProvider>
              {schedule.collect.TIME && <Typography variat="body1">{schedule.collect.TIME}</Typography>}
          </Grid>
          <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker 
                    disablePast={true}
                    autoOk={true}
                    shouldDisableDate={shouldDisableDate({SCHEDULE, schedule, shipping, scope:SCOPE_DELIVER})}
                    renderDay={renderDay({SCHEDULE, schedule, shipping, scope:SCOPE_DELIVER})}
                    margin="dense"
                    type="text"
                    id="deliverDate"
                    name="deliverDate"
                    error={!!booking.errors.schedule['deliver']}
                    inputProps={{'data-scope':'schedule','data-field':SCOPE_DELIVER}}
                    onInput={localHandleDayInput('deliver')}
                    onChange={localHandleDayInput('deliver')}
                    onAccept={localHandleDayInput('deliver')}
                    views={["date"]}
                    ToolbarComponent={()=><DatePickerTitle text={PHRASES.DELIVERY_DATE_PICKER_TITLE}/>}
                    variant={"dialog"}
                    value={schedule.deliver.DATE}
                    format={SCHEDULE_DATE_FORMAT}
                    label={PHRASES.DELIVERY_DATE}
                    fullWidth
                    autoComplete={SCOPE_DELIVER}
                    //onOpen={localSetDatePickerModeTo({SCHEDULE, schedule, shipping, picker_mode:SCOPE_DELIVER})}
                  />
              </MuiPickersUtilsProvider>
              {schedule.deliver.TIME && <Typography variat="body1">{schedule.deliver.TIME}</Typography>}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  
  const ScheduleUnavailable = ({PHRASES, shipping})=>{
    return (
      <Grid container className={`ScheduleUnavailable`}>
        <Grid item xs={12} sm={7} className={`schedule-unavailable`}>
          {
            !shipping.postcode
            ? (PHRASES.SCHEDULE_POSTCODE_UNKNOWN || `Please enter a postcode so we can show you our schedule`)
            : (
              !!shipping.known_area
              ? (PHRASES.SCHEDULE_POSTCODE_KNOWN_BUT_UNAVAILABLE || PHRASES.UNAVAILABLE || 'Not available to {AREA}').replace(/\{AREA\}/gi, (shipping.area || shipping.postcode || ''))
              : (
                !!shipping.postcode_is_valid
                ? (PHRASES.SCHEDULE_ERROR || `Something is wrong, we can't show you our schedule right now`)
                : (PHRASES.SCHEDULE_POSTCODE_INVALID || `That postcode doesn't appear to be correct, please enter a valid postcode`)
              )
            )
          }
        </Grid>
      </Grid>
    )
  }


  return (
    <div className="ScheduleForm">
      <Typography variant="h6" gutterBottom className={`schedule-title ${(!!booking.errors['schedule'].errors && 'has-errors') || 'no-errors'}`}>
        {PHRASES.SCHEDULE_FORM_TITLE || PHRASES.SCHEDULE || 'SCHEDULE'}
      </Typography>
      
      <Grid container className="postcode-notice-mobile">
        <Grid item xs={8} sm={9} md={10} className="message left-column">
          <Typography variant="body1" align="left">
            { !!shipping.postcode && 
              (
                PHRASES.SCHEDULE_INTRO_MOBILE || `For postcode {AREA}`
              )
              .replace(/\{AREA\}/gi, shipping.area)
              .replace(/\{POSTCODE\}/gi, shipping.postcode)
            }
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3} md={2} align="right" className="change-button right-column">
          <Button className="dont-ui-button" variant="text" color="primary" onClick={promptShow}>
            {PHRASES.SCHEDULE_INTRO_MOBILE_CHANGE_BUTTON || 'Change'}
          </Button>
        </Grid>
      </Grid>

      <Grid container className="schedule-copy">
        <Grid item xs={12} className="schedule-copy-notes" hidden={!(rule && (rule.MINIMUM || rule.NOTES))}>
        {
            // minimum cost message
            !!rule && !!rule.MINIMUM
            ? <Typography variant="body1">{(PHRASES.SCHEDULE_MINIMUM_COST || 'Minimum cost:') + ' '+ rule.MINIMUM}</Typography>
            : null
          }
          {
            // slash to separate text
            !!rule && rule.NOTES && rule.MINIMUM 
            ? " / "
            : ""
          }
          {
            // general notes
            !!rule && !!rule.NOTES
            ? <React.Fragment>{rule.NOTES}</React.Fragment>
            : null
          }
        </Grid>
        {
          (!!shipping.known_area && SCHEDULE.available)
          ? (
            <Grid item xs={12} className="schedule-copy-intro" hidden={!PHRASES.SCHEDULE_FORM_INTRO}>
              <Typography variant="body1">{PHRASES.SCHEDULE_FORM_INTRO}</Typography>
            </Grid>
          ) : ''
        }
      </Grid>

      <Grid container className={`schedule-wrap picking-${schedule.picker_mode}`}>
        {
          !!shipping.known_area && SCHEDULE.available
          ? <ScheduleInputs {...({PHRASES, SCHEDULE, schedule, shipping})}/>
          : <ScheduleUnavailable {...({PHRASES, SCHEDULE, schedule, shipping})}/>
        }
      </Grid>
      
      {/*
      <Grid container spacing={0} className={`schedule-wrap picking-${schedule.picker_mode}`}>
        {
          !!shipping.known_area && SCHEDULE.available
          ? <SchedulePicker {...({PHRASES, SCHEDULE, schedule, shipping})}/>
          : <ScheduleUnavailable {...({PHRASES, SCHEDULE, schedule, shipping})}/>
        }
      </Grid>
      */}

    </div>
  );
}

const mapStateToProps = ({
  PHRASES, SCHEDULE, schedule, shipping, booking
}) => ({
  PHRASES, SCHEDULE, schedule, shipping, booking
})
  
export default withStyles(styles)(connect(
    mapStateToProps,
    {
      promptShow,
      handleDayClick,
      setDatePickerModeTo
    }
)(ScheduleForm));
