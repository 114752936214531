import SPOT_LOAD from '../../config/spot';

//import { SPOT_LOGIN } from '../spot.js'

//import * as noty from '../../helpers/noty';

import { 
    loginPromptShow
} from '../login';

//import { Promisify } from '../../helpers/promisify';

//export const CustomerConnect = window.CustomerConnect;

export const showPasswordPrompt = (event) => (dispatch) => {
    //console.log('showPasswordPrompt',{dispatch,event});

    loginPromptShow(dispatch);
    
}

export const SpotChangePasswordRequest = (dispatch, email, password)=>{
	return new Promise((resolve, reject)=>{
		SPOT_LOAD().then(()=>{
			(window.CustomerConnect.User.ChangePassword({NewPassword:password})) //Promisify
			.done((data)=>{
				//console.log('CustomerConnect.User.ChangePassword',data)
			
				if(!!data.Failed){
					console.error('SpotChangePasswordRequest FAILED', {email, password, data});
					reject(data);
				
				}
				else{
					console.info('SpotChangePasswordRequest OK!', {email, password, data});
					resolve(data);

				}
			
			})
			.fail( err =>{
				console.error('CustomerConnect.User.ChangePassword',{err})
				reject(err);
			})			
		})	
		.catch(e=>{
			//console.error('SPOT LOAD ERROR', e);
		})
	})
}
