import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CreditCardInput from 'react-credit-card-input';
//import FormGroup from '@material-ui/core/FormGroup';
//import OutlinedInput from '@material-ui/core/OutlinedInput';
//import FilledInput from '@material-ui/core/FilledInput';
//import InputLabel from '@material-ui/core/InputLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';

import { BILLING_SCOPE } from './../modules/billing'
//import { updateStateWithUserInput } from './../modules/address'
import AddressForm from './AddressForm'

import {
    setPaymentInput,
    setPaymentInputValue,
    toggleDifferentBillingAddress,
    changePaymentCard,
} from './../modules/payment'


const styles = theme => ({
    root: {
        fontSize: '16px',
        flex: 1,
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
        width: '100%'
    },
    cardSelect: {
        width: '100%',
        'min-width': '280px',
        'margin-bottom': '20px',
    }
});

  
function PaymentForm({
    PHRASES,
    booking,
    payment,
    billing,
    setPaymentInput,
    toggleDifferentBillingAddress,
    changePaymentCard,
    classes
}) {
    const handleCardNumberChange = (e) => {
        //console.log('handleCardNumberChange proxy',e);
        return setPaymentInput({field:'card_number', value: e.target.value})
    }
    const handleCardExpiryChange = (e) => {
        //console.log('handleCardExpiryChange proxy',e);
        return setPaymentInput({field:'card_expiry', value: e.target.value})
    }
    const handleCardCVCChange = (e) => {
        //console.log('handleCardCVCChange proxy',e);
        return setPaymentInput({field:'card_cvc', value: e.target.value})
    }
    return (
        <React.Fragment>
            { PHRASES.PAYMENT_FORM_TITLE &&
                <Typography variant="h6" gutterBottom className={(!!booking.errors['payment'].errors && 'has-errors') || 'no-errors'}>
                    {PHRASES.PAYMENT_FORM_TITLE || PHRASES.PAYMENT || 'PAYMENT'}
                </Typography>
            }
            { PHRASES.PAYMENT_FORM_INTRO &&
                <Typography variant="body1" gutterBottom>
                    {PHRASES.PAYMENT_FORM_INTRO || ''}
                </Typography>
            }
            { !!payment.cards && payment.cards.length>0 &&
                (
                    <Grid item xs={12}>
                        { PHRASES.PAYMENT_EXISTING_CARDS_INTRO &&
                            <Typography variant="body1" gutterBottom>
                                {PHRASES.PAYMENT_EXISTING_CARDS_INTRO || ''}
                            </Typography>
                        }
                        <FormControl className={classes.formControl}>
                            {/* 
                            <InputLabel htmlFor="payment-CardId">
                                {PHRASES.PAYMENT_EXISTING_CARD_LABEL || 'Existing card'}
                            </InputLabel>
                            */}
                            <Select
                                displayEmpty
                                value={payment.CardId}
                                onChange={changePaymentCard}
                                inputProps={{
                                    name: 'CardId',
                                    id: 'payment-CardId',
                                }}
                                className={classes.cardSelect}
                            >
                                {
                                    !!payment.cards && payment.cards.map( card=> {
                                        return (
                                            <MenuItem value={card.CardId}>
                                                {card.CardInfo}
                                                {', expiry: '}
                                                {card.FormattedCardExpiration}
                                            </MenuItem>
                                        )
                                    })
                                }
                                <MenuItem key="new" value="">
                                    <em>{PHRASES.PAYMENT_EXISTING_CARDS_NEW_CARD || 'New card'}</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )
            }
            {(!payment.CardId || !payment.cards || !(payment.cards.length>0)) && (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={(!!booking.errors['payment'].errors && 'has-errors') || 'no-errors'}>
                            <CreditCardInput
                                error={!!booking.errors['payment']}
                                cardNumberInputProps={{ value: payment.card_number_typed || payment.card_number, onChange: handleCardNumberChange }}
                                cardExpiryInputProps={{ value: payment.card_expiry_typed || payment.expiry, onChange: handleCardExpiryChange }}
                                cardCVCInputProps={{ value: payment.card_cvc_typed || payment.cvc, onChange: handleCardCVCChange }}
                                fieldClassName="payment-card-input"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={payment.different_billing_address}
                                        onChange={toggleDifferentBillingAddress}
                                        value="y"
                                        color="primary"
                                    />
                                }
                                label={PHRASES.BILLING_ADDRESS_IS_DIFFERENT_FROM_SHIPPING || "Different billing address"}
                            />
                        </Grid>
                        <Grid item xs={12} hidden={!payment.different_billing_address}>
                        <AddressForm
                            addressType={BILLING_SCOPE}
                            addressData={billing}
                        />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

const mapStateToProps = ({
    PHRASES, booking, payment, billing
}) => ({
    PHRASES, booking, payment, billing
})
    
export default withStyles(styles)(connect(
      mapStateToProps,
      {
        setPaymentInput,
        setPaymentInputValue,
        toggleDifferentBillingAddress,
        changePaymentCard,
      }
)(PaymentForm));
  
