export const INSTRUCTIONS_ITEMS = 'Instructions/Items'
export const INSTRUCTIONS_DRIVER = 'Instructions/Driver'

export const ITEMS_TERMS_AND_CONDITIOSN = 'Items/TermsAndConditions'

const initialState = { 
    pickup_delivery_comments: '',
    customer_memo: ''
};

export default (state = initialState, action) => {
  switch (action.type) {

    case INSTRUCTIONS_ITEMS:
      return {
        ...state,
        customer_memo: action.payload.customer_memo
      }

    case INSTRUCTIONS_DRIVER:
      return {
        ...state,
        pickup_delivery_comments: action.payload.pickup_delivery_comments
      }

      
    default:
      return state
  }
}

export const setInstructionsDriver = ({target:{value:pickup_delivery_comments}}) => {
  return dispatch => {
    setInstructionsDriverValue(dispatch, pickup_delivery_comments)
  }
}
export const setInstructionsDriverValue = (dispatch, pickup_delivery_comments) => {
  dispatch({
    type: INSTRUCTIONS_DRIVER,
    payload:{pickup_delivery_comments}
  })
};


export const setInstructionsItems = ({target:{value:customer_memo}}) => {
  return dispatch => {
    setInstructionsItemsValue(dispatch, customer_memo)
  }
}
export const setInstructionsItemsValue = (dispatch, customer_memo) => {
  dispatch({
    type: INSTRUCTIONS_ITEMS,
    payload:{customer_memo}
  })
};


