import React from 'react';
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

import {
  promptHide,
  promptContinue,
  promptStraightToLogin,
  promptContinueCozEnterKey,
} from './../modules/prompt'

import {
  setPostcode
} from './../modules/shipping'

import { changeHash } from './../helpers/changeHash';

//import LinearProgress from '@material-ui/core/LinearProgress';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import { CircleLoader } from 'react-spinners';

import {
  loginPromptHide,
  loginPromptHideClick,
  loginHandler,
  loginClick,
  resetPasswordClick,
  resetPasswordHandler,
  setPassword
} from './../modules/login'

import {
  setEmail,
} from './../modules/spot/index.js'


const styles = theme => ({
  //root: { fontSize: '16px', },
  paper: {
    [theme.breakpoints.down('sm')]:{
      margin:'10px'
    }
  }
});


const PostcodePrompt = ({
  PHRASES, 
  login,
  shipping,
  setPostcode, 
  fullScreen,
  promptHide, 
  promptContinue,
  promptStraightToLogin,
  promptContinueCozEnterKey,
  loginPromptHide,
  loginPromptHideClick,
  loginHandler,
  loginClick,
  resetPasswordClick,
  resetPasswordHandler,
  setPassword,
  setEmail,
  classes
}) => {

  React.useEffect(() => {
    changeHash('#booking-prompt');
    //return () => window.location.hash = '';
  }, []);


  //console.log('prompt', {shipping,classes});
  
  return (
    <div>
      <Dialog
        id="BookingPrompt"
        classes={classes}
        open
        onClose={promptHide}
        aria-labelledby="postcode-dialog-title"
        className="dont-ui-button booking-prompt-dialog"
        //fullScreen={(PHRASES.PROMPT_SHOW_LOGIN_TOO==='YES') && fullScreen ? true : false}
      >
        
        {PHRASES.PROMPT_TITLE && <DialogTitle className="form-dialog-title" id="postcode-prompt-title">{PHRASES.PROMPT_TITLE}</DialogTitle>}

        <DialogContent className="form-dialog-content">
        
          <Grid container alignItems="baseline" className={`booking-prompt-body`}>
            {PHRASES.PROMPT_INTRO_TITLE && <Grid item xs={12}><Typography variant="h3">{PHRASES.PROMPT_INTRO_TITLE}</Typography></Grid>}
            {PHRASES.PROMPT_INTRO && <Grid item xs={12}><Typography variant="body1">{PHRASES.PROMPT_INTRO}</Typography></Grid>}
            <Grid container className={`booking-prompt-launch`}>
              <Grid item xs={8}>

                <TextField
                  autoFocus
                  margin="dense"
                  id="postcode"
                  label={PHRASES.YOUR_POSTCODE}
                  type="text"
                  placeholder={PHRASES.POSTCODE_EXAMPLE}
                  value={shipping.postcode || ''}
                  onInput={setPostcode}
                  onKeyDown={promptContinueCozEnterKey}
                  fullWidth
                  className="booking-prompt-launch-input" 
                />

              </Grid>
              <Grid item xs={4}>
                  <Button onClick={promptContinue} color="primary" variant="contained" 
                    disabled={!shipping.known_area}
                    fullWidth
                    className="booking-prompt-launch-button" 
                  >
                    {PHRASES.PROMPT_START_BUTTON || PHRASES.CONTINUE || 'Continue'}
                  </Button>
              </Grid>
            </Grid>

            {
              //console.log('PHRASES.PROMPT_SHOW_LOGIN_TOO',PHRASES.PROMPT_SHOW_LOGIN_TOO)
            }
            {(PHRASES.PROMPT_SHOW_LOGIN_TOO==='YES') && (
              (!!PHRASES.PROMPT_OUTRO || !!PHRASES.PROMPT_OUTRO_TITLE) && (
                <Grid container>
                  {PHRASES.PROMPT_OUTRO_TITLE && <Grid item xs={12}><Typography variant="h3">{PHRASES.PROMPT_OUTRO_TITLE}</Typography></Grid>}
                  {PHRASES.PROMPT_OUTRO && <Grid item xs={12}><Typography variant="body1">{PHRASES.PROMPT_OUTRO}</Typography></Grid>}
                </Grid>
              )
            )}

            {/*!(PHRASES.PROMPT_SHOW_LOGIN_TOO==='YES') && (
              <Grid item xs={12}>
                <Button className="dont-ui-button" onClick={promptHide} color="secondary" style={{"marginRight": "auto"}}>
                  {PHRASES.CANCEL}
                </Button>
              </Grid>
            )*/}


            <Grid container alignItems={"flex-end"} justify={"space-between"}>
              <Grid item xs={7}>
                <TextField
                  autoFocus={!login.email}
                  margin="dense"
                  id="login-email"
                  label={PHRASES.LOGIN_PROMPT_EMAIL_LABEL || PHRASES.EMAIL || 'Email'}
                  type="text"
                  placeholder={PHRASES.LOGIN_PROMPT_EMAIL_PLACEHOLDER}
                  value={login.email || ''}
                  onInput={setEmail}
                  fullWidth
                  readOnly
                />
              </Grid>

              <Grid item xs={7}>
                <TextField
                  autoFocus={!!login.email}
                  margin="dense"
                  id="password"
                  label={PHRASES.LOGIN_PROMPT_PASSWORD_LABEL || PHRASES.PASSWORD || 'Password'  }
                  type="password"
                  placeholder={PHRASES.LOGIN_PROMPT_PASSWORD_PLACEHOLDER}
                  value={login.password || ''}
                  onInput={setPassword}
                  fullWidth
                />

              </Grid>
              <Grid item xs={4} align="right">
                <Button className="dont-ui-button login-button" 
                  onClick={loginClick}
                  color="primary" 
                  variant="contained" 
                  disabled={!login.password}
                  style={({
                    width:'100%'
                  })}
                >
                  {PHRASES.LOGIN_PROMPT_BUTTON_LOGIN || PHRASES.LOGIN || 'Login'}
                </Button>
              </Grid>


              <Grid container spacing={0} justify="space-between" style={({ marginTop:"20px" })}>
                <Grid item xs={3}>
                  <Button className="dont-ui-button cancel-button" onClick={promptHide} color="secondary"
                    style={({
                      width:'100%'
                    })}
                  >
                    {PHRASES.LOGIN_PROMPT_BUTTON_CANCEL || PHRASES.CANCEL || 'Cancel'}
                  </Button>
                </Grid>
                <Grid item xs={6} align="right">
                  <Button className="dont-ui-button reset-button" onClick={resetPasswordClick}
                    style={({
                      width:'100%'
                    })}
                  >
                    {PHRASES.LOGIN_PROMPT_RESET_PASSWORD_TEXT || PHRASES.RESET_PASSWORD || 'Reset Password'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          
          
        </DialogContent>

        {/*
        {(PHRASES.PROMPT_SHOW_LOGIN_TOO==='YES') && (
          <DialogActions className={`booking-prompt-actions`}>
            <Grid container xs={12}>
              <Grid item xs={8}>
                <Button className="dont-ui-button" onClick={promptHide} color="secondary" style={{"marginRight": "auto"}}>
                  {PHRASES.CANCEL}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button className="dont-ui-button" fullWidth onClick={promptStraightToLogin} color="primary" variant="contained">
                  {PHRASES.PROMPT_LOGIN_BUTTON || PHRASES.LOGIN || "Login"}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        )}
        */}

      </Dialog>
    </div>
  );
}

const mapStateToProps = ({
  PHRASES, SCHEDULE, 
  shipping,
  login
}) => ({
  PHRASES, SCHEDULE,
  shipping,
  login
})

export default withStyles(styles)(withMobileDialog({breakpoint: 'xs'})(connect(
  mapStateToProps,
  {
    promptHide,
    promptContinue,
    promptStraightToLogin,
    promptContinueCozEnterKey,
    setPostcode,
    loginPromptHide,
    loginPromptHideClick,
    loginHandler,
    loginClick,
    resetPasswordClick,
    resetPasswordHandler,
    setPassword,
    setEmail,
  }
)(PostcodePrompt)))
