//import { Promisify } from './../helpers/promisify';

import {
	SET_RANDOM_TEMPORARY_PASSWORD,
	//LOGIN_SET_PASSWORD,
	LoadAllTheThings
} from './login';

import { SPOT_ACTIVE_SESSION, SPOT_ACTIVE_TOKEN } from './../config/spot.Session';

import {
	CONFIRMATION_SHOW
} from './confirmation';

import {
	loadSpot,
	SpotState,
	SpotSaveCustomer,
	SpotSavePickupRequest
} from './spot.js';

import { SpotLoginRequest } from './spot/login';

import { changeHash } from './../helpers/changeHash';

export const BOOKING_SHOW = 'Booking/Show'
export const BOOKING_HIDE = 'Booking/Hide'
export const BOOKING_IDLE = 'Booking/Idle'
export const BOOKING_BUSY = 'Booking/Busy'
export const BOOKING_ERROR = 'Booking/Error'
export const BOOKING_VALID = 'Booking/Valid'
export const BOOKING_CANCEL = 'Booking/Cancel'
export const BOOKING_CONFIRM = 'Booking/Confirm'
export const BOOKING_CONFIRMED = 'Booking/Confirmed'
export const BOOKING_RESTART = 'Booking/Restart'
export const BOOKING_CANCEL_CONFIRMATION = 'Booking/Cancel/Confirmation'

//export const CustomerConnect = window.CustomerConnect;

const initialState = {
	busy: false,
	confirmed: false,
	error: false,
	errors: {
		billing: {}, 
		shipping: {}, 
		instructions: {}, 
		payment: {}, 
		schedule: {},
		confirm: {}
	}
}

export default (state = initialState, action) => {
  switch (action.type) {

	case BOOKING_IDLE:
		return {...state, ...{ busy: false }}
		
	case BOOKING_BUSY:
		return {...state, ...{ busy: true }}

	case BOOKING_CONFIRMED:
		//console.log('BOOKING_CONFIRMED',{payload:action.payload});
		window.BOOKINGS = window.BOOKINGS || {};
		if(action.payload && action.payload.Response && action.payload.Response.TransactionNumber){
			window.BOOKINGS[action.payload.TransactionNumber] = action.payload;
		}
		return {
			...state,
			...action.payload,
			...{ confirmed: true, error:false }
		}
	
	case BOOKING_RESTART:
		//console.log('BOOKING_RESTART',{payload:action.payload});
		return {
			...state,
			...{ Request: null, Response: null },
			...{ confirmed: false, error:false }
		}

	case BOOKING_VALID:
		return {
			...state,
			...{ errors: initialState.errors },
			...{ confirmed: false, error:true }
		}

	case BOOKING_ERROR:
		return {
			...state,
			...{ errors: action.payload.errors },
			...{ confirmed: false, error:true }
		}

	default:
	  return state

  }
}


export const bookingIdle = () => {
	return {
		type: BOOKING_IDLE
	}
}
export const bookingBusy = () => {
	return {
		type: BOOKING_BUSY
	}
}
export const bookingHide = () => {

	changeHash('');
		
	return {
		type: BOOKING_HIDE
	}
}
export const bookingShow = () => {
	//return {
	//	type: BOOKING_SHOW
	//}
	return (dispatch, getState) => {
		const state = getState();

		// 2019 - just show form
		//dispatch({type: BOOKING_SHOW});

		// 2020 - load customer data if we have a session ID
		// see login method here, https://apidocs.mydrycleaner.com/?version=latest#44c9a06b-04f8-4bf2-b6e1-457d5c4ddb7e
		const token = SPOT_ACTIVE_TOKEN(); // this is a logged in session
		const session = SPOT_ACTIVE_SESSION(); // this is just any session
		//console.log('bookingShow', {session,token, SPOT_ACTIVE_SESSION:session, SPOT:window.SPOT,CustomerConnect:window.CustomerConnect,state});
		if(!!session && !!token){
			LoadAllTheThings(dispatch, state, { autoLogin:true })
				.then(res=>{
					//console.log('bookingShow LoadAllTheThings res', {res, session, state})
					dispatch({ type: BOOKING_SHOW })
				})
				.catch(err=>{
					console.error('bookingShow LoadAllTheThings err', {err, session, state})
					dispatch({ type: BOOKING_SHOW })
				})
			;
		}
		else{
			dispatch({ type: BOOKING_SHOW })
		}

	}
}
export const bookingCancel = () => {

	changeHash('');
	
	return {
		type: BOOKING_CANCEL
	}
}

export const bookingConfirm = () => {
	return (dispatch, getState) => {
		
		// validate first
		//dispatch({type:BOOKING_BUSY,payload:{}});

		loadSpot(dispatch).then((SPOT, SpotSettings, CustomerConnect)=>{

			let originalState = getState();
			console.log('bookingConfirm', {originalState});
			let state = SpotState(Object.assign({}, {...originalState}));
			let Customer = state.Customer;
			let LoginRequest = state.LoginRequest;
			console.log('bookingConfirm - SpotState', {state});
			console.log('bookingConfirm - NewPassword', {NewPassword:state.NewPassword});

			// if we are setting a new password, put it in the state
			// NEW 2020 - LET STATE KNOW WE NEED A PASSWORD
			if(!!state.NewPassword){
				//console.log('bookingConfirm - NewPassword required', {state});
				dispatch({
					type:SET_RANDOM_TEMPORARY_PASSWORD,
					payload:{
						needsPasswordReset:true,
						password:state.login.password
					}
				})
			}

			// leave if we have errors
			if(!!state.errors){
				console.warn('bookingConfirm - leave, there are errors', {errors:state.errors,state});
				dispatch({type:BOOKING_IDLE,payload:{}});
				dispatch({type:BOOKING_ERROR,payload:{ errors: state.error_states }});
				return;
			}
			else{
				console.warn('bookingConfirm - NO errors', state);
				dispatch({type:BOOKING_VALID});
			}
	
			// start booking process
			dispatch({type:BOOKING_BUSY,payload:{}})




			//################################################
			// relay for poor network environments
			const OptimisticPickupRequest = (() => {
				const BookingRef = (function generateRandomCode() {
					var characters = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
					var result = 'T';
					var today = new Date();
					var month = String(today.getMonth() + 1).padStart(2, '0');
					var day = String(today.getDate()).padStart(2, '0');
					result += month + day;
					for (var i = 0; i < 5; i++ ) {
						result += characters.charAt(Math.floor(Math.random() * characters.length));
					}
					return result;
				})();
				const { shipping, billing, instructions, payment, schedule } = state;
				return { shipping, billing, instructions, payment, schedule, BookingRef };
			})();
			const OptimisticBookingConfirmation = {
				Request: OptimisticPickupRequest,
				Response: {
					TransactionNumber:OptimisticPickupRequest.BookingRef
				}
			};
			const RealPickupRequestReport = (status, result) => {
				const payload = { 
					status, 
					BookingRef: OptimisticPickupRequest.BookingRef, 
					...result, 
					data:OptimisticPickupRequest
				}; //const payload = { shipping, billing, instructions, payment, schedule, BookingRef };
				const relayUrl = window.location.hostname.match(/localhost/)
					? `https://hkdk.events/i1jVK9N4nqJS/confirmation/${status}`
					: `https://hkdk.events/EnDEN6kGcQzm/confirmation/${status}`;
				fetch(relayUrl, {
					method: "POST",
					headers: (function() {
						var headers = new Headers();
						headers.append("content-type", "application/json");
						return headers;
					})(),
					body: JSON.stringify(payload)
				})
					.then(response => response.text())
					.then(result => {
						console.info("RELAY OK", { state, payload, result });
					})
					.catch(error => {
						console.error("RELAY ERROR", { state, payload, error });
					});

			}
			const RealPickupRequestFailed = (result) => RealPickupRequestReport("failed", result);
			const RealPickupRequestWorked = (result) => RealPickupRequestReport("worked", result);
			const OptimisticConfirmation = () => {
				dispatch({type:CONFIRMATION_SHOW,payload:{booking:OptimisticBookingConfirmation}});
			};
			OptimisticConfirmation();
			//################################################




			//################################################
			// relay for poor network environments
			const RELAY = function(){
				//const { shipping, billing, instructions, payment, schedule, error } = state;
				if(!!state.error){
					console.warn("ABORT RELAY: ERROR", { state, error:state.error });
					return;
				}
				const payload = { ...OptimisticPickupRequest }; //const payload = { shipping, billing, instructions, payment, schedule, BookingRef };
				const relayUrl = window.location.hostname.match(/localhost/)
					? "https://hkdk.events/i1jVK9N4nqJS"
					: "https://hkdk.events/EnDEN6kGcQzm";
				fetch(relayUrl, {
					method: "POST",
					headers: (function() {
						var headers = new Headers();
						headers.append("content-type", "application/json");
						return headers;
					})(),
					body: JSON.stringify(payload)
				})
					.then(response => response.text())
					.then(result => {
						console.info("RELAY OK", { state, payload, result });
					})
					.catch(error => {
						console.error("RELAY ERROR", { state, payload, error });
					});
			};
			RELAY();
			//################################################

			
			
			try{

				
				// login then save account, then book
				if(!!state.login.autoLogin){
					//console.log('BookingConfirm > SAVE AND BOOK without login',{Customer});
					
					// save customer
					SpotSaveCustomer( dispatch, Customer)
					.then( (SaveCustomerResponse) => {
						console.info('CustomerConnect.Customer.SaveCustomer result',{Customer,SaveCustomerResponse})
					
						// book
						SpotSavePickupRequest(dispatch,state)
						.then( (PickupRequest) => {
							console.info('CustomerConnect.Route.SavePickupRequest result',{PickupRequest})
							//dispatch({type:BOOKING_IDLE,payload:{}});
							RealPickupRequestWorked(PickupRequest); // dispatch({type:CONFIRMATION_SHOW,payload:{booking:PickupRequest}});
						})
						.catch( (err) => {
							console.error('CustomerConnect.Route.SavePickupRequest error',{err})
							RealPickupRequestFailed({info:"CustomerConnect.Route.SavePickupRequest",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
						})
		
					})
					.catch( (err) => {
						console.error('CustomerConnect.Customer.SaveCustomer error',{err})
						RealPickupRequestFailed({info:"CustomerConnect.Customer.SaveCustomer",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
					})

				}
				// LOGIN > SAVE > BOOK
				else if(!!state.LoginFirst){
					//console.log('BookingConfirm LOGIN FIRST > SAVE > BOOK',{LoginRequest});

					// login
					SpotLoginRequest(
						dispatch,
						LoginRequest.EmailAddress,
						LoginRequest.Password
					)
					.then((LoginResponse)=>{
						console.info('CustomerConnect.User.Login result',{LoginRequest,LoginResponse});



						// save customer
						SpotSaveCustomer( dispatch, Customer) //Promisify
						.then( (SaveCustomerResponse) => {
							console.info('CustomerConnect.Customer.SaveCustomer result',{Customer,SaveCustomerResponse})
							
							// book
							SpotSavePickupRequest(dispatch,state) //Promisify
							.then( (PickupRequest) => {
								console.info('CustomerConnect.Route.SavePickupRequest result',{PickupRequest})
								//dispatch({type:BOOKING_IDLE,payload:{}});
								RealPickupRequestWorked(PickupRequest); // dispatch({type:CONFIRMATION_SHOW,payload:{booking:PickupRequest}});
							})
							.catch( (err) => {
								console.error('CustomerConnect.Customer.SaveCustomer error',{err})
								RealPickupRequestFailed({info:"CustomerConnect.Customer.SaveCustomer",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
							})
			
						})	
						.catch( (err) => {
							console.error('CustomerConnect.User.Login error',{err})
							RealPickupRequestFailed({info:"CustomerConnect.User.Login",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
						})

					})
					.catch( (err) => {
						console.error('CustomerConnect.User.Login error',{err})
						RealPickupRequestFailed({info:"CustomerConnect.User.Login",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
					})

				}
				// login then save account, then book
				else{
					//console.log('BookingConfirm SAVE > LOGIN > BOOK',{Customer});
					
					// save customer
					(SpotSaveCustomer( dispatch, Customer)) //Promisify
					.then( (SaveCustomerResponse) => {
						console.info('CustomerConnect.Customer.SaveCustomer result',{Customer,SaveCustomerResponse})
						
						// login
						SpotLoginRequest(
							dispatch,
							LoginRequest.EmailAddress,
							LoginRequest.Password
						)
						.then((LoginResponse)=>{
							console.info('CustomerConnect.User.Login result',{LoginRequest,LoginResponse})
							
							// book
							SpotSavePickupRequest(dispatch,state)
							.then( (PickupRequest) => {
								console.info('CustomerConnect.Route.SavePickupRequest result',{PickupRequest})
								//dispatch({type:BOOKING_IDLE,payload:{}});
								RealPickupRequestWorked(PickupRequest); // dispatch({type:CONFIRMATION_SHOW,payload:{booking:PickupRequest}});
							})
							.catch( (err) => {
								console.error('CustomerConnect.Route.SavePickupRequest error',{err})
								RealPickupRequestFailed({info:"CustomerConnect.Route.SavePickupRequest",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
							})
			
						})	
						.catch( (err) => {
							console.error('CustomerConnect.User.Login error',{err})
							RealPickupRequestFailed({info:"CustomerConnect.User.Login",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
						})
		
					})
					.catch( (err) => {
						console.error('CustomerConnect.Customer.SaveCustomer error',{err})
						RealPickupRequestFailed({info:"CustomerConnect.Customer.SaveCustomer",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
					})

				};


			}catch(err){

				console.error('UNKONWN ERROR',{err})
				RealPickupRequestFailed({info:"UNKNOWN ERROR",error:err}); // dispatch({type:BOOKING_IDLE,payload:{}});
				
			}
		
		}) //loadSpot.then()
	}
}

export const bookingCancelConfirmation = () => {
	return dispatch => {

		changeHash('');
		
		dispatch({
			type: BOOKING_CANCEL_CONFIRMATION
		})
	}
}

