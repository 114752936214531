import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Typography from '@material-ui/core/Typography';
//import Hidden from '@material-ui/core/Hidden';

import LinearProgress from '@material-ui/core/LinearProgress';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import { CircleLoader } from 'react-spinners';

import {
  changePasswordPromptHide,
  changePasswordPromptHideClick,
  changePasswordHandler,
  changePasswordClick,
  setPassword
} from '../modules/changePassword'

//import { Typography } from '@material-ui/core';


const styles = theme => ({
  root: { fontSize: '16px', flexGrow: 1, },
  passwordIsValidError:{
      color: 'red',
  },
  dialogPaper: {
     margin:'10px',
     "max-width": "340px",
  },
  overrides: {
  }
})

const ChangePasswordPrompt = ({PHRASES, 
  changePassword,
  //changePasswordPromptHide,
  changePasswordPromptHideClick,
  //changePasswordHandler,
  changePasswordClick,
  setPassword,
  classes
}) => (
    <Dialog
      id={`changePassword`}  
      className={`changePassword ${classes.root}`}
      open={!!changePassword.visible}
      onClose={changePasswordPromptHideClick}
      maxWidth={'sm'}
      fullWidth={true}
      aria-labelledby="create-password-title"
      classes={{ paper:classes.dialogPaper }}
    >
      <DialogTitle className="form-dialog-title" id="create-password-title">
        {/* <Typography variant="h3"> */}
          {
            (PHRASES.CHANGE_PASSWORD_PROMPT_TITLE || PHRASES.CHANGE_PASSWORD || 'Set Password')
          }
        {/* </Typography> */}
      </DialogTitle>

      {
        changePassword.busy
        ? <LinearProgress className={classes.lineProgress} />
        : null
      }

      <DialogContent>
        
        {!!PHRASES.CHANGE_PASSWORD_PROMPT_INTRO && (
          <DialogContentText>
            {PHRASES.CHANGE_PASSWORD_PROMPT_INTRO}
          </DialogContentText>
        )}
      
        {/* 
          !!login.busy && (
            <CircleLoader
              style={{
                "z-index": "999",
                "position": "absolute",
                "height": "0",
                "background": "#fff",
                "right": "40px",
                "top": "20px",
                "display": !!login.busy ? 'block' : 'none'
              }}
              //className={override}
              sizeUnit={"px"}
              size={150}
              color={'#000099'}
              loading={login.busy}
            />
          )
        */}
        
        <TextField
          autoFocus={!!changePassword.email}
          margin="dense"
          id="password"
          label={PHRASES.CHANGE_PASSWORD_PROMPT_PASSWORD_LABEL || PHRASES.PASSWORD || 'Password'  }
          type="password"
          placeholder={PHRASES.CHANGE_PASSWORD_PROMPT_PASSWORD_PLACEHOLDER}
          value={changePassword.password || ''}
          onInput={setPassword}
          fullWidth
        />


        {!!PHRASES.CHANGE_PASSWORD_PROMPT_PASSWORD_INSTRUCTIONS && (
          <DialogContentText>
            {PHRASES.CHANGE_PASSWORD_PROMPT_PASSWORD_INSTRUCTIONS}
          </DialogContentText>
        )}


        {!changePassword.passwordIsValid && !!PHRASES.CHANGE_PASSWORD_PROMPT_PASSWORD_INVALID && (
          <DialogContentText className={classes.passwordIsValidError}>
            {PHRASES.CHANGE_PASSWORD_PROMPT_PASSWORD_INVALID}
          </DialogContentText>
        )}


      </DialogContent>
      <DialogActions className={`dialog-actions`}>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Button className="dont-ui-button cancel-button" onClick={changePasswordPromptHideClick} color="secondary">
              {PHRASES.CHANGE_PASSWORD_PROMPT_BUTTON_CANCEL || PHRASES.CANCEL || 'Cancel'}
            </Button>
          </Grid>
          <Grid item xs={8} align="right">
            <Button className="dont-ui-button reset-button" 
              //style={{float:"left"}}
              onClick={changePasswordClick} 
              disabled={!changePassword.passwordIsValid}
            >
              {PHRASES.CHANGE_PASSWORD_PROMPT_BUTTON_CONFIRM || PHRASES.CHANGE_PASSWORD || PHRASES.RESET_PASSWORD || 'Change Password'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
);

const mapStateToProps = ({
  PHRASES, SCHEDULE, changePassword
}) => ({
  PHRASES, SCHEDULE, changePassword
})

export default withStyles(styles)(connect(
  mapStateToProps,
  {
    changePasswordPromptHide,
    changePasswordPromptHideClick,
    changePasswordHandler,
    changePasswordClick,
    setPassword
  }
)(ChangePasswordPrompt))