import React from 'react';
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { changePasswordPromptShowClick } from '../modules/changePassword';

import {
  confirmationHide
} from '../modules/confirmation'

import * as BookingFormComponents from './../components'

const ConfirmationPrompt = ({
  PHRASES, SCHEDULE, 
  login, 
  booking, 
  changePasswordPromptShowClick, 
  confirmationHide
}) => {

  console.log("ConfirmationPrompt", { needsPasswordReset: login.needsPasswordReset, manualLogin: login.manualLogin, autoLogin: login.autoLogin });

  return (
    <div>
      <Dialog
        id="BookingConfirmation"
        open
        className="dont-ui-button"
        onClose={confirmationHide}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle className="form-dialog-title" id="confirmation-dialog-title">{PHRASES.BOOKING_CONFIRMED_TITLE}</DialogTitle>
        <DialogContent>
          
          <DialogContentText>
            <Grid container>
              <Grid item xs={12}>
                <React.Fragment>{booking.TransactionNumber}</React.Fragment>
                <React.Fragment>{PHRASES.BOOKING_CONFIRMED_INFO}</React.Fragment>
              </Grid>
            </Grid>
          </DialogContentText>
          
          {/* !!login.needsPasswordReset  */}
          {
            false // turned off because of unreliable bookings
            && !login.manualLogin 
            && !login.autoLogin
            && !!PHRASES.BOOKING_CONFIRMED_SET_PASSWORD_INFO 
            && !!PHRASES.BOOKING_CONFIRMED_SET_PASSWORD_BUTTON_TEXT
            && (
              <DialogContentText>
                <Grid container>
                  <Grid item xs={12}>
                    <React.Fragment>{PHRASES.BOOKING_CONFIRMED_SET_PASSWORD_INFO}</React.Fragment>
                    <Button className="dont-ui-button" onClick={changePasswordPromptShowClick} color="primary">
                      {PHRASES.BOOKING_CONFIRMED_SET_PASSWORD_BUTTON_TEXT}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContentText>
            )
          }

        </DialogContent>
        <DialogActions>
          <Button className="dont-ui-button" onClick={confirmationHide} color="primary">
            {PHRASES.DONE}
          </Button>
        </DialogActions>
      </Dialog>


      <BookingFormComponents.ChangePasswordPrompt/>

    </div>
  );
}

const mapStateToProps = ({
  PHRASES, SCHEDULE, 
  login,
  booking
}) => ({
  PHRASES, SCHEDULE,
  login,
  booking
})

export default connect(
  mapStateToProps,
  {
    changePasswordPromptShowClick,
    confirmationHide
  }
)(ConfirmationPrompt)
