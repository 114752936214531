import { changeHash } from './../helpers/changeHash';

export const CONFIRMATION_HIDE = 'Confirmation/Hide';
export const CONFIRMATION_SHOW = 'Confirmation/Show';

export const confirmationHide = () => {
	return dispatch => {

		changeHash('');
		
		dispatch({
			type: CONFIRMATION_HIDE
		})
	}
}

export const confirmationShow = () => {
	return dispatch => {
		dispatch({
			type: CONFIRMATION_SHOW
		})
	}
}
