import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';

import { withStyles } from '@material-ui/core/styles';

import { BILLING_SCOPE } from './../modules/billing'
import { updateStateWithUserInput } from './../modules/address'

import AddressForm from './AddressForm'


const styles = theme => ({
  //root: { fontSize: '16px', },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
});


const BillingForm = ({
  PHRASES, 
  login,
  shipping, 
  billing,
  booking,
  setPostcode, 
  updateStateWithUserInput,
  addressData,
  addressType
}) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom className={(!!booking.errors['blling'].errors && 'has-errors') || 'no-errors'}>
        {PHRASES.BILLING_FORM_TITLE || PHRASES.BILLING || PHRASES.ADDRESS || 'Address'}
      </Typography>
      <Grid container spacing={1} className={(!!booking.errors['blling'].errors && 'has-errors') || 'no-errors'}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={!!booking.errors[addressType]['firstName']}
            id="firstName"
            name="firstName"
            inputProps={{'data-scope':addressType,'data-field':"firstName"}}
            onInput={updateStateWithUserInput}
            onChange={updateStateWithUserInput}
            value={addressData.firstName}
            label={PHRASES.FIRST_NAME}
            fullWidth
            autoComplete="forename"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={!!booking.errors[addressType]['lastName']}
            id="lastName"
            name="lastName"
            inputProps={{'data-scope':addressType,'data-field':"lastName"}}
            onInput={updateStateWithUserInput}
            onChange={updateStateWithUserInput}
            value={addressData.lastName}
            label={PHRASES.LAST_NAME}
            fullWidth
            autoComplete="surname"
          />
        </Grid>
        <AddressForm
          addressType={BILLING_SCOPE}
          addressData={shipping}
        />
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = ({
  PHRASES, booking, login, billing, shipping
}) => ({
  PHRASES, booking, login, billing, shipping
})

export default withStyles(styles)(connect(
    mapStateToProps,
    {
      updateStateWithUserInput
    }
)(BillingForm));