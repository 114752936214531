export const changeHash = (newHash)=> {
    //console.log('changeHash',{newHash,DONT_WATCH_HASH:window.DONT_WATCH_HASH,DONT_HANDLE_HASH:window.DONT_HANDLE_HASH});

    // global off switch to stop clashes with angular app
    if(!!window.DONT_WATCH_HASH) return;
    
    // let the hash change handler know we're doing this
    window.DONT_HANDLE_HASH = true;

    // set the hash
    window.location.hash = newHash;

    // the hash handler will set this flag back to false when it has fired
    // window.DONT_HANDLE_HASH = true;

}