import { 
    SPOT_LOGOUT, 
    //SPOT_LOGIN,
} from './spot.js';

import { LOGIN_SET_BILLING, LOGIN_SET_PAYMENT } from './login';

export const PAYMENT_BILLING_CHOICE = 'Payment/Billing/Choice'
export const PAYMENT_BILLING_INPUT = 'Payment/Billing/Input'
export const PAYMENT_CARD_INPUT = 'Payment/Input'

export const PAYMENT_CARDS_SET = 'Payment/Cards/Set'
export const PAYMENT_CARDS_SELECT = 'Payment/Cards/Select'

const initialState = { 
    card_number:'',
    card_expiry:'',
    card_cvc:'',

    cards: [], // existing cards will be populated here
    CardId: '',

    different_billing_address: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case SPOT_LOGOUT:
        return {...state, 
            CardId: '', 
            cards: []
        }


    case PAYMENT_CARDS_SELECT:
        return {...state, 
            CardId:action.payload.CardId
        }


    case PAYMENT_CARDS_SET:
    case LOGIN_SET_PAYMENT:
        return {...state,
            cards:action.payload.cards,
            CardId:(action.payload.cards.length && action.payload.cards[0].CardId) || ''
        }

	case PAYMENT_BILLING_CHOICE:
	  return {...state, different_billing_address:action.payload.different_billing_address}

	case PAYMENT_CARD_INPUT:
	  return {...state, ...action.payload}

    case LOGIN_SET_BILLING:
      return {
        ...state,
        ...action.payload
      }
    default:
	  return state
  }
}

export const setPaymentInput = ({field,value}) => {
    //console.log('setPaymentInput',{field,value})
	return dispatch => {
        setPaymentInputValue(dispatch, {field,value})
	}
}
export const setPaymentInputValue = (dispatch, {field,value}) => {
    //console.log('setPaymentInputValue',{ [field]: value })
    dispatch({
        type: PAYMENT_CARD_INPUT,
        payload: { 
            
            // actual value, no spaces
            [field]: value.replace(/[^\d/]+/gi, ''),
            
            // what the user sees in the input
            [field+'_typed']: value
        }
        /*
        ((field, value)=>{
            let o = {};
            o[field] = value;
            return o;
        })(field, value)
        */
	})
};


export const toggleDifferentBillingAddress = ({target:{checked:different_billing_address}}) => {
    //console.log('toggleDifferentBillingAddress',{different_billing_address});
	return dispatch => {
        toggleDifferentBillingAddressState(dispatch, {different_billing_address})
	}
}
export const toggleDifferentBillingAddressState = (dispatch, {different_billing_address}) => {
	dispatch({
        type: PAYMENT_BILLING_CHOICE,
        payload: { different_billing_address }
	})
};


export const changePaymentCard = ({target:{value:CardId}}) => {
    //console.log('changePaymentCard',{CardId});
	return dispatch => {
        changePaymentCardState(dispatch, {CardId})
	}
}
export const changePaymentCardState = (dispatch, {CardId}) => {
	dispatch({
        type: PAYMENT_CARDS_SELECT,
        payload: { CardId }
	})
};

