import Noty from 'noty';

const NOTY_DEFAULTS = {
    layout:'center',
    theme:"metroui",
    timeout: 3000
};
const noty = (text, options)=>{

    const noty_settings = {
        ...NOTY_DEFAULTS,
        ...options,
        ...{text}
    };

    console.log({noty_settings});

    return new Noty(noty_settings).show();
}

// Types: 
// alert, success, warning, error, info/information
// 
// Layouts: 
// top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight

export const alert = (text, options)=> noty(text, { ...(options || {}), ...{type:'alert'} });
export const success = (text, options)=> noty(text, { ...(options || {}), ...{type:'success'} });
export const warning = (text, options)=> noty(text, { ...(options || {}), ...{type:'warning'} });
export const error = (text, options)=> noty(text, { ...(options || {}), ...{type:'error'} });
export const info = (text, options)=> noty(text, { ...(options || {}), ...{type:'info'} });