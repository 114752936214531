import { postcodeArea } from './../helpers/postcode'

import {
    K,
    MOMENT_DAYS,
    MOMENT_FORMAT,
    MOMENT_WEEKDAY,
    MOMENT_DAY,
    MOMENT_PARSE_TIME,
    MOMENT_PARSE_FORMAT,
} from './../config/constants';

// https://coderwall.com/p/nilaba/simple-pure-javascript-array-unique-method-with-5-lines-of-code
/* 
Array.prototype.unique = function() {
    return this.filter(function (value, index, self) { 
        return self.indexOf(value) === index;
    });
};
*/
const unique_items = array => array.filter(function (value, index, self) { 
    return self.indexOf(value) === index;
});

const moment = require('moment');
const _ = require('lodash/core');

const SPLIT = /[^0-9a-zA-Z]+/gi;

export const MomentToDate = (s) => {
    const D = typeof(s)==='string'?Moment(s):s;
    const y = D.get('year');
    const m = D.get('month');
    const d = D.get('date');
    const o = new Date(y, m, d);
    //////console.log('MomentToDate',{s,parts:{y,m,d},o})
    return o;
};

export const Moment = (raw_input) => {

    return ((input) => {

        let output,
            type = typeof(input), 
            date = !!(input instanceof Date)
        ;

        if(input===undefined || input==="" || input===0){
            output = moment();
            //////console.log('Moment type 1 - should be today', {s:output.format(MOMENT_FORMAT),input,output});
        }
        else if(date){
            output = moment(input);
            //////console.log('Moment type 2',{input,output});
        }
        else if(type==='object' && input._isAMomentObject){
            //////console.log('Moment type 3x, clone moment object');
            output = moment(MomentToDate(input));
            //////console.log('Moment type 3x',{input,output});
        }
        else if(type==='object'){
            output = input;
            //////console.log('Moment type 3',{input,output});
        }
        else if(type==='string'){
            //const date_string = input + MOMENT_PARSE_TIME;
            if(input.match(/^(\d+-\d+-\d+)$/gi)){
                const parts = input.split(/-/gi).map( (c) => parseInt(c) );
                const date_config = {
                    'year': parseInt(parts[0]),
                    'month': parseInt(parts[1])-1, // month is zero based
                    'date': parseInt(parts[2])
                };
                output = moment().set(date_config);
                //////console.log('Moment from parts', {input,parts,date_config,date_string,output})
            }
            else{
                output = moment(input + MOMENT_PARSE_TIME, MOMENT_PARSE_FORMAT);
            }
            //////console.log('Moment type 4', {input,MOMENT_FORMAT,output});
        }
        else if(type==='number'){
            output = moment().add( input, MOMENT_DAYS);
            //////console.log('Moment type 5',{input,output});
        }
        //////console.log('Moment',{type,date,input,output});

        return output;

    })(raw_input)
};

export const Schedule = function(DATA){
    this.SLOTS = null;
    this.DATA = DATA;

    this.for = ( input ) => {
        const postcode = postcodeArea(input);
        const schedule_for = this.DATA.filter( (rule) => rule.POSTCODE === postcode );
        ////console.log('Schedule.for', {schedule_for,postcode,input});
        this.DATA = schedule_for;
        window.SCHEDULE = this.DATA;
        window.Schedule = this;
        return this;
    }

    this.get = () => {
        return this.DATA;
    }

    this.slots = (restrict) => {
        let slots = [];
        let slots_weekdays = [];
        
        ////console.log('Schedule.slots', {restrict,DATA:this.DATA});

        this.DATA.forEach( (rule, rule_number) => {
            
            rule.WEEKDAYS = Array.isArray(rule.WEEKDAYS) ? rule.WEEKDAYS : rule.WEEKDAYS.toUpperCase().split(SPLIT);

            // tally up all weekdays that should be enabled
            slots_weekdays = slots_weekdays.concat(rule.WEEKDAYS);
            
            ////console.log('Schedule.slots: date',{rule_number,rule,weekdays:rule.WEEKDAYS});

            for(var i=0; i<=8; i++){
                const date = Moment(restrict).add(i, MOMENT_DAYS);
                ////console.log('Schedule.slots: i',{i,date});

                let slot = {};
                slot[K.RULE] = rule;
                slot[K.DATE] = date.format(MOMENT_FORMAT);
                slot[K.TIME] = rule[K.TIME];
                slot[K.HOUR] = parseInt(rule[K.TIME].replace(/^0?([0-9]+).*$/gi,'$1'));
                slot[K.KEY] = `${slot[K.DATE]}@${slot[K.TIME]}`;
                slot[K.WEEKDAY] = date.format(MOMENT_WEEKDAY).toUpperCase();

                ////console.log('Schedule.slots: slot?',{slot});
                if(rule.WEEKDAYS.indexOf(slot[K.WEEKDAY])>-1){
                    ////console.log('Schedule.slots: slot accepted',{slot});
                    
                    slots[slots.length] = slot;

                }
                else{
                    ////console.log('Schedule.slots: slot rejected',{slot});
                }

            };

        });
        
        if(restrict){
            const min_date = Moment(restrict);

            ////console.log('Schedule.slots restrict raw', {min_date,restrict});
            ////console.log('Schedule.slots restrict formatted', {min_date:min_date.format(MOMENT_FORMAT),restrict});

            slots = slots.filter( (slot) => {
                const slot_date = Moment(slot.DATE);
                const slot_enabled = !!(slot_date.isSameOrAfter(min_date,MOMENT_DAY));
                ////console.log('Schedule.slots restricted? result',{restrict,min_date:min_date.format(MOMENT_FORMAT),slot_date:slot_date.format(MOMENT_FORMAT),slot_enabled});
                return slot_enabled;
            })
        }

        const sorted_slots = _.sortBy(slots, x=>x.DATE );//.sort( slot => slot.DATE );
        ////console.log('Schedule.slots result', {slots, sorted_slots});
        this.SLOTS = sorted_slots;

        const unique_weekdays = unique_items(slots_weekdays); //.unique(); //_.uniqBy(slots_weekdays); // https://stackoverflow.com/a/31740263/228776
        ////console.log('Schedule.slots result', {slots_weekdays, unique_weekdays});
        this.WEEKDAYS = unique_weekdays;

        return this;
    }

    this.first = ( restrict ) => {
        this.SLOTS = undefined;
        let slots = this.slots(restrict).SLOTS;

        ////console.log('Schedule.first',{restrict,slots});

        if(restrict){
            const min_date = Moment(restrict);

            ////console.log('Schedule.first restrict',{min_date:min_date.format(MOMENT_FORMAT)});

            slots = slots.filter( (slot) => {
                ////console.log('Schedule.first restrict validate slot',{min_date,slot});
                let nd = Moment(),
                    md = Moment(min_date),
                    sd = Moment(slot[K.DATE]),
                    sh = slot[K.HOUR],
                    nh = nd.hour()
                ;
                const slot_valid = !!(
                    sd.isSameOrAfter(md,MOMENT_DAY) 
                    && (
                        sd.isAfter(nd,MOMENT_DAY) 
                        ||
                        (
                            sd.isSame(nd,MOMENT_DAY)
                            && sh>nh
                        )
                    )
                );
                ////console.log('Schedule.first restrict validate slot final',{slot_valid,slot,nd,md,sd,sh,nh});
                return slot_valid;
            })
        }
        
        ////console.log('Schedule.first sort');

        slots = _.sortBy(slots, function(slot) { return slot[K.KEY]; });

        ////console.log('Schedule.first final',{restrict,slots});

        return slots.length ? slots[0] : [];
    }
}



window.Moment = Moment;
window.moment = moment;
