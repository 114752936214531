import SPOT_SETTINGS from './spot.Settings';

import { SPOT_SESSION } from './spot.Session';

// expose to window
window.SPOT_SETTINGS = SPOT_SETTINGS;
window.SPOT_SESSION = SPOT_SESSION;

//export const CustomerConnect = window.CustomerConnect;

// default function
export default window.SPOT_LOAD = function(){

    console.log("SPOT_LOAD() start");

    return new Promise((resolve, reject) => {

        // if already loaded, returnresolve promise right away
        if(window.CustomerConnect
            && window.CustomerConnect.SETTINGS 
            && !!window.CustomerConnect.Config.AccountKey
            && !!window.CustomerConnect.Config.SessionId
            && window.SPOT 
        ){
            console.log("SPOT_LOAD() quit early, already loaded", window.CustomerConnect, window.CustomerConnect.SETTINGS, window.SPOT);
            resolve(window.CustomerConnect, window.CustomerConnect.SETTINGS, window.SPOT);
            return;
        }

        // if not resolved by now, load everything fresh
        SPOT_SESSION().then( (SPOT) => {
            
            // SET SPOT OBJECT
            //console.log('SPOT_LOAD() SPOT_SESSION() THEN', {SPOT});
            window.SPOT = Object.assign(window.SPOT || {}, SPOT || {});
            
            // critical check presense of window.CustomerConnect
            if(!window.CustomerConnect){
                console.error("CustomerConnect is not loaded, quit SPOT_LOAD now without a session (SPOT_SESSION().then((SPOT)))");
                reject("CustomerConnect is not loaded, quit SPOT_LOAD now without a session");
            }
            console.info("CustomerConnect is loaded", window.CustomerConnect);
            
            // start CustomerConnect
            window.CustomerConnect && (function(CustomerConnect){
                CustomerConnect.SPOT = SPOT;
                CustomerConnect.Config.AccountKey = 'UK123C4377';
                CustomerConnect.Config.URL = 'https://api.mydrycleaner.com/q';

                ////console.log('SPOT LOADED', {SPOT,Config:CustomerConnect.Config});
                CustomerConnect.Config.SessionId = SPOT.session; //.ReturnObject.SessionID;
                // LoadAllTheThings

                SPOT_SETTINGS().then((SETTINGS)=>{
                    //console.log('SPOT_LOAD() SPOT_SETTINGS, SPOT_SESSION = ',{SPOT, SETTINGS});
                    CustomerConnect.SETTINGS = SETTINGS;
                    resolve(window.CustomerConnect, SETTINGS, SPOT);
                });
            })(window.CustomerConnect)

        })
        .catch((err) =>{
            console.error('SPOT_LOAD() SPOT_SESSION() CATCH', {err,CustomerConnect:window.CustomerConnect});
            reject(err)
        })
    
    
    })

}
