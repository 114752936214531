import SPOT_LOAD from './../../config/spot';

import { SPOT_LOGIN } from './../spot.js'

import * as noty from './../../helpers/noty';

import { 
    loginPromptShow
} from '../login';

// import { Promisify } from './../../helpers/promisify';

//export const CustomerConnect = window.CustomerConnect;

export const sendMobileCode = (PhoneNumber) => (dispatch) => {
    //console.log('sendMobileCode',{dispatch,PhoneNumber});

if(!PhoneNumber){
        noty.error('Please enter your phone number');
        return;
    }

    window.CustomerConnect.User.GenerateMobileLoginCode(PhoneNumber).then((data)=>{
        //console.log('sendMobileCode result',{PhoneNumber,data});
        if(data && data.ReturnObject && data.ReturnObject.Success===true){
            noty.success('Code sent to your phone!');
        }
        else{
            noty.error( data.ReturnObject.Message || 'Could not send code to your phone, please login');
        }
    });
}

export const loginWithMobileCode = (EmailAddress, PhoneNumber, MobileCode) => (dispatch) => {
    //console.log('loginWithMobileCode',{dispatch,EmailAddress, PhoneNumber, MobileCode});
    window.CustomerConnect.User.ValidateMobileLoginCode(EmailAddress, PhoneNumber, MobileCode);
}

export const loginWithPassword = (EmailAddress, Password) => (dispatch) => {
    //console.log('loginWithPassword',{dispatch,EmailAddress,Password});
    window.CustomerConnect.User.Login(EmailAddress,Password);
}

export const showPasswordPrompt = (event) => (dispatch) => {
    //console.log('showPasswordPrompt',{dispatch,event});

    loginPromptShow(dispatch);
    
}


export const SpotLoginRequest = (dispatch, email, password)=>{
	return new Promise((resolve, reject)=>{
		SPOT_LOAD()
		.then(()=>{
			(window.CustomerConnect.User.Login(email, password)) //Promisify
			.done((data)=>{
				//console.log('CustomerConnect.User.Login',data)
			
				if(!!data.Failed){
					console.error('SpotLoginRequest FAILED', {email, password, data});
                    
                    //noty.error(data.Message || "Login failed!");
					
                    //dispatch({ type:LOGIN_PROMPT_ERROR, payload:{error:data.Message} });
                    
					reject(data);
					return;
				
				}
				else{
					console.info('SpotLoginRequest OK!', {email, password, data});
					//noty.success('Logged in!');

					const { CustomerID, CustomerName, CustomerStoreID, CustomerStoreName, SessionID } = data.ReturnObject;
					const LoginData = { Email:email, CustomerID, CustomerName, CustomerStoreID, CustomerStoreName };

					// NEW 2020 - expose this session to customer connect counter
					if(window.sessionStorage){
						window.sessionStorage.setItem("ccApp.token", JSON.stringify(SessionID));
					}

					if(!!LoginData.CustomerID){
						dispatch({ type:SPOT_LOGIN,payload:LoginData });
					}

					resolve(LoginData);

				}
			
			})
			.fail( err =>{
				console.error('CustomerConnect.User.Login',{err})
				reject(err);
			})			
		})	
		.catch(e=>{
			//console.error('SPOT LOAD ERROR', e);
		})
	})
}
