import React, { Component } from 'react'
import { connect } from 'react-redux'

import Prompt from './Prompt';
import Booking from './Booking';
import Confirm from './Confirmation';

import { HideEverything } from './../modules/wrapper';
import { bookingShow } from './../modules/booking';
import { promptShow, promptHide } from './../modules/prompt';
import { setPostcodeValueFromString } from '../modules/shipping';


let initialised = false;
let clicked = false;


class Wrapper extends Component {
    constructor (props) {
        super(props)

        const {
            promptShow,
            promptHide, 
            bookingShow,
            HideEverything,
            setPostcodeValueFromString
        } = props;
        
        //console.log('Wrapper', {e:this,props})

        // auto fill postcode
        //const postcode = ('localStorage' in window) && localStorage.getItem('postcode');
        //if(!!postcode){
        //    
        //    //console.log('setPostcodeValueFromString();', postcode);
        //    props.setPostcodeValueFromString(postcode);
        //
        //    // but do not go through to prompt
        //    ////console.log('bookingShow();');
        //    //props.bookingShow();
        //    
        //    //return;
        //};

        
        // reusable launch procedure
        const spotLaunch = (custom_postcode = '', forceStraightToBooking = false)=>{

            // tracking
            window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'launch', eventAction:'started'});
            window.ga && window.ga('send', 'event', 'booking', 'launch', 'started');//, [eventLabel], [eventValue], [fieldsObject]);
            
            // start with a postcode?
            const postcode = custom_postcode || (()=> ('localStorage' in window) && localStorage.getItem('postcode'))();

            // skip prompt?
            const skipPrompt = !!window.DONT_WATCH_HASH || !!forceStraightToBooking;

            // launch?
            //console.log('spotLaunch', {postcode,forceStraightToBooking,skipPrompt});
            if(!!skipPrompt && !!postcode){
                setPostcodeValueFromString(postcode);

                // tracking
                window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'launch', eventAction:'straight-to-form'});
                window.ga && window.ga('send', 'event', 'booking', 'launch', 'straight-to-form');//, [eventLabel], [eventValue], [fieldsObject]);

                // STRAIGHT TO BOOKING FORM
                bookingShow();

                return;
            }
            else{

                // tracking
                window.dataLayer && window.dataLayer.push({'event': 'booking', eventCategory:'launch', eventAction:'show-prompt'});
                window.ga && window.ga('send', 'event', 'booking', 'launch', 'show-prompt');//, [eventLabel], [eventValue], [fieldsObject]);

                // SET NKNOWN POSTCODE?
                !!postcode && setPostcodeValueFromString(postcode);

                // PROMPT FOR POSTCODE
                ////console.log('promptShow();');
                promptShow();

                return;

            }

        }


        // expose global methods to window
        window.SPOT = Object.assign(window.SPOT || {}, {
            book: bookingShow,
            prompt: promptShow,
            postcode: setPostcodeValueFromString,
            area: setPostcodeValueFromString,
            launch: spotLaunch,
            show: spotLaunch,
            hide: promptHide,
            exit: promptHide,
        });


        
        // bind event handlers
        if(!!initialised){
            //console.log('already initialised');
        }
        else{
            initialised = true;
            document.addEventListener("click", function(e) {
                
                // reset click once trap
                clicked = false;
                
                // loop through parents to document root, look for .book
                for (var target=e.target; target && target!==this; target=target.parentNode) {
                    // loop parent nodes from the target to the delegation node
                    ////console.log(target);

                    if (target.matches('.spot-booking-launch')) {
                        
                        // don't follow the link
                        e.preventDefault();
                            
                        if(!!clicked){
                            //console.log('already clicked');
                        }
                        else{
                            clicked = true;

                            spotLaunch();
        
                        }
                        // stop for-looping
                        break;
                    }
                }
            }, false);
        }

        document.addEventListener("keyup", function(event) {
            var e = event || window.event; // for IE to cover IEs window event-object
            if(e && e.altKey && e.which === 66) {
              
                        ////console.log('promptShow();');
                        props.promptShow();
        
                return false;
            }
        }, false);// keyup

  
        //React.useEffect(() => {
            //window.location.hash = '#booking-form';
            const onHashChange = () => {
                //console.log('onHashChange', {hash:window.location.hash,DONT_WATCH_HASH:window.DONT_WATCH_HASH,DONT_HANDLE_HASH:window.DONT_HANDLE_HASH})

                // this hack exists so we can run the app along with the angular counter
                if(!!window.DONT_WATCH_HASH){
                    ////console.log('onHashChange QUIT! DONT_WATCH_HASH')
                    return;
                }

                // ignore hash change when it's fired by the app itself
                if(!!window.DONT_HANDLE_HASH){
                    // handle the next time it changes
                    window.DONT_HANDLE_HASH = false;
                    // do nothing this time.
                    return;
                };

                if(window.location.hash==='#booking-prompt'){
                    //console.log('onHashChange promptShow()')
                    return promptShow()
                };
                if(window.location.hash==='#booking-dialog'){
                    //console.log('onHashChange bookingShow()')
                    return bookingShow()
                };
                
                ////console.log('onHashChange HideEverything()')
                HideEverything();

            }
            window.addEventListener("hashchange", onHashChange);
            //return () => window.removeEventListener("hashchange", onHashChange);
        //}, []);

    
    } // constructor

    render () {
        if(this.props.visible_container === 'Nothing') return <noscript />
        return React.createElement(Wrapper[this.props.visible_container]);
    } // render
}
Wrapper.Prompt = Prompt;
Wrapper.Booking = Booking;
Wrapper.Confirm = Confirm;



export default connect(
    ({visible_container}) => ({visible_container}),
    {
        promptShow,
        promptHide,
        bookingShow,
        HideEverything,
        setPostcodeValueFromString
    }
)(Wrapper)
