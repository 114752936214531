import {
	BOOKING_SHOW,
	BOOKING_HIDE,
	BOOKING_CANCEL,
	//BOOKING_CONFIRM,
} from './booking';

import {
	PROMPT_SHOW,
	PROMPT_HIDE,
	PROMPT_CONTINUE
} from './prompt';

import {
	CONFIRMATION_SHOW,
	CONFIRMATION_HIDE
} from './confirmation';

export const HIDE_EVERYTHING = "HIDE-EVERYTHING";

export const PROMPT_CONTAINER = "Prompt";
export const BOOKING_CONTAINER = "Booking";
export const CONFIRM_CONTAINER = "Confirm";
export const NOTHING_CONTAINER = "Nothing";

export default (state = 'Nothing', action) => {
  switch (action.type) {

	case PROMPT_SHOW:
	  return PROMPT_CONTAINER

	case PROMPT_HIDE:
	  return NOTHING_CONTAINER

	case BOOKING_SHOW:
	case PROMPT_CONTINUE:
	  return BOOKING_CONTAINER

	case BOOKING_HIDE:
	case BOOKING_CANCEL:
	  return NOTHING_CONTAINER

	case CONFIRMATION_SHOW:
	  return CONFIRM_CONTAINER

	case HIDE_EVERYTHING:
	case CONFIRMATION_HIDE:
	  return NOTHING_CONTAINER
		
	default:
	  return state
  }
}




export const HideEverything = () => {
	return {
		type: HIDE_EVERYTHING
	}
}