//import { Promisify } from './../helpers/promisify';

import { SPOT_KILL_SESSION } from "./spot.Session";

export default function(){

    return new Promise((resolve, reject) => {

        window.CustomerConnect ? (function(CustomerConnect, localStorageService){
    
            (function () {
                if (window.localStorageService && localStorageService.get("ccCache") != null) {
                    CustomerConnect.Config.Settings = JSON.parse(window.CustomerConnect.Util.base64._decode(localStorageService.get("ccCache")));
                    //$scope.Loaded = true;
                } else {

                    const LoadSpotStuff = ()=> {
                        (window.CustomerConnect.Settings.GetSettings()) //Promisify
                            .done(function (data) {
                                CustomerConnect.Config.Settings = data.ReturnObject.CustomerConnectSettings;
        
                                (window.CustomerConnect.Settings.GetNotifications()) //Promisify
                                    .done(function (notifications) {
                                        CustomerConnect.Config.Settings.Notifications = notifications.ReturnObject;
        
                                        (window.CustomerConnect.Settings.GetPreferences()) //Promisify
                                            .done(function (preferences) {
                                                CustomerConnect.Config.Settings.Preferences = preferences.ReturnObject;
        
                                                (window.CustomerConnect.Store.GetStoreList()) //Promisify
                                                    .done(function (stores) {
                                                        CustomerConnect.Config.Settings.Stores = stores.ReturnObject;
        
                                                        (window.CustomerConnect.Route.GetRouteDeliveryZones()) //Promisify
                                                            .done(function (routes) {
                                                                CustomerConnect.Config.Settings.Routes = routes.ReturnObject;
        
                                                                window.localStorageService && localStorageService.set("ccCache", CustomerConnect.Util.base64._encode(JSON.stringify(window.CustomerConnect.Config.Settings)));
                                                                
                                                                resolve(window.CustomerConnect.Config.Settings);
                                                            });
        
                                                    });
        
                                            });
                                    });
                            });
                    }

                    try{
                        LoadSpotStuff()
                    }
                    catch(e){
                        console.warn("failed to load spot, try killing session and going again");
                        SPOT_KILL_SESSION();
                        LoadSpotStuff();
                    }
                    
                };
    
                //$rootScope.Settings = CustomerConnect.Config.Settings;
                //console.log(['initial',$rootScope.Settings]);
            })();
        
        })(window.CustomerConnect, window.localStorageService) : reject(null);

    
    })

}