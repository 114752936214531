//nst PHRASES_TSV = window.PHRASES_TSV = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT1Hx94q4_CBdztByPotHZ6T6OYuxVPsP2DosskedgvC6XmodMjT0f4LUEGnupNZKbxxdCGCErZIVUf/pub?output=tsv';
//nst PHRASES_TSV = window.PHRASES_TSV = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSPqkYNyPtQVLhZ0uSS3YY9LxJl-5Fw3eKv6rQcaIepFoERXnm50pQJ2a2-65hk7KnzN250iwh4gyzG/pub?gid=0&single=true&output=tsv';
//nst PHRASES_TSV = window.PHRASES_TSV = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTU67k3qtvFUycDhIP8gaLdPd1KCPwkFC9Y-rxjsV8Kb1tdJFIMZz2keGeTYoG8nGB6LtrW2TfMbeYY/pub?gid=0&single=true&output=tsv';
//nst PHRASES_TSV = window.PHRASES_TSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vTcpPrYdoSbnDVg5XhcoCO2HLw37BFam4xhGnOWwdh-hfFWmKMnkZVRKYXjXRTKdg-RuLePxR0-sf7V/pub?gid=0&single=true&output=tsv";
//nst PHRASES_TSV = window.PHRASES_TSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR7FocXh9DKMil_TLiNuT4akVhrbTZUq1T00k8A4Obtp5GONL7WImwYxgg22P6_Qge35Wn7Xlq-zD7L/pub?gid=1275693213&single=true&output=tsv"
const PHRASES_TSV = window.PHRASES_TSV = "https://www.123cleaners.com/do/google?q=booking-phrases";

export default function(){

    if(!!window.PHRASES){
        console.warn('window.PHRASES was already loaded', {PHRASES:window.PHRASES});
        return Promise.resolve(window.PHRASES);
    }
    console.warn('window.PHRASES was nothing, LOAD NOW', {PHRASES:window.PHRASES});

    let PHRASES = {};

    return new Promise((resolve, reject) => {

        fetch(PHRASES_TSV, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            //mode: 'no-cors', 
            cache: "no-cache"
        })
        .then(r=>r.text())
        .then( (data) => {
            

            data = data
            .replace(/#REF!/gi,'')
            .replace(/^[\t\n\r\s]+/gi,'')
            .replace(/^\d+\t/gi,'')
            .replace(/([\n\r]+)\d+\t/gi,'$1')
            .replace(/([\n\r]+)[\s\t]+[\n\r]+/gi,'$1')
            .split(/[\n\r]+/gi)
            .forEach((line) => {
                line = line.replace(/^[\t\n\r\s]+/gi,'');
                //console.log(line);
                line = line.split(/\t/);
                PHRASES[line[0]] = line[1]
            });

            //console.log({PHRASES});
            window.PHRASES = PHRASES;
    
            resolve(PHRASES);
        })
        .catch((err) =>{
            reject("Could not load phrases", {err, PHRASES_TSV})
        })
    
    
    })

}
